import React, { FC } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";

export type ForgotPasswordStep2FormInputs = {
  code: string;
};

const schema = yup
  .object({
    code: yup
      .string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(7, "Must be exactly 7 digits")
      .max(7, "Must be exactly 7 digits"),
  })
  .required();

type Props = {
  onSubmit: (values: ForgotPasswordStep2FormInputs) => void;
};

export const ForgotPasswordStep2Form: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordStep2FormInputs>({
    resolver: yupResolver(schema),
  });

  return (
    <form action={"#"} onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="textField margin" variant="outlined" size="small">
        <InputLabel htmlFor="outlined-adornment-username">OTP</InputLabel>
        <OutlinedInput
          id="outlined-adornment-username"
          label="OTP"
          aria-describedby="outlined-weight-helper-text"
          placeholder="Enter OTP"
          inputProps={{ style: { textAlign: "left" } }}
          {...register("code")}
          error={Boolean(errors?.code?.message)}
        />
        {errors?.code?.message && (
          <FormHelperText error>{errors?.code?.message}</FormHelperText>
        )}
      </FormControl>

      <br />
      <br />
      <Button
        variant="contained"
        className="loginButton"
        type="submit"
        disabled={isSubmitting}
      >
        Continue
      </Button>
    </form>
  );
};

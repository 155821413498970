const SettingTypes = {
  SET_COMPANY: "SET_COMPANY",
  SET_DEFAULT_COMPANY: "SET_DEFAULT_COMPANY",
  SET_COMPANY_DETAILS: "SET_COMPANY_DETAILS"
}

export interface AddCompany {
  name: string,
  users: [] | null
}


export interface UpdateCompany {
  name: string,
  id: string
}


export interface SetDefaultCompany {
  company_id: string
}

export interface GetCompany {

}

export interface DeleteCompany{
  company_id: string
}

export interface RemoveMember {
  remove_company_member: string
}

export interface InviteMember {
  email: string,
  companyId: string
}

export interface GetInvitedMember {
  inviteId: string
}

export default SettingTypes;

import React from "react";

import { COLORS} from '../../utils/Colors';
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {Breadcrumbs, Icon, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import {Chat, HomeRounded} from "@material-ui/icons";

interface Props {
    title: string;
    open: boolean;
    icon: any
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    pageHead: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: "-12%",

    },
    pageHeadBarShift: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: "2%",
    }
}));

const PageHeader: React.FC<Props> = ({ title , open, icon}) => {
    const classes = useStyles();

    return (
        <Breadcrumbs aria-label="breadcrumb" className={clsx(classes.pageHead, {
            [classes.pageHeadBarShift]: open,
        })}
                     style={{
            display: "flex",
            alignItems: "center",
            fontWeight: "bold",
            padding: 10,
            height: 50,
            marginLeft: "25%",
            color: COLORS.basfDarkBlue
        }}>
                <Typography style={{fontSize: "15px", display: "flex",}}>
                    <Icon  style={{fontSize: "20px", marginRight: "2%"}}>{icon}</Icon>  {title}</Typography>
        </Breadcrumbs>
    );
};

export default PageHeader;

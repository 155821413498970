import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider, FormControl,
  IconButton, InputLabel,
  Menu,
  MenuItem, Select, TextField, Tooltip
} from "@material-ui/core";
import { Chat, Delete, Edit, MoreVert, Replay, VerifiedUser } from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { decode } from "js-base64";
import SettingAction from "../../stores/actions/settingAction";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import TaskAction from "../../stores/actions/taskAction";
import { RootState } from "../../stores";
import { COLORS } from "../../utils/Colors";


interface Props{

  statusList: any,
  setEditTaskStatus: any,
  editTaskStatus: any
}


const useStyles = makeStyles((theme) => ({

  iconStyle: {
    fontSize: 13 ,
    marginRight: 5
  },

  hoverMore: {
    float: "right" ,
    marginRight: "1%" ,
    color: COLORS.basfDarkBlue ,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
      color: COLORS.basfDarkBlue ,
      borderRadius: "50px",

    },
  }
}))

const TaskStatusPopup: React.FC<Props> = ({statusList , setEditTaskStatus , editTaskStatus}) => {
  const classes = useStyles();
  const [openDialog , setOpenDialog] = useState(false);
  const dispatch = useDispatch()<any>;

  const [openNewTask, setNewTask] = useState(false);
  const [taskStatusName, setTaskStatusName] = useState('');
  const [taskStatusColor, setTaskStatusColor] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const [teamId, setTaskId] = useState("");
  const [showAutoComplete , setShowAutoComplet] = useState(false);

  const handleChangeColor = (event: any) => {
    setTaskStatusColor(event.target.value as string)
  }

  const deleteTaskStatus = async (e: any) => {
    const result = await dispatch(TaskAction.deleteTaskStatus({statusId: statusList.id}))
    if(result && result.status) {
      await dispatch(TaskAction.getProjectStatus({projectid:statusList.task_project_id}))
    }
  }

  const handleDeleteTask = async (e: any) => {
    if(e.close){
      e.close()
    }
    setOpenDialog(!openDialog);
  }

  const handleEditTaskStatus = async (e: any) => {
    if(e.close){
      e.close()
    }

    setNewTask(true);
    setTaskStatusName(statusList.status_name);
    setTaskStatusColor(statusList.color)
  }

  const handleCloseEdit = () => {
    setNewTask(false)
    setShowAutoComplet(false)
  }

  const editTask = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true)

    const result = await dispatch(TaskAction.updateProjectStatus({name: taskStatusName , color: taskStatusColor , statusId: statusList.id , projectId: statusList.task_project_id}));

    if(result && result.status) {
      handleCloseEdit();
      setEditTaskStatus(!editTaskStatus)
    }
    setButtonLoader(false)
  }


  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={"More"} style={{float: "right"}}>
              <IconButton aria-label="settings" {...bindTrigger(popupState)}>
                <MoreVert  />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)} style={{marginTop: 46, marginLeft: "-2%"}}>
              <MenuItem  onClick={() => handleDeleteTask(popupState)} style={{ fontSize: 13}}><Delete className={clsx(classes.iconStyle)}/> Delete</MenuItem>
              <MenuItem onClick={() => handleEditTaskStatus(popupState)} style={{ fontSize: 13}}><Edit className={clsx(classes.iconStyle)}/>Edit</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(!openDialog)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Task Status ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the task status ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(!openDialog)}>Cancel</Button>
          <Button onClick={deleteTaskStatus}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNewTask} onClose={handleCloseEdit} maxWidth={"xs"} fullWidth={true}>
        <form action={"#"} onSubmit={editTask}>
          <DialogTitle>Update Task Status </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="taskName"
              label="Status Name"
              value={taskStatusName}
              type="text"
              required={true}
              fullWidth
              variant="standard"
              onChange={(text) => setTaskStatusName(text.target.value)}
            />
            <br/>
            <br/>
            <FormControl  size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Color</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={taskStatusColor}
                label="Color"
                style={{color: taskStatusColor}}
                onChange={handleChangeColor}
              >
                <MenuItem value={"#F39500"} style={{color: COLORS.basfOrange}} >Orange</MenuItem>
                <MenuItem value={"#00793A"} style={{color: COLORS.basfDarkGreen}}>Green</MenuItem>
                <MenuItem value={"#004A96"} style={{color: COLORS.basfDarkBlue}}>Blue</MenuItem>
                <MenuItem value={"#C50022"} style={{color: COLORS.basfRed}}>Red</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Update</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default TaskStatusPopup;

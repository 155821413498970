import { Task } from "../../utils/public-types";
import { useParams } from "react-router-dom";
import { COLORS } from "../../utils/Colors";

export function initTasks(taskDataIs : any) {

  const currentDate = new Date();

  var tasks: any = [];
  if(taskDataIs && taskDataIs.data.length > 0) {
    let i = 1;
    for (const taskData of taskDataIs.data) {
      let startDate = new Date(taskData.start);
      let endDate = new Date(taskData.end);
      // let type = taskDataIs.data.length === i ? 'milestone' : taskData.type;

      if(taskData.type === 'project') {
        tasks.push({
          start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
          end: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
          name: taskData.name,
          id: taskData.id,
          progress: (taskData.progress + 10),
          type: taskData.type,
          displayOrder: taskData.displayOrder,
          dependencies: [taskData.dependencies],
          project: taskData.project,
          hideChildren: false,
        })
      }else {
        tasks.push({
          start: new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()),
          end: new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()),
          name: taskData.name,
          id: taskData.id,
          progress: taskData.progress,
          type: taskData.type,
          displayOrder: taskData.displayOrder,
          dependencies: [taskData.dependencies],
          project: taskData.project,
          hideChildren: false,
          styles: { progressColor: taskData.color, progressSelectedColor: COLORS.basfDarkBlue },
        })
      }
      i++;
    }
  }


  const result: Task[] = tasks;

  return result;
}


export function getStartEndDateForProject(tasks: Task[], projectId: string) {
  const projectTasks = tasks.filter(t => t.project === projectId);
  let start = projectTasks[0].start;
  let end = projectTasks[0].end;

  for (let i = 0; i < projectTasks.length; i++) {
    const task = projectTasks[i];
    if (start.getTime() > task.start.getTime()) {
      start = task.start;
    }
    if (end.getTime() < task.end.getTime()) {
      end = task.end;
    }
  }
  return [start, end];
}

import styled from "styled-components";

export const Wrapper = styled.div`
  bottom: 0;
  top: 0;

  .loginButton {
    background-color: rgb(74 141 211);
    width: 50%;
    height: 45px;
    border-radius: 25px;
    color: white;
  }

  .loginButton:hover {
    background-color: #004a96;
  }

  .backButton {
    background-color: rgb(74 141 211);
    width: 30%;
    height: 25px;
    border-radius: 25px;
    color: white;
  }

  .margin {
    margin: 10px;
  }

  .textField {
    width: 100%;
    background-color: white;
    border-radius: 4px;
    border-color: #ef943d;
    text-align: right;
  }

  .paper {
    padding: 60px;
    margin: 10px;
    text-align: center;
    border-radius: 10px;
  }
  .buttonProgress {
    position: absolute;
  }
`;

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgressProps,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Delete, Edit, MoreVert } from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import LinearProgress from "@material-ui/core/LinearProgress";
import TaskAction from "../../stores/actions/taskAction";
import { RootState } from "../../stores";

interface Props {
  projectDetails: any;
  taskDetails: any;
  statusList: any;
  setEditTaskStatus: any;
  editTaskStatus: any;
}

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13,
    marginRight: 5,
  },
}));

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box style={{ width: "100%", margin: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box style={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const TaskTaskPop: React.FC<Props> = ({
  projectDetails,
  taskDetails,
  statusList,
  setEditTaskStatus,
  editTaskStatus,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch()<any>;

  const [openNewTask, setNewTask] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskStartDate, setTaskStartDate] = useState("");
  const [taskEndDate, setTaskEndDate] = useState("");
  const [taskAllocated, setTaskAllocated] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [inputTeam, setInputTeam] = useState("");
  const [inputTeamId, setInputTeamId] = useState("");
  const [inputProgress, setInputProgress] = useState(0);
  const [inputType, setInputType] = useState("");
  const [inputDepends, setInputDepends] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [teamId, setTaskId] = useState("");
  const [showAutoComplete, setShowAutoComplet] = useState(false);
  const [taskReason, setTaskReason] = useState("");
  const [subTask, setSubTask] = useState("");
  const { allocateList, taskList } = useSelector(
    (state: RootState) => state.tasks
  );

  const deleteTask = async (e: any) => {
    const result = await dispatch(
      TaskAction.deleteTaskTask({ task_id: taskDetails.id })
    );
    if (result && result.status) {
      await dispatch(
        TaskAction.getProjectStatus({ projectid: taskDetails.project_id })
      );
    }
  };

  const handleDeleteTask = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setOpenDialog(!openDialog);
  };

  const handleEditTask = async (e: any) => {
    if (e.close) {
      e.close();
    }

    setNewTask(true);
    setTaskId(taskDetails.id);
    setTaskName(taskDetails.task_name);
    setTaskDescription(taskDetails.task_description);
    setTaskStatus(taskDetails.status);
    setTaskStartDate(taskDetails.start_date);
    setTaskEndDate(taskDetails.end_date);

    setTaskAllocated(taskDetails.allocate_type);
    setInputTeam(taskDetails.allocatedTo);

    setInputProgress(taskDetails.progress as number);
    setInputType(taskDetails.task_type);
    setInputDepends(taskDetails.depend_task);
    setSubTask(
      taskDetails.sub_tasks.length > 0 ? taskDetails.sub_tasks[0].sub_task : ""
    );
    if (
      taskDetails.allocate_type === "TEAM" ||
      taskDetails.allocate_type === "PERSONAL"
    ) {
      getAllocatedData(taskDetails.allocate_type);
      // setShowAutoComplet(true);
      setInputTeamId(taskDetails.allocated);
    }
  };

  const handleCloseEdit = () => {
    setNewTask(false);
    setShowAutoComplet(false);
  };

  const getAllocatedData = useCallback(
    async (typeIs: any) => {
      const type = typeIs;
      const keyword = "";
      const result = await dispatch(
        TaskAction.searchTaskAllocate({ type, keyword })
      );
      if (result.status) {
        setShowAutoComplet(true);
      }
    },
    [dispatch]
  );

  const handleAllocate = (event: any) => {
    setShowAutoComplet(false);
    setTaskAllocated(event.target.value);
    getAllocatedData(event.target.value);
    setInputTeam("");
    setInputTeamId("");
  };

  const handleStatus = (event: any) => {
    setTaskStatus(event.target.value);
  };

  const editTask = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true);
    const name = taskName;
    const description = taskDescription;
    const startDate = taskStartDate;
    const endDate = taskEndDate;
    const status = taskStatus;
    const type = taskAllocated;
    const allocated = inputTeamId;
    const taskId = teamId;

    const result = await dispatch(
      TaskAction.editProjectTask({
        taskId,
        name,
        description,
        startDate,
        endDate,
        status,
        type,
        allocated,
        reason: taskReason,
        progress: inputProgress,
        task_type: inputType,
        depend_task: inputDepends,
      })
    );
    if (result && result.status) {
      handleCloseEdit();
      setEditTaskStatus(!editTaskStatus);
    }
    setButtonLoader(false);
  };

  const handleProgressChange = (e: any) => {
    if (e.length > 0 && parseInt(e) <= 100) {
      setInputProgress(e as number);
    } else if (e.length === 0) {
      setInputProgress(e as number);
    }
  };

  const handleTaskType = (event: any) => {
    setInputType(event.target.value as string);
  };

  const handleTaskDepend = (event: any) => {
    setInputDepends(event.target.value as string);
  };

  const userList = allocateList?.data;
  useEffect(() => {
    if (taskDetails.allocate_type === "PERSONAL" && userList) {
      const f = userList.find((u: any) => u.id === taskDetails.allocated);
      if (f) {
        setInputTeam(f.name);
      }
    }
  }, [userList]);

  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={"More"}>
              <IconButton aria-label="settings" {...bindTrigger(popupState)}>
                <MoreVert />
              </IconButton>
            </Tooltip>

            <Menu
              {...bindMenu(popupState)}
              style={{ marginTop: 46, marginLeft: "-2%" }}
            >
              <MenuItem
                onClick={() => handleDeleteTask(popupState)}
                style={{ fontSize: 13 }}
              >
                <Delete className={clsx(classes.iconStyle)} /> Delete
              </MenuItem>
              <MenuItem
                onClick={() => handleEditTask(popupState)}
                style={{ fontSize: 13 }}
              >
                <Edit className={clsx(classes.iconStyle)} />
                Edit
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(!openDialog)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Task?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the task ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(!openDialog)}>Cancel</Button>
          <Button onClick={deleteTask}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNewTask}
        onClose={handleCloseEdit}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <form action={"#"} onSubmit={editTask}>
          <DialogTitle>Update Task </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="taskName"
              label="Task Name"
              value={taskName}
              type="text"
              required={true}
              fullWidth
              variant="standard"
              onChange={(text) => setTaskName(text.target.value)}
            />
            <br />
            <br />
            <TextField
              margin="dense"
              id="taskDesc"
              label="Task Description"
              value={taskDescription}
              type="text"
              fullWidth
              variant="standard"
              onChange={(text) => setTaskDescription(text.target.value)}
            />
            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Allocate To</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={taskAllocated}
                label="Allocate To"
                onChange={handleAllocate}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"TEAM"}>Team</MenuItem>
                <MenuItem value={"PERSONAL"}>Individual</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            {(taskAllocated === "TEAM" || taskAllocated === "PERSONAL") && (
              <>
                {showAutoComplete && (
                  <Autocomplete
                    id="free-solo-demo"
                    value={inputTeam || null}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue) {
                        setInputTeam(newValue?.label);
                        setInputTeamId(newValue?.id);
                      }
                    }}
                    options={
                      allocateList
                        ? allocateList.data.map((option: any) => ({
                            id: option.id,
                            label: option.name,
                          }))
                        : [{ label: "Loading...", id: 0 }]
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Search " + taskAllocated}
                      />
                    )}
                  />
                )}
                <br />
                <br />
              </>
            )}

            <TextField
              InputProps={{
                inputProps: {
                  min: projectDetails.data[0].start_date + " 00:00",
                  max: projectDetails.data[0].end_date + " 00:00",
                },
              }}
              margin="dense"
              id="startDate"
              label="Start Date"
              value={taskStartDate}
              type="datetime-local"
              fullWidth
              required={true}
              variant="standard"
              onChange={(text) => setTaskStartDate(text.target.value)}
            />
            <br />
            <br />
            <TextField
              InputProps={{
                inputProps: {
                  min: taskStartDate,
                  max: projectDetails.data[0].end_date + " 00:00",
                },
              }}
              margin="dense"
              id="taskDesc"
              label="End Date "
              value={taskEndDate}
              type="datetime-local"
              fullWidth
              required={true}
              variant="standard"
              onChange={(text) => setTaskEndDate(text.target.value)}
            />
            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Status</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={taskStatus}
                label="Status"
                onChange={handleStatus}
              >
                {statusList &&
                  statusList.map((status: any, index: any) => {
                    return (
                      <MenuItem
                        value={status.id}
                        style={{ color: status.color }}
                      >
                        {status.status_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <br />
            <br />
            <TextField
              margin="dense"
              id="taskDesc"
              label="Progress"
              value={inputProgress}
              type="number"
              fullWidth
              required={true}
              variant="standard"
              onChange={(text) => handleProgressChange(text.target.value)}
            />
            <LinearProgressWithLabel value={inputProgress} />
            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Task Type</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={inputType}
                label="Task Type"
                required={true}
                onChange={handleTaskType}
              >
                <MenuItem value={"task"}>Task</MenuItem>
                <MenuItem value={"milestone"}>Milestone</MenuItem>
              </Select>
            </FormControl>

            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Dependent task</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={inputDepends}
                label={"Dependent task"}
                onChange={handleTaskDepend}
              >
                {taskList &&
                  taskList
                    .filter(
                      (item: any) => Number(item.id) !== Number(taskDetails.id)
                    )
                    .map((taskData: any) => {
                      return (
                        <MenuItem value={taskData.id}>
                          {taskData.task_name}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
            <br />
            <br />
            <TextField
              margin="dense"
              id="taskDesc"
              label="Reason"
              value={taskReason}
              type="text"
              fullWidth
              required={true}
              variant="standard"
              onChange={(text) => setTaskReason(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseEdit}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TaskTaskPop;

const InitiativeTypes = {
  SET_INITIATIVE: "SET_INITIATIVE",
};

export interface AddFolder {
  name: string;
  folder_id: string | null;
}

export interface UpdateFolder {
  name: string;
  desc?: string;
  id: string;
  folder_id: string | null;
}

export interface AddNotes {
  name: string;
  description: string;
  folder_id: string | null;
}

export interface AddDocument {
  attachment: string;
  folder_id: string | null;
}

export interface GetDocument {
  folder_id: string | null;
  teamId: string | null;
  keyword: string | null;
  sort: string | null;
}

export interface ConfigDoc {
  folder_id: string | null;
  teamId: string | null;
}

export interface RemoveConfig {
  config_id: string;
}

export interface RemoveInitiative {
  document_id: string;
}

export default InitiativeTypes;

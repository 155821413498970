import React, { useCallback, useState } from "react";
import {
  IconButton,
  InputAdornment,
  List,
  ListItem,
  OutlinedInput,
} from "@material-ui/core";
import { Close, Send, VideoCall } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Route, useNavigate } from "react-router-dom";
import { encode } from "js-base64";
import { COLORS } from "../../utils/Colors";
import EmojiPopover from "./EmojiPopover";
import AttachmentPop from "./AttachmentPop";
import DiscussionAction from "../../stores/actions/discussionAction";
import fileImage from "../../assets/image/free-file-icon-1453-thumb.png";
import CreateMeeting from "./CreateMeeting";
import { RootState } from "../../stores";
import { socket } from "../../utils/Constants";

interface Props {
  openInput: boolean;
  messageType: any;
  parentId: string | null;
  styleInput: any;
  teamId: any | null;
  scrollHandle: any;
}

const useStyles = makeStyles((theme) => ({}));

const DiscussionInput: React.FC<Props> = ({
  openInput,
  messageType,
  parentId,
  styleInput,
  teamId,
  scrollHandle,
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({});
  const [input, setInput] = useState("");
  const [input2, setInput2] = useState("");
  const [sendButton, setSendButton] = useState(false);
  const isHoveringVd = "isHoveringVd";
  const isHoveringSn = "isHoveringSn";
  const dispatch = useDispatch()<any>;
  const [profileImageM, setProfileImageM] = React.useState("");
  const [profileImageDoc, setProfileImageDoc] = React.useState("");
  const [selectDocument, setSelectDocument] = useState("");
  const [profileImageName, setProfileImageName] = React.useState("");
  const [profileImageExt, setProfileImageExt] = React.useState("");
  const [popupMeeting, setPopupMeeting] = useState(false);
  const [popupSearch, setPopupSearch] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const navigator = useNavigate();

  const { searchUser } = useSelector((state: RootState) => state.discussion);

  const addEmoji = (e: any, option: any = null) => {
    let sym = e.unified.split("-");
    if (option.close) {
      option.close();
    }
    let codesArray: any[] = [];
    sym.forEach((el: any) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);
    setInput(input + emoji);
    codesArray.length > 0 ? setSendButton(true) : setSendButton(false);
  };

  const showSendButton = (e: any) => {
    e.length > 0 ? setSendButton(true) : setSendButton(false);
  };

  const handleChange = async (e: any) => {
    let arrayString = input.split(" ");
    let arrayLength = arrayString.length - 1;

    socket.emit("typing_update", {
      teamId: teamId,
      count: e.target.value.length,
    });

    showSendButton(e.target.value);
    setInput(e.target.value);
    for (let i = 0; i < arrayString.length; i++) {
      setInput2(input + " " + arrayString[i]);
    }
    if (
      e.target.value.length > 0 &&
      arrayString[arrayLength].match("@") &&
      arrayString[arrayLength].length > 1 &&
      arrayString[arrayLength][0].match("@")
    ) {
      const resultData = await dispatch(
        DiscussionAction.getSearchMember({
          teamId: teamId,
          keyword: arrayString[arrayLength],
        })
      );
      if (resultData.status && resultData.data.length > 0) {
        setPopupSearch(true);
      }
    } else {
      setPopupSearch(false);
    }
  };

  const matchHashTags = (text: string) => {
    var string = text;
    var regex = /@(\w*[0-9a-zA-Z]+\w*[0-9a-zA-Z])/gi;
    var matches: any = string.matchAll(regex);
    for (var match of matches) {
      let stringData: any = <b>{match[0]}</b>;
      string = string.replace(match[0], stringData);
      return string;
    }
  };

  const onSelect = async (e: any) => {
    // setInput(input +' <a>'+e+'</a> ');
    setPopupSearch(false);

    return <Route path="/old-url" element={<Navigate to="/new-url" />} />;
    // navigator("/", { replace: true })
    // let sUser = [...selectedUsers , ...e];
    // setSelectedUsers((selectedUsers) => [...selectedUsers , e])
  };

  const submitHandle = async (e: any) => {
    e.preventDefault();
    socket.emit("typing_update", { teamId: teamId, count: 0 });

    const team_id = teamId;
    const parent_message_id = parentId;
    const message_type = "content";
    const content = input;
    const file = profileImageM
      ? profileImageM
      : profileImageDoc
      ? profileImageDoc
      : "";
    const fileName = profileImageName
      ? profileImageName
      : selectDocument
      ? selectDocument
      : "";
    let limit = "5";
    setInput("");
    const result = await dispatch(
      DiscussionAction.sendMessage({
        team_id,
        parent_message_id,
        message_type,
        content,
        file,
        fileName,
      })
    );
    if (result.data.status) {
      setInput("");
      setProfileImageM("");
      setProfileImageDoc("");
      setSelectDocument("");
      socket.emit("message_sent", { companyid: team_id });
      if (messageType === "Main") {
        // scrollHandle()
        await dispatch(DiscussionAction.getMessage({ team_id, limit }));
      } else if (messageType === "Header") {
        // scrollHandle()
        const message_id = parent_message_id;
        await dispatch(DiscussionAction.getSingleMessage({ message_id }));
      } else if (messageType === "SubMessage") {
        await dispatch(DiscussionAction.getMessage({ team_id, limit }));
        if (result.data.reload) {
          const companyId: string | any = localStorage.getItem(
            "@userData:companyId"
          );
          await dispatch(
            DiscussionAction.getTeamsAllPersonal({
              type: "Personal",
              company_id: companyId,
              get_type: false,
            })
          );
          navigator("../discussion/" + encode(result.data.teamId));
        }
      }
    }
  };

  const deleteFile = useCallback(
    async (file_name: any) => {
      const result = await dispatch(DiscussionAction.deleteFile({ file_name }));
    },
    [dispatch]
  );

  const onChangeMsgImage = async (event: any) => {
    setProfileImageExt(event.target.files[0].name);
    const file = event.target.files[0];
    deleteFile(profileImageM);
    const result = await dispatch(DiscussionAction.uploadFile({ file }));
    if (result.status) {
      setProfileImageM(result.data);
      setProfileImageName(result.fileName);
      setSendButton(true);
    }
  };

  const handleRemoveFile = async (event: any) => {
    deleteFile(profileImageM);
    setProfileImageExt("");
    setProfileImageM("");
  };

  const handleRemoveDocument = async (event: any) => {
    setSelectDocument("");
    setProfileImageDoc("");
  };

  const handleMeetingPop = async (event: any) => {
    setPopupMeeting(!popupMeeting);
  };

  return (
    <>
      {popupSearch && (
        <List
          component="nav"
          aria-label="Device settings"
          style={{
            width: "30%",
            marginLeft: "13%",
            marginBottom: "2%",
            display: "inherit",
          }}
        >
          {searchUser &&
            searchUser.data.map((data: any, i: any) => {
              let highList = i === 0 ? COLORS.hoverColor : "";
              return (
                <>
                  <Link
                    to={
                      "/home/discussion/" + encode(data.teamId) + "?tagged=true"
                    }
                    style={{
                      textDecoration: "none",
                      backgroundColor: highList,
                    }}
                  >
                    <ListItem
                      style={{ backgroundColor: highList }}
                      button
                      id="lock-button"
                      aria-haspopup="listbox"
                      aria-controls="lock-menu"
                      aria-label="when device is locked"
                    >
                      {data.username}{" "}
                    </ListItem>
                  </Link>
                </>
              );
            })}
        </List>
      )}
      <form action={"#"} onSubmit={submitHandle}>
        {profileImageM && (
          <div style={{ display: "flex" }}>
            <img
              src={fileImage}
              style={{ width: "10%", marginBottom: "5%", marginLeft: "25%" }}
            />
            <Close
              style={{ marginRight: "70%", cursor: "pointer" }}
              onClick={handleRemoveFile}
            />
          </div>
        )}

        {profileImageDoc && (
          <div style={{ display: "flex" }}>
            <img
              src={fileImage}
              style={{ width: "10%", marginBottom: "5%", marginLeft: "25%" }}
            />
            <p>{selectDocument}</p>
            <Close
              style={{ marginRight: "70%", cursor: "pointer" }}
              onClick={handleRemoveDocument}
            />
          </div>
        )}

        {openInput && (
          <>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-chatinput"
              type="text"
              value={input}
              aria-describedby="outlined-weight-helper-text"
              placeholder={"Write a message."}
              style={styleInput}
              inputProps={{ style: { textAlign: "left", paddingLeft: 5 } }}
              onChange={handleChange}
              rows={1}
              autoComplete={"off"}
              maxRows={6}
              startAdornment={
                <InputAdornment position="start">
                  <EmojiPopover addEmoji={addEmoji} />
                  <AttachmentPop
                    onChangeMsgImage={onChangeMsgImage}
                    setProfileImageDoc={setProfileImageDoc}
                    setSelectDocument={setSelectDocument}
                    setSendButton={setSendButton}
                  />
                  <IconButton
                    aria-label="Video Call"
                    style={{ padding: 5 }}
                    onClick={handleMeetingPop}
                  >
                    <VideoCall
                      style={{
                        cursor: "pointer",
                        color:
                          state === isHoveringVd
                            ? COLORS.basfLightOrange
                            : COLORS.basfOrange,
                      }}
                      data-info="isHoveringVd"
                      onMouseEnter={() => setState("isHoveringVd")}
                      onMouseLeave={() => setState("")}
                    ></VideoCall>
                  </IconButton>
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  {sendButton && (
                    <IconButton
                      aria-label="Send"
                      style={{ padding: 5 }}
                      onClick={submitHandle}
                    >
                      <Send
                        style={{
                          cursor: "pointer",
                          color:
                            state === isHoveringSn
                              ? COLORS.basfLightOrange
                              : COLORS.basfOrange,
                        }}
                        data-info="isHoveringSn"
                        onMouseEnter={() => setState("isHoveringSn")}
                        onMouseLeave={() => setState("")}
                      ></Send>
                    </IconButton>
                  )}
                </InputAdornment>
              }
            ></OutlinedInput>
          </>
        )}
      </form>

      {popupMeeting && (
        <CreateMeeting
          popupMeeting={popupMeeting}
          handleMeetingPop={handleMeetingPop}
          teamId={teamId}
          parentId={parentId}
          scrollHandle={scrollHandle}
        />
      )}

      {/*<Dialog*/}
      {/*  open={popupSearch}*/}
      {/*  keepMounted*/}
      {/*  aria-describedby="alert-dialog-slide-description"*/}

      {/*>*/}
      {/*    <DialogTitle>{"Select User"} <Button onClick={() => setPopupSearch(!popupSearch)}><HighlightOff/></Button>*/}
      {/*    </DialogTitle>*/}
      {/*    <DialogContent>*/}
      {/*        <DialogContentText id="alert-dialog-slide-description">*/}
      {/*            {searchUser &&*/}
      {/*            <Select*/}
      {/*              multiple*/}
      {/*              native*/}
      {/*              onChange={(text) => onSelect(text)}*/}
      {/*              // @ts-ignore Typings are not considering `native`*/}
      {/*              label="Native"*/}
      {/*              inputProps={{*/}
      {/*                  id: 'select-multiple-native',*/}
      {/*              }}*/}
      {/*            >*/}
      {/*                {searchUser.data.map((data: any, i: any) => {*/}
      {/*                    let selected = (i === 0 ? true : false);*/}
      {/*                      return(*/}
      {/*                        <option key={i} selected={selected} style={{marginBottom: "3%" , width: "100%" , }}>*/}
      {/*                            @{data.username}*/}
      {/*                        </option>*/}
      {/*                      )*/}
      {/*                  })}*/}
      {/*            </Select>*/}
      {/*            }*/}
      {/*        </DialogContentText>*/}
      {/*    </DialogContent>*/}
      {/*    <DialogActions>*/}
      {/*        <Button onClick={() => setPopupSearch(!popupSearch)}>Cancel</Button>*/}
      {/*    </DialogActions>*/}
      {/*</Dialog>*/}
    </>
  );
};

export default DiscussionInput;

import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Wrapper } from "./Default.styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import LinearProgress from "@material-ui/core/LinearProgress";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Contacts, Delete, MoreVert, QueryBuilder, VerifiedUser } from "@material-ui/icons";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { BASE_URL } from "../../utils/Constants";

const useStyles = makeStyles((theme) => ({

  iconStyle: {
    fontSize: 13 ,
    marginRight: 5
  }

}))

const Default = (props: {
  children: JSX.Element | string;
  illustrationBackground: string;
  heading: string;
}) => {
  const classes = useStyles();
  const { children, illustrationBackground, heading } = props;
  const [open, setOpen] = React.useState(false);
  const { authenticated } = useSelector((state: RootState) => state.auth);
  const { token } = useSelector((state: RootState) => state.auth);
  const sLoading = useSelector((state: RootState) => state.loading);
  return (
    <Wrapper>
      {sLoading.show &&
        <Grid style={{ zIndex: 9999, position: "fixed", width: "100%", height: "100%", marginTop: "0.7%" }}>
          <LinearProgress />
        </Grid>
      }
      <Grid container spacing={3} direction={"column"} xs={12} lg={12} md={12}>
        <Grid xs={12} lg={2} md={2} sm={12}>
          <a href={BASE_URL}>
            <button className={"button"}>Back To Home</button>
          </a>
        </Grid>
        <Grid xs={12} lg={12} md={12} sm={12}>
          <h2 className={"h2"}>Hunt'r</h2>
          <h3>Happy to see you, Please {heading}</h3>
          {children}
        </Grid>
        <Grid xs={12} lg={12} md={12} sm={12}  style={{textAlign:"center"}}>
          <footer>
            <PopupState variant="popover" popupId="demoMenu" >
              {(popupState) => (
                <React.Fragment>
                  <IconButton aria-label="settings" {...bindTrigger(popupState)} style={{color: "white" , fontSize: 14}}>
                    Help ?
                  </IconButton>
                  <Menu {...bindMenu(popupState)} style={{marginTop: 46, marginLeft: "-2%"}}>
                    <MenuItem  style={{ fontSize: 13}}><Link to={"/contact-form"} style={{textDecoration: "none"}}><QueryBuilder className={clsx(classes.iconStyle)}/> FAQ</Link></MenuItem>
                    <MenuItem  style={{ fontSize: 13}}><Link to={"/contact-form"} style={{textDecoration: "none"}}><Contacts className={clsx(classes.iconStyle)}/>Contact Us</Link></MenuItem>
                  </Menu>
                </React.Fragment>
              )}
            </PopupState>
          </footer>
        </Grid>
      </Grid>

    </Wrapper>
  );
};

Default.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
  heading: PropTypes.string,
};

export default Default;

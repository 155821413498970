import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "js-base64";
import Grid from "@material-ui/core/Grid";
import { AddBox, Chat } from "@material-ui/icons";
import { Card, CardActions, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import clsx from "clsx";

import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import { COLORS } from "../../utils/Colors";
import DiscussionAction from "../../stores/actions/discussionAction";
import { RootState } from "../../stores";
import DiscussionInput from "../../components/discussion/DiscussionInput";
import ChatMessage from "../../components/discussion/ChatMessage";

const useStyles = makeStyles(() => ({
  discussButton: {
    backgroundColor: COLORS.white,
    width: 200,
    height: 40,
    display: "flex",
    alignItems: "center",
    paddingLeft: 5,
    bottom: 15,
    left: "30%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfBoxGrey,
    },
  },
}));

const DashboardPage: React.FC = () => {
  const classes = useStyles();
  const [openInput, setOpenInput] = useState(false);
  const [openButton, setOpenButton] = useState(true);
  const [closeInput, setCloseInput] = useState(false);
  const [activeScroll, setActiveScroll] = useState(true);

  const bottomRef = useRef<any>(null);

  const dispatch = useDispatch()<any>;

  const { teamId } = useParams();

  const { user } = useSelector((state: RootState) => state.auth);

  const { teamDetails } = useSelector((state: RootState) => state.discussion);

  useEffect(() => {
    // get message function
    const getTeamDetails = async () => {
      if (teamId) {
        const team_id = decode(teamId);
        const result = await dispatch(
          DiscussionAction.getTeamDetails({ team_id })
        );
        if (result.status && result.data.data.type === "Direct") {
          setOpenInput(true);
          setOpenButton(false);
          setCloseInput(true);
        }
      }
    };

    getTeamDetails();
  }, [teamId]);

  const handleInputOpen = () => {
    setOpenInput(!openInput);
    setOpenButton(!openButton);
    setCloseInput(!closeInput);
  };

  const handleInputClose = () => {
    setOpenInput(!openInput);
    setOpenButton(!openButton);
    setCloseInput(!closeInput);
  };

  const scrollHandle = () => {
    setActiveScroll(!activeScroll);
    if (bottomRef.current)
      bottomRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  let showInput =
    user &&
    user.role === "User" &&
    teamDetails &&
    teamDetails?.data?.is_default === 1
      ? false
      : true;

  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Discussion"
      icon={<Chat />}
    >
      <Grid
        container
        spacing={2}
        style={{
          width: "70%",
          position: "absolute",
        }}
      >
        <Card style={{ width: "100%" }}>
          <CardContent>
            <Grid item lg={12} md={12} sm={12} xs={12} xl={12}>
              <ChatMessage
                teamdata={decode(teamId!)}
                setOpenButton={setOpenButton}
                openButton={openButton}
                setOpenInput={setOpenInput}
                activeScroll={activeScroll}
                showInput={showInput}
              />
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Grid
              item
              lg={11}
              md={11}
              sm={11}
              xs={11}
              xl={11}
              style={{ marginLeft: "3%" }}
            >
              {openInput && (
                <>
                  <DiscussionInput
                    key={1}
                    openInput={openInput}
                    messageType={"Main"}
                    parentId={""}
                    styleInput={{
                      bottom: 15,
                      backgroundColor: "white",
                      display: "fixed",
                    }}
                    teamId={decode(teamId!)}
                    scrollHandle={scrollHandle}
                  ></DiscussionInput>
                  {/*<IconButton title="Close" onClick={handleInputClose} style={{*/}
                  {/*  bottom: 15,*/}
                  {/*  backgroundColor: "white",*/}
                  {/*}}>*/}
                  {/*  <Close />*/}
                  {/*</IconButton>*/}
                </>
              )}

              {openButton && showInput && (
                <Card
                  className={clsx(classes.discussButton)}
                  onClick={handleInputOpen}
                >
                  <AddBox key={1} />
                  New Discussion
                </Card>
              )}
            </Grid>
            {/*<Grid item lg={1} md={1} sm={1} xs={1} xl={1}>*/}
            {/*  {!openButton &&*/}
            {/*    <IconButton title="Close" onClick={handleInputClose} style={{*/}
            {/*      bottom: 15,*/}
            {/*      backgroundColor: "white",*/}
            {/*    }}>*/}
            {/*      <Close />*/}
            {/*    </IconButton>*/}
            {/*  }*/}
            {/*</Grid>*/}
          </CardActions>
        </Card>
      </Grid>
    </HomeDefault>
  );
};

export default DashboardPage;

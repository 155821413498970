import { RootState } from "../";
import { ThunkAction } from "redux-thunk";
import LoadingType from "../types/loadingType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../types/alertType";
import TaskType, {
  AddProject,
  AddProjectStatus, AddProjectTask, AddSubTask, DeleteStatus, DeleteSubTask, EditTask, GetCalenderData,
  GetProject,
  GetProjectDetails, GetProjectGant,
  GetProjectTasks, SearchTeamAndMember, TaskDelete, UpdateProject, UpdateProjectStatus, updateSubTask
} from "../types/taskType";



interface Action {
  type: string,
  message?: string,
  payload?: any
}

const TaskAction = {

  addProject: (data: AddProject) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const message: any = await axios.post(BASE_URL+"api/v1/create_task_project" , {
            project_name: data.name,
            description: data.description,
            company_id: companyId,
            start_date: data.startDate,
            end_date: data.endDate
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  updateProject: (data: UpdateProject) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const message: any = await axios.post(BASE_URL+"api/v1/update_task_project" , {
            id: data.projectId,
            project_name: data.name,
            description: data.description,
            company_id: companyId,
            start_date: data.startDate,
            end_date: data.endDate
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getProject: (data: GetProject) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const result: any = await axios.get(BASE_URL+"api/v1/get_task_project/"+companyId ,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );


        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_PROJECT,
          payload: result.data
        })


        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getProjectDetails: (data: GetProjectDetails) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(BASE_URL+"api/v1/get_project_detail/"+data.projectid ,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_PROJECT_DETAILS,
          payload: result.data
        })

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getProjectStatus: (data: GetProjectDetails) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        // dispatch({
        //   type: TaskType.SET_PROJECT_STATUS,
        //   payload: ''
        // })
        //
        // dispatch({
        //   type: TaskType.SET_STATUS,
        //   payload: ''
        // })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(BASE_URL+"api/v1/get_task_status/"+data.projectid ,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_PROJECT_STATUS,
          payload: result.data
        })

        dispatch({
          type: TaskType.SET_STATUS,
          payload: result.data.statusList
        })

        dispatch({
          type: TaskType.SET_PROJECT_TASK,
          payload: result.data.allTask
        })
        // console.log(result.data)
        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getProjectStatusOnly: (data: GetProjectDetails) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        dispatch({
          type: TaskType.SET_STATUS,
          payload: ''
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(BASE_URL+"api/v1/get_task_status_only/"+data.projectid ,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_STATUS,
          payload: result.data
        })

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  addProjectStatus: (data: AddProjectStatus) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(BASE_URL+"api/v1/create_task_status" , {
            status_name: data.name,
            project_id: data.projectid,
            color: data.color
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  updateProjectStatus: (data: UpdateProjectStatus) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(BASE_URL+"api/v1/update_task_status" , {
            status_name: data.name,
            project_id: data.projectId,
            color: data.color,
            id: data.statusId
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },


  getProjectTasks: (data: GetProjectTasks) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        dispatch({
          type: TaskType.SET_PROJECT_STATUS,
          payload: []
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(BASE_URL+"api/v1/get_task/"+data.projectid+"/"+data.statusId ,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_PROJECT_STATUS,
          payload: result.data
        })


        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getProjectTasksGantt: (data: GetProjectGant) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        dispatch({
          type: TaskType.SET_PROJECT_GANTT,
          payload: ''
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(BASE_URL+"api/v1/get_task_gantt/"+data.projectid,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: TaskType.SET_PROJECT_GANTT,
          payload: result.data
        })

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  addProjectTask: (data: AddProjectTask) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const message: any = await axios.post(BASE_URL+"api/v1/create_task" , {
            task_name: data.name,
            task_description: data.description,
            project_id: data.projectid,
            company_id: companyId,
            start_date: data.startDate,
            end_date: data.endDate,
            status: data.status,
            allocate_type: data.type,
            allocated: data.allocated,
            task_type: data.task_type,
            depend_task: data.depend_task
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  editProjectTask: (data: EditTask) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(BASE_URL+"api/v1/update_task" , {
            task_name: data.name,
            task_description: data.description,
            id: data.taskId,
            start_date: data.startDate,
            end_date: data.endDate,
            status: data.status,
            allocate_type: data.type,
            allocated: data.allocated,
            reason: data.reason,
            progress: data.progress,
            task_type: data.task_type,
            depend_task: data.depend_task
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  searchTaskAllocate: (data: SearchTeamAndMember) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: TaskType.SET_ALLOCATE,
          payload: []
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const result: any = await axios.post(BASE_URL+"api/v1/get_member_team_allocate",
          {
            company_id: companyId,
            type: data.type,
            keyword: data.keyword
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );


        dispatch({
          type: TaskType.SET_ALLOCATE,
          payload: result.data
        })

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  getCalenderTask: (data: GetCalenderData) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const result: any = await axios.post(BASE_URL+"api/v1/get_meetings",
          {
            companyId: companyId,
            date: data.date
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: TaskType.SET_CALENDER_DATE,
          payload: result.data
        })

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return result.data;


      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  deleteTaskProject: (data: GetProjectDetails) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(BASE_URL+"api/v1/delete_task_project",
          {
            data: {
              project_id: data.projectid
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  deleteTaskTask: (data: TaskDelete) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(BASE_URL+"api/v1/delete_task_task",
          {
            data: {
              task_id: data.task_id
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  deleteTaskStatus: (data: DeleteStatus) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(BASE_URL+"api/v1/delete_task_status",
          {
            data: {
              status_id: data.statusId
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  addSubTask: (data: AddSubTask) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const message: any = await axios.post(BASE_URL+"api/v1/add_sub_task" , {
            sub_task: data.subTask,
            start_date: data.startDate,
            end_date: data.endDate,
            task_id: data.taskId
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  deleteSubTask: (data: DeleteSubTask) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(BASE_URL+"api/v1/delete_sub_task",
          {
            data: {
              id: data.subTaskId
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });

        return result.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

  updateSubTask: (data: updateSubTask) : ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {

        dispatch({
          type: LoadingType.SHOW_LOADING
        })

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem("@userData:companyId");
        const message: any = await axios.post(BASE_URL+"api/v1/update_sub_task" , {
            sub_task: data.subTask,
            start_date: data.startDate,
            end_date: data.endDate,
            task_id: data.taskId,
            id: data.subTaskId
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;

      }
      catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        })
      }
    },

}

// @ts-ignore
export default TaskAction;

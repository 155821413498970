import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@material-ui/core";
import {
  Link,
  Description,
  Folder,
  PictureAsPdf,
  Image,
  Receipt,
  Notes,
  Search,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { RootState } from "../../stores";
import { COLORS } from "../../utils/Colors";
import { BASE_URL } from "../../utils/Constants";
import InitiativeAction from "../../stores/actions/initiativeAction";
import InitiativeSidePop from "./InitiativeSidePop";

const useStyles = makeStyles(() => ({
  folderFile: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
    },
  },
  button: {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.white,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },
}));

interface Props {
  setFolderId: any;
  savedDocument: boolean;
  folderId: any;
  setSaveDocument: any;
}

const InitiativeData: React.FC<Props> = ({
  setFolderId,
  savedDocument,
  folderId,
  setSaveDocument,
}) => {
  console.log({ folderId });

  const dispatch = useDispatch()<any>;
  const classes = useStyles();
  const [folders, setFolders] = useState([{ id: "", name: "" }]);
  const [onclick, setOnClick] = useState(true);
  const [documentId, setDocumentId] = useState("");
  const [openFolderDelete, setOpenFolderDelete] = useState(false);
  const [openFolderEdit, setOpenFolderEdit] = useState(false);
  const [folderNameEdit, setFolderNameEdit] = useState("");
  const [openNotesEdit, setOpenNotesEdit] = useState(false);
  const [folderNoteTitleEdit, setFolderNoteTitleEdit] = useState("");
  const [folderNoteDescEdit, setFolderNoteDescEdit] = useState("");
  const [initiatSearchName, setInitiatSearchName] = useState("");
  const [sortName, setSortName] = useState("");

  const { initiativeList } = useSelector(
    (state: RootState) => state.initiatives
  );

  // fucntion use for get file and folders
  const getInitiatives = useCallback(
    async (event: any | null) => {
      dispatch(
        InitiativeAction.getInitiatives({
          folder_id: event,
          teamId: null,
          keyword: null,
          sort: null,
        })
      );
      setSaveDocument(!savedDocument);
    },
    [dispatch, setSaveDocument]
  );

  useEffect(() => {
    getInitiatives(folderId);
  }, [getInitiatives, savedDocument]);

  const clickFolder = async (id: any, name: any) => {
    let newData = { id: id, name: name };
    setFolders([...folders, newData]);
    getInitiatives(id);
    setFolderId(id);
  };

  const backClick = async (id: any) => {
    if (folders.length > 0) {
      let newArray = [];
      for (const foldIs of folders) {
        if (foldIs && foldIs.id === id) {
          newArray.push({ id: foldIs.id, name: foldIs.name });
          break;
        } else {
          newArray.push({ id: foldIs.id, name: foldIs.name });
          setFolders(newArray);
        }
      }
    }
    if (id === "") {
      setFolders([{ id: "", name: "" }]);
    }
    setFolderId(id);
    getInitiatives(id);
  };

  const fileClick = async (url: any) => {
    window.open(url, "_blank");
  };

  const deleteFolder = async (e: any) => {
    await dispatch(InitiativeAction.removeInitiative({ document_id: e }));
    getInitiatives(folderId);
    setOpenFolderDelete(!openFolderDelete);
  };

  const updateFolder = async (id: any, name: any, desc?: string) => {
    const result = await dispatch(
      InitiativeAction.updateInitiatives({
        name: name,
        desc: desc,
        id: id,
        folder_id: folderId,
      })
    );

    if (result?.status) {
      getInitiatives(folderId);
      setOpenFolderEdit(false);
    }
  };

  const searchInitiate = async () => {
    if (initiatSearchName.length > 0) {
      dispatch(
        InitiativeAction.getInitiatives({
          folder_id: folderId,
          teamId: null,
          keyword: initiatSearchName,
          sort: sortName ? sortName : null,
        })
      );
    } else {
      getInitiatives(folderId);
    }
  };

  const handleSorting = async (e: any) => {
    setSortName(e.target.value as string);
    if (e.target.value.length > 0) {
      dispatch(
        InitiativeAction.getInitiatives({
          folder_id: folderId,
          teamId: null,
          keyword: initiatSearchName ? initiatSearchName : null,
          sort: e.target.value,
        })
      );
    } else {
      getInitiatives(folderId);
    }
  };

  return (
    <>
      <Grid container spacing={2} style={{ marginBottom: "1%" }}>
        <Grid item xs={12}>
          <Button
            className={clsx(classes.button)}
            onClick={() => backClick("")}
          >
            Main
          </Button>
          {folders &&
            folders.map((fold: any, ind: any) => {
              if (fold.id) {
                return (
                  <Button
                    className={clsx(classes.button)}
                    onClick={() => backClick(fold.id)}
                    style={{ marginLeft: "1%" }}
                    key={ind}
                  >
                    {fold.name}
                  </Button>
                );
              }
            })}

          <FormControl
            className="textField margin"
            variant="outlined"
            size="small"
            style={{ marginLeft: "50%" }}
          >
            <InputLabel htmlFor="outlined-adornment-keyword">Search</InputLabel>
            <OutlinedInput
              id="outlined-adornment-keyword"
              type={"text"}
              value={initiatSearchName}
              label={"Search"}
              required={true}
              onChange={(text) => setInitiatSearchName(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Search"
              inputProps={{ style: { textAlign: "left" } }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => searchInitiate()}
                  >
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl
            className="textField margin"
            variant="outlined"
            size="small"
          >
            <InputLabel id="demo-select-small">Sort</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              value={sortName}
              label="Color"
              onChange={handleSorting}
            >
              <MenuItem selected={true}>Sort</MenuItem>
              <MenuItem value={"Name"}>Name</MenuItem>
              <MenuItem value={"Old"}>Oldest on top</MenuItem>
              <MenuItem value={"New"}>Newest on top</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          width: "70%",
          maxHeight: "600px",
          overflowX: "hidden",
          position: "absolute",
        }}
      >
        <Grid item xs={12}>
          <h4>Folders</h4>
          {initiativeList &&
            initiativeList.data.folder.map((folder: any, index: any) => {
              let time = (
                <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                  {folder.updated_at}
                </Moment>
              );
              let id = folder.id;
              let name = folder.name;
              return (
                <>
                  <Card key={index} style={{ width: "100%", marginBottom: 4 }}>
                    <CardHeader
                      className={clsx(classes.folderFile)}
                      onMouseEnter={() => setOnClick(true)}
                      onClick={() => (onclick ? clickFolder(id, name) : "")}
                      avatar={<Folder style={{ color: COLORS.basfOrange }} />}
                      title={name}
                      subheader={time}
                      action={
                        <InitiativeSidePop
                          type="Folder"
                          dataDetails={folder}
                          setOnClick={setOnClick}
                          setOpenFolderDelete={setOpenFolderDelete}
                          openFolderDelete={openFolderDelete}
                          setDocumentId={setDocumentId}
                          setOpenFolderEdit={setOpenFolderEdit}
                          openFolderEdit={openFolderEdit}
                          setFolderNameEdit={setFolderNameEdit}
                          setOpenNotesEdit={setOpenNotesEdit}
                          openNotesEdit={openNotesEdit}
                          setFolderNoteTitleEdit={setFolderNoteTitleEdit}
                          folderNoteTitleEdit={folderNoteTitleEdit}
                          setFolderNoteDescEdit={setFolderNoteDescEdit}
                          folderNoteDescEdit={folderNoteDescEdit}
                        />
                      }
                    />
                  </Card>
                  <Divider />
                </>
              );
            })}
        </Grid>

        <Grid item xs={12}>
          <h4>Files</h4>
          {initiativeList &&
            initiativeList.data.files.map((folder: any, index: any) => {
              let time = (
                <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                  {folder.updated_at}
                </Moment>
              );
              let url =
                folder.type === "FILE"
                  ? BASE_URL + "" + folder.name
                  : folder.fileName;
              let nameShow =
                folder.type === "FILE" ? folder.fileName : folder.name;
              let icon =
                folder.type === "FILE" && folder.ext === "sql" ? (
                  <Receipt style={{ color: COLORS.basfDarkBlue }} />
                ) : folder.ext === "pdf" ? (
                  <PictureAsPdf style={{ color: COLORS.basfRed }} />
                ) : folder.ext === "jpg" ? (
                  <Image style={{ color: COLORS.basfOrange }} />
                ) : folder.ext === "png" ? (
                  <Image style={{ color: COLORS.basfOrange }} />
                ) : folder.ext === "jpeg" ? (
                  <Image style={{ color: COLORS.basfOrange }} />
                ) : folder.ext === "svg" ? (
                  <Image style={{ color: COLORS.basfOrange }} />
                ) : folder.ext === "link" ? (
                  <Link style={{ color: COLORS.basfDarkBlue }} />
                ) : folder.ext === "txt" ? (
                  <Notes style={{ color: COLORS.basfDarkBlue }} />
                ) : folder.ext === "docx" ? (
                  <Description style={{ color: COLORS.basfDarkBlue }} />
                ) : folder.ext === "xlsx" ? (
                  <Description style={{ color: COLORS.basfDarkGreen }} />
                ) : (
                  <Description />
                );

              let id = folder.id;
              return (
                <>
                  {/*<a href={url} target={"_blank"} style={{textDecoration: "none"}} >*/}
                  <Card key={index} style={{ width: "100%", marginBottom: 4 }}>
                    <CardHeader
                      className={clsx(classes.folderFile)}
                      onMouseEnter={() => setOnClick(true)}
                      onClick={() => (onclick ? fileClick(url) : "")}
                      avatar={icon}
                      title={nameShow}
                      subheader={time}
                      action={
                        <InitiativeSidePop
                          type={folder.type}
                          dataDetails={folder}
                          setOnClick={setOnClick}
                          setOpenFolderDelete={setOpenFolderDelete}
                          openFolderDelete={openFolderDelete}
                          setDocumentId={setDocumentId}
                          setOpenFolderEdit={setOpenFolderEdit}
                          openFolderEdit={openFolderEdit}
                          setFolderNameEdit={setFolderNameEdit}
                          setOpenNotesEdit={setOpenNotesEdit}
                          openNotesEdit={openNotesEdit}
                          setFolderNoteTitleEdit={setFolderNoteTitleEdit}
                          folderNoteTitleEdit={folderNoteTitleEdit}
                          setFolderNoteDescEdit={setFolderNoteDescEdit}
                          folderNoteDescEdit={folderNoteDescEdit}
                        />
                      }
                    />
                  </Card>
                  {/*</a>*/}
                  <Divider />
                </>
              );
            })}
        </Grid>
        <Grid item xs={12}>
          <h4>Notes</h4>
          {initiativeList &&
            initiativeList.data.notes.map((folder: any, index: any) => {
              let time = (
                <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                  {folder.updated_at}
                </Moment>
              );
              let url =
                folder.type === "FILE"
                  ? BASE_URL + "" + folder.name
                  : folder.fileName;
              let nameShow =
                folder.type === "FILE" ? folder.fileName : folder.name;
              let icon = <Description style={{ color: COLORS.basfDarkBlue }} />;
              let noteDescitopn =
                folder.type === "NOTE" ? folder.description : "";
              let id = folder.id;
              return (
                <>
                  <Card
                    key={index}
                    style={{ width: "100%", marginBottom: 4 }}
                    className={clsx(classes.folderFile)}
                  >
                    <CardHeader
                      onMouseEnter={() => setOnClick(true)}
                      avatar={icon}
                      title={nameShow}
                      subheader={time}
                      action={
                        <InitiativeSidePop
                          type={folder.type}
                          dataDetails={folder}
                          setOnClick={setOnClick}
                          setOpenFolderDelete={setOpenFolderDelete}
                          openFolderDelete={openFolderDelete}
                          setDocumentId={setDocumentId}
                          setOpenFolderEdit={setOpenFolderEdit}
                          openFolderEdit={openFolderEdit}
                          setFolderNameEdit={setFolderNameEdit}
                          setOpenNotesEdit={setOpenNotesEdit}
                          openNotesEdit={openNotesEdit}
                          setFolderNoteTitleEdit={setFolderNoteTitleEdit}
                          folderNoteTitleEdit={folderNoteTitleEdit}
                          setFolderNoteDescEdit={setFolderNoteDescEdit}
                          folderNoteDescEdit={folderNoteDescEdit}
                        />
                      }
                    />
                    <CardContent style={{ marginLeft: "4%" }}>
                      {noteDescitopn}
                    </CardContent>
                  </Card>
                  <Divider />
                </>
              );
            })}
        </Grid>
      </Grid>
      <Dialog
        open={openFolderDelete}
        keepMounted
        onClose={() => setOpenFolderDelete(!openFolderDelete)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Initiative?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the initiative ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderDelete(!openFolderDelete)}>
            Cancel
          </Button>
          <Button
            onClick={() => deleteFolder(documentId)}
            className={clsx(classes.button)}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFolderEdit}
        keepMounted
        onClose={() => setOpenFolderEdit(!openFolderEdit)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>{"Rename ?"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="text"
            fullWidth
            variant="standard"
            value={folderNameEdit}
            required={true}
            onChange={(text) => setFolderNameEdit(text.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderEdit(!openFolderEdit)}>
            Cancel
          </Button>
          <Button
            onClick={() => updateFolder(documentId, folderNameEdit)}
            className={clsx(classes.button)}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openNotesEdit}
        keepMounted
        onClose={() => setOpenNotesEdit(!openNotesEdit)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth={true}
        maxWidth={"xs"}
      >
        <DialogTitle>{"Update ?"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="text"
            fullWidth
            variant="standard"
            value={folderNoteTitleEdit}
            required={true}
            onChange={(text) => setFolderNoteTitleEdit(text.target.value)}
          />
          <br />
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="text"
            multiline={true}
            rows={5}
            fullWidth
            variant="standard"
            value={folderNoteDescEdit}
            required={true}
            onChange={(text) => setFolderNoteDescEdit(text.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNotesEdit(!openNotesEdit)}>
            Cancel
          </Button>
          <Button
            onClick={() =>
              updateFolder(documentId, folderNoteTitleEdit, folderNoteDescEdit)
            }
            className={clsx(classes.button)}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InitiativeData;

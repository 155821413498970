import React, { useCallback, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Chat,
  Delete,
  Edit,
  MoreVert,
  Replay,
  VerifiedUser,
} from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import DiscussionAction from "../../stores/actions/discussionAction";
import { COLORS } from "../../utils/Colors";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13,
    marginRight: 5,
  },
  button: {
    backgroundColor: COLORS.basfLightBlue,
    color: COLORS.white,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
    },
  },
}));

interface Props {
  type: any;
  isDirect: any;
  isPublic: boolean;
  teamId: any;
}

const TeamPopover: React.FC<Props> = ({ type, isDirect, teamId, isPublic }) => {
  const classes = useStyles();
  const [openPopAddMem, setOpenPopAddMem] = useState(false);
  const [openPopDeleteTeam, setOpenPopDeleteTeam] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;
  const [inputTeamMember, setInputTeamMember] = useState("");
  const [inputTeamMemberId, setInputTeamMemberId] = useState("");

  const { companyMembers, teamMembers } = useSelector(
    (state: RootState) => state.discussion
  );

  const getCompanyMember = useCallback(async () => {
    const company_id: string | any = localStorage.getItem(
      "@userData:companyId"
    );
    await dispatch(DiscussionAction.getCompanyMember({ company_id }));
  }, [dispatch]);

  const getTeamMember = useCallback(async () => {
    await dispatch(DiscussionAction.getTeamMember({ teamId }));
  }, [dispatch]);

  const getTeamData = useCallback(async () => {
    const company_id: string | any = localStorage.getItem(
      "@userData:companyId"
    );
    const get_type = false;
    const result = await dispatch(
      DiscussionAction.getTeamsAll({ type: "Team", company_id, get_type })
    );
    const result2 = await dispatch(
      DiscussionAction.getTeamsAllPersonal({
        type: "Personal",
        company_id,
        get_type,
      })
    );
  }, [dispatch]);

  const handleAddMember = async (event: any) => {
    if (event.close) {
      event.close();
    }
    getCompanyMember();
    getTeamMember();
    setOpenPopAddMem(!openPopAddMem);
  };

  const addTeamMember = async (event: any) => {
    event.preventDefault();
    const member_id = inputTeamMemberId;
    const team_id = teamId;
    const result = await dispatch(
      DiscussionAction.addTeamMember({ team_id, member_id, type: "Public" })
    );
    if (result.status) {
      setInputTeamMember(".");
      setInputTeamMemberId("");
      getTeamMember();
    }

    setButtonLoader(false);
  };

  const handleRemoveMember = async (
    team_id: any,
    member_id: any,
    type: any
  ) => {
    const result = await dispatch(
      DiscussionAction.removeTeamMember({ team_id, member_id, type })
    );
    if (result.status) {
      getTeamMember();
    }
  };

  const handleDeleteTeam = async (event: any) => {
    if (event.close) {
      event.close();
    }
    setOpenPopDeleteTeam(!openPopDeleteTeam);
  };

  const deleteTeam = async () => {
    const result = await dispatch(
      DiscussionAction.deleteTeam({ teamId: teamId })
    );
    if (result.status) {
      getTeamData();
      setOpenPopDeleteTeam(!openPopDeleteTeam);
    }
  };

  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <IconButton
              aria-label="settings"
              {...bindTrigger(popupState)}
              style={{ color: "white", alignContent: "right", fontSize: 12 }}
            >
              <MoreVert />
            </IconButton>
            <Menu
              {...bindMenu(popupState)}
              style={{ marginTop: 46, marginLeft: "-2%" }}
            >
              <MenuItem
                onClick={() => handleDeleteTeam(popupState)}
                style={{ fontSize: 13 }}
              >
                <Delete className={clsx(classes.iconStyle)} /> Delete
              </MenuItem>
              {!type && !isPublic && (
                <MenuItem
                  onClick={() => handleAddMember(popupState)}
                  style={{ fontSize: 13 }}
                >
                  <VerifiedUser className={clsx(classes.iconStyle)} />
                  Member
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      {openPopAddMem && (
        <Dialog
          open={openPopAddMem}
          keepMounted
          onClose={() => handleAddMember("")}
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle>{"Invite Member?"}</DialogTitle>
          <DialogContent>
            <form action={"#"} onSubmit={addTeamMember}>
              <Stack spacing={2}>
                <Autocomplete
                  id="free-solo-demo"
                  // calling the freeSolo prop inside the Autocomplete component
                  value={inputTeamMember || null}
                  onChange={(event: any, newValue: any | null) => {
                    setInputTeamMember(newValue.lable);
                    setInputTeamMemberId(newValue.id);
                  }}
                  freeSolo={true}
                  options={
                    companyMembers
                      ? companyMembers.data.map((option: any) => ({
                          id: option.member_id,
                          label: option.username,
                        }))
                      : [{ label: "Loading...", id: 0 }]
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Search User" />
                  )}
                />
                <Button
                  type={"submit"}
                  disabled={buttonLoader}
                  style={{
                    color: COLORS.basfWhite,
                    backgroundColor: COLORS.basfDarkBlue,
                  }}
                >
                  Add
                </Button>
              </Stack>
            </form>
            {teamMembers &&
              teamMembers.data.length > 0 &&
              teamMembers.data.map((teamData: any, indexs: number) => {
                return (
                  <Card style={{ marginTop: "3%" }} key={indexs}>
                    <CardHeader
                      title={teamData.username}
                      titleTypographyProps={{
                        style: { fontSize: 14, fontWeight: "bold" },
                      }}
                      action={
                        <Button
                          onClick={() =>
                            handleRemoveMember(
                              teamData.team_id,
                              teamData.member_id,
                              teamData.type
                            )
                          }
                        >
                          <Delete
                            style={{
                              cursor: "pointer",
                              color: COLORS.basfLightBlue,
                              fontSize: 20,
                              marginTop: "2%",
                            }}
                          />
                        </Button>
                      }
                    />
                  </Card>
                );
              })}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleAddMember("")}>Cancel</Button>
          </DialogActions>
        </Dialog>
      )}

      <Dialog
        open={openPopDeleteTeam}
        keepMounted
        onClose={() => setOpenPopDeleteTeam(!openPopDeleteTeam)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Team ?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the team ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopDeleteTeam(!openPopDeleteTeam)}>
            Cancel
          </Button>
          <Button onClick={() => deleteTeam()} className={clsx(classes.button)}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TeamPopover;

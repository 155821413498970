import { BASE_URL } from "../../utils/Constants";
import { RootState } from "../";
import AuthTypes, {
  ChangePassword,
  CheckUser,
  GetProfileData,
  GetUser,
  SendOtp,
  SignIn,
  SignOut,
  SignUp,
  SignUpInvited,
  UpdatePassword,
  UpdateProfileData,
  UpdateProfileImage,
  VerifyOTP,
  VerifyToken,
} from "../types/authTypes";
import { ThunkAction } from "redux-thunk";
import axios from "axios";
import LoadingType from "../types/loadingType";
import AlertTypes, { AlertType } from "../types/alertType";
import header from "../../components/Include/Header";
import { stringify } from "querystring";
import SettingTypes from "../types/settingType";
var FormData = require("form-data");

interface Action {
  type: string;
  payload?: any;
  token?: any;
}

const AuthAction = {
  signUp:
    (data: SignUp): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        // Creat new user account in database
        const user: any = await axios.post(BASE_URL + "api/v1/register", {
          username: data.username,
          email: data.email,
          password: data.password,
          role: data.role,
          company: data.company,
          company_name: data.company_name,
        });

        if (user.data.status) {
          const payload = {
            id: user.data.data.userdata.id,
            username: user.data.data.userdata.username,
            companyId: user.data.data.userdata.company_id,
            role: user.data.data.userdata.role,
            profile: user.data.data.userdata.profile,
          };

          dispatch({
            type: AuthTypes.SET_USER,
            payload: payload,
            token: user.data.data.token,
          });
          return user.data;
        }
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  signUpInvited:
    (data: SignUpInvited): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        // Creat new user account in database
        const user: any = await axios.post(
          BASE_URL + "api/v1/register_invited",
          {
            username: data.username,
            email: data.email,
            password: data.password,
            role: data.role,
            invitaionId: data.invitaionId,
          }
        );

        if (user.data.status) {
          const payload = {
            id: user.data.data.userdata.id,
            username: user.data.data.userdata.username,
            companyId: user.data.data.userdata.company_id,
            role: user.data.data.userdata.role,
            profile: user.data.data.userdata.profile,
          };

          dispatch({
            type: AuthTypes.SET_USER,
            payload: payload,
            token: user.data.data.token,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  checkUser:
    (data: CheckUser): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      dispatch({
        type: LoadingType.SHOW_LOADING,
      });
      try {
        let payload = null;
        // Creat new user account in database
        const result: any = await axios.post(BASE_URL + "api/v1/email_check", {
          username: data.username,
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return result;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  verifyToken:
    (data: VerifyToken): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const result: any = await axios
          .post(
            BASE_URL + "api/v1/checkToken",
            {},
            {
              headers: {
                Authorization: "Bearer " + JSON.parse(data.tokens),
              },
            }
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            if (error.response.status == 401) {
              dispatch({
                type: AuthTypes.RESET_AUTH,
                payload: "",
                token: "",
              });
            }
          });

        if (result.data.status) {
          const payload = {
            id: result.data.data.id,
            username: result.data.data.username,
            companyId: result.data.data.company_id,
            role: result.data.data.role,
            profile: result.data.data.profile,
          };
          dispatch({
            type: AuthTypes.SET_USER,
            payload: payload,
            token: JSON.parse(data.tokens),
          });
        } else {
          dispatch({
            type: AuthTypes.RESET_AUTH,
            payload: "",
            token: "",
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  signIn:
    (data: SignIn): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        // Creat new user account in database
        const user: any = await axios.post(BASE_URL + "api/v1/login", {
          username: data.username,
          password: data.password,
        });

        if (user.data.status) {
          const payload = {
            id: user.data.data.userdata.id,
            username: user.data.data.userdata.username,
            companyId: user.data.data.userdata.company_id,
            role: user.data.data.userdata.role,
            profile: user.data.data.userdata.profile,
          };

          dispatch({
            type: AuthTypes.SET_USER,
            payload: payload,
            token: user.data.data.token,
          });

          dispatch({
            type: SettingTypes.SET_COMPANY_DETAILS,
            payload: user.data.data.company,
          });
        }
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  signOUt:
    (data: SignOut): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const result: any = await axios.post(
          BASE_URL + "api/v1/logout",
          {},
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(data.tokens),
            },
          }
        );

        if (result.data.status) {
          const payload = {
            id: result.data.data.id,
            username: result.data.data.username,
          };
          dispatch({
            type: AuthTypes.RESET_AUTH,
            payload: payload,
            token: "",
          });

          dispatch({
            type: AlertTypes.SET_ALERT,
            payload: {
              type: AlertType.WARNING,
              message: result.data.message,
            },
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  sendOTP:
    (data: SendOtp): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const user: any = await axios.post(
          BASE_URL + "api/v1/sent_forgot_otp",
          {
            email: data.email,
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return user;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  verifyOtp:
    (data: VerifyOTP): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const user: any = await axios.post(BASE_URL + "api/v1/verify_otp", {
          otp: data.otp,
          email: data.email,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return user;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  updatePassword:
    (data: ChangePassword): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const user: any = await axios.post(
          BASE_URL + "api/v1/update_password",
          {
            otp: data.otp,
            email: data.email,
            password: data.password,
            confirmPassword: data.confirmPassword,
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return user;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  changePassword:
    (data: UpdatePassword): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");

        const user: any = await axios.post(
          BASE_URL + "api/v1/change_password",
          {
            old_password: data.oldPassword,
            new_Password: data.newPassword,
            confirmPassword: data.confirmPassword,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return user.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getProfileData:
    (data: GetProfileData): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios
          .post(
            BASE_URL + "api/v1/profile_details",
            {},
            {
              headers: {
                Authorization: "Bearer " + JSON.parse(token),
              },
            }
          )
          .then(function (response) {
            return response;
          })
          .catch(function (error) {
            if (error.response.status == 401) {
              dispatch({
                type: AuthTypes.RESET_AUTH,
                payload: "",
                token: "",
              });
            }
          });

        if (result.data.status) {
          dispatch({
            type: AuthTypes.GET_USER_PROFILE,
            payload: result.data,
          });
        } else {
          // dispatch({
          //   type: AuthTypes.RESET_AUTH,
          //   payload: '',
          //   token: '',
          // });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  changeProfileDetails:
    (data: UpdateProfileData): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");

        const user: any = await axios.post(
          BASE_URL + "api/v1/update_profile",
          {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
            username: data.userName,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        if (user.data?.data) {
          dispatch({
            type: AuthTypes.GET_USER_PROFILE,
            payload: user.data?.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return user.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  changeProfileImage:
    (data: UpdateProfileImage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");

        const formData = new FormData();
        formData.append("image", data.image);
        const user: any = await axios.post(
          BASE_URL + "api/v1/update_image",
          formData,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return user.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },
};

export default AuthAction;

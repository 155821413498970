import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, Paper } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import illustration from "../../assets/image/auth.png";
import Default from "../../layouts/auth/Default";
import { Wrapper } from "./SignIn.styles";
import AuthAction from "../../stores/actions/authAction";
import {
  ForgotPasswordStep1Form,
  ForgotPasswordStep1FormInputs,
} from "./components/ForgotPasswordStep1Form";
import {
  ForgotPasswordStep2Form,
  ForgotPasswordStep2FormInputs,
} from "./components/ForgotPasswordStep2Form";
import {
  ForgotPasswordStep3Form,
  ForgotPasswordStep3FormInputs,
} from "./components/ForgotPasswordStep3Form";

const SignIn = () => {
  const [email, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [step, setStep] = useState<number>(1);

  const navigate = useNavigate();
  const dispatch = useDispatch()<any>;

  // Forgot password otp send
  const handleSendOtp = async ({ email }: ForgotPasswordStep1FormInputs) => {
    const responseIs = await dispatch(AuthAction.sendOTP({ email }));
    if (responseIs.data.status) {
      setUserEmail(email);
      setStep(2);
    }
  };

  // OTP verify process
  const handleVerifyOtp = async ({ code }: ForgotPasswordStep2FormInputs) => {
    const verifies = await dispatch(AuthAction.verifyOtp({ otp: code, email }));
    if (verifies.data.status) {
      setOtp(code);
      setStep(3);
    }
  };

  //Update password process
  const handleUpdatePassword = async ({
    password,
    confirmPassword,
  }: ForgotPasswordStep3FormInputs) => {
    const updated = await dispatch(
      AuthAction.updatePassword({ email, otp, password, confirmPassword })
    );
    if (updated.data.status) {
      navigate("/");
    }
  };

  return (
    <Wrapper>
      <Default
        illustrationBackground={illustration}
        image={illustration}
        heading={`Reset your password, ${step}/3`}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "25vh" }}
        >
          <Paper elevation={3} className="paper">
            {step === 1 && <ForgotPasswordStep1Form onSubmit={handleSendOtp} />}
            {step === 2 && (
              <>
                <ForgotPasswordStep2Form onSubmit={handleVerifyOtp} />
                <br />
                <br />
                <Button
                  variant="contained"
                  color={"secondary"}
                  type="button"
                  onClick={() => handleSendOtp({ email })}
                >
                  Send OTP Again
                </Button>
              </>
            )}
            {step === 3 && (
              <ForgotPasswordStep3Form onSubmit={handleUpdatePassword} />
            )}

            <br />
            <br />
            <Button
              variant="contained"
              className="backButton"
              type="button"
              onClick={() => {
                if (step === 1) {
                  navigate("/");
                } else {
                  setStep(1);
                }
              }}
            >
              Back
            </Button>
          </Paper>
        </Grid>
      </Default>
    </Wrapper>
  );
};

export default SignIn;

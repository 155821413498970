import React, { useEffect, useState } from "react";

//Assets
import illustration from "../../assets/image/auth.png";
import clsx from "clsx";
// Default Design
import Default from "../../layouts/auth/Default";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";

//Style add
import { Wrapper } from "./SignIn.styles";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AuthAction from "../../stores/actions/authAction";

const Contactus = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({ password: "", showPassword: false });
  const [buttonLoader, setButtonLoader] = useState(false);
  const { user, authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const history = useNavigate();
  const dispatch = useDispatch()<any>;

  useEffect(() => {
    const tokens = localStorage.getItem("@userData:token");
    if ((authenticated && token) || tokens) {
      // [ROUTE] Go to dashboard page
      history("/home");
    }
  }, [authenticated, history]);

  const handleLogin = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    await dispatch(AuthAction.signIn({ username, password }));
    setButtonLoader(false);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <Wrapper>
      <Default
        illustrationBackground={illustration}
        image={illustration}
        heading="Contact us"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "25vh" }}
        >
          <form action={"#"} onSubmit={handleLogin}>
            <Paper elevation={3} className="paper">
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-fullname">
                  Enter Full Name
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-fullname"
                  value={username}
                  error={username.length ? false : true}
                  label={"Enter Full Name"}
                  required={true}
                  onChange={(text) => setUsername(text.target.value)}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter Full Name"
                  inputProps={{ style: { textAlign: "left" } }}
                />
              </FormControl>
              <br />
              <br />
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-contact">
                  Enter Contact Number
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-contact"
                  type={values.showPassword ? "text" : "password"}
                  value={password}
                  error={password.length ? false : true}
                  label={"Enter Contact Number"}
                  required={true}
                  onChange={(text) => setPassword(text.target.value)}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter Contact Number"
                />
              </FormControl>

              <br />
              <br />
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-email">
                  Enter Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-email"
                  type={"email"}
                  value={password}
                  error={password.length ? false : true}
                  label={"Enter Email"}
                  required={true}
                  onChange={(text) => setPassword(text.target.value)}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Enter Email"
                />
              </FormControl>

              <br />
              <br />
              <FormControl
                className="textField margin"
                variant="outlined"
                size="small"
              >
                <InputLabel htmlFor="outlined-adornment-comment">
                  Comment
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-comment"
                  type={"text"}
                  multiline={true}
                  rows={5}
                  value={password}
                  error={password.length ? false : true}
                  label={"Comment"}
                  required={true}
                  onChange={(text) => setPassword(text.target.value)}
                  aria-describedby="outlined-weight-helper-text"
                  placeholder="Comment"
                />
              </FormControl>
              <br />
              <br />
              <Button
                variant="contained"
                className="loginButton"
                type="button"
                disabled={buttonLoader}
              >
                Submit
              </Button>
              <br />
              <br />
              <Link
                to={"/"}
                style={{ color: "#586EF5", textDecoration: "none" }}
              >
                Login Page ?
              </Link>
              <br />
              {buttonLoader && (
                <CircularProgress size={24} className="buttonProgress" />
              )}
            </Paper>
          </form>
        </Grid>
      </Default>
    </Wrapper>
  );
};

export default Contactus;

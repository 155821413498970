import SettingTypes from "../types/settingType";

interface SettingAction {
  type: string,
  payload?: any
}

const initial_state =  {
  companyData: null,
  companyDetails: null
}


const settingReducer = (state = initial_state, action: SettingAction) => {
  switch (action.type) {

    case SettingTypes.SET_COMPANY:
      return {
        ...state,
        companyData: action.payload
      }

    case SettingTypes.SET_DEFAULT_COMPANY:
      localStorage.removeItem("@userData:companyId");
      localStorage.setItem("@userData:companyId", JSON.stringify(action.payload.companyId));
      return {
        ...state,
      }

    case SettingTypes.SET_COMPANY_DETAILS:
      return {
        ...state,
        companyDetails: action.payload
      }


    default:
      return state;

  }
}


export default settingReducer;

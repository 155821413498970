import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { Chat, Delete, Edit, MoreVert, Replay } from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { decode, encode } from "js-base64";
import { bool } from "yup";
import DiscussionAction from "../../stores/actions/discussionAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13,
    marginRight: 5,
  },
}));

interface Props {
  messageId: any;
  isMain: any;
  teamId: any;
  isOwner?: boolean;
  isFeed?: boolean;
}

const ChatSidePop: React.FC<Props> = ({
  messageId,
  isMain,
  teamId,
  isOwner = false,
  isFeed = false,
}) => {
  const classes = useStyles();
  const [openDeleteMessage, setDeleteMessage] = useState(false);
  // const message_id = decode(messageId)

  const handleDeleteMessage = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setDeleteMessage(!openDeleteMessage);
  };

  const dispatch = useDispatch<any>();

  const deleteMessage = async () => {
    await dispatch(
      DiscussionAction.deleteChatMessage({
        messageId: String(messageId),
        isFeed,
      })
    );
    setDeleteMessage(!openDeleteMessage);
  };

  if (isMain !== "true" && !isOwner) {
    return null;
  }

  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <IconButton aria-label="settings" {...bindTrigger(popupState)}>
              <MoreVert />
            </IconButton>
            <Menu
              {...bindMenu(popupState)}
              style={{ marginTop: 46, marginLeft: "-2%" }}
            >
              {isMain === "true" && (
                <MenuItem onClick={popupState.close} style={{ fontSize: 13 }}>
                  <Link
                    to={
                      "/home/chat/" + encode(teamId) + "/" + encode(messageId)
                    }
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Chat className={clsx(classes.iconStyle)} />
                    Discuss In Feed
                  </Link>
                </MenuItem>
              )}
              {isMain === "true" && <Divider></Divider>}

              {isOwner && (
                <MenuItem
                  onClick={() => handleDeleteMessage(popupState)}
                  style={{ fontSize: 13 }}
                >
                  <Delete className={clsx(classes.iconStyle)} /> Delete
                </MenuItem>
              )}

              {/*{isOwner && (*/}
              {/*  <MenuItem onClick={popupState.close} style={{ fontSize: 13 }}>*/}
              {/*    <Edit className={clsx(classes.iconStyle)} />*/}
              {/*    Edit*/}
              {/*  </MenuItem>*/}
              {/*)}*/}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <Dialog
        open={openDeleteMessage}
        keepMounted
        onClose={() => setDeleteMessage(!openDeleteMessage)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Discussion?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the discussion ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setDeleteMessage(!openDeleteMessage);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteMessage}>Confirm</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatSidePop;

import React, { MouseEventHandler, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { encode } from "js-base64";
import { Button } from "@material-ui/core";
import axios from "axios";

import DiscussionAction from "../../stores/actions/discussionAction";
import AuthAction from "../../stores/actions/authAction";
import { COLORS } from "../../utils/Colors";
import introPng from "../../assets/image/introduction.png";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../../stores/types/alertType";

const HomePage: React.FC = () => {
  const dispatch = useDispatch()<any>;
  const navigator = useNavigate();
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const tokens = localStorage.getItem("@userData:token");
    if (!tokens) {
      navigator("/");
    } else {
      (async () => {
        // socket.emit('identity' , tokens)
        await dispatch(AuthAction.verifyToken({ tokens }));
        const company_id: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const get_type = true;
        const type = "Team";
        const result = await dispatch(
          DiscussionAction.getTeams({ type, company_id, get_type })
        );

        if (result && result.status) {
          navigator("/home/discussion/" + encode(result.data[0].id));
        } else {
          setShowMessage(!showMessage);
        }
      })();
    }
  }, [dispatch]);

  const handleOkay = async () => {
    const tokens: any = localStorage.getItem("@userData:token");
    await dispatch(AuthAction.signOUt({ tokens }));
    navigator("/");
  };

  const [availableCompanies, setAvailableCompanies] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<string | null>(null);

  useEffect(() => {
    const getAvailableCompanies = async () => {
      try {
        const token: string | any = localStorage.getItem("@userData:token");

        const response: any = await axios.get(
          `${BASE_URL}api/v1/company-request/get-available-companies`,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (response.data?.status) {
          setAvailableCompanies(response.data?.data);
        }
      } catch (e: any) {
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: e?.message,
          },
        });
      }
    };

    getAvailableCompanies();
  }, []);

  const sendRequest = async (id: number) => {
    try {
      const token: string | any = localStorage.getItem("@userData:token");

      const fd = new FormData();
      fd.append("company_id", String(id));

      const response: any = await axios.post(
        `${BASE_URL}api/v1/company-request/send-join-request`,
        fd,
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        }
      );

      if (response.data?.status) {
        setAvailableCompanies((prevState) => {
          const index = prevState.findIndex((p: any) => p?.id === id);
          if (index > -1) {
            prevState[index].status = "PENDING";
          }
          return prevState;
        });
      }
    } catch (e: any) {
      dispatch({
        type: AlertTypes.SET_ALERT,
        payload: {
          type: AlertType.WARNING,
          message: e?.message,
        },
      });
    }
  };

  const getStatusButton = (status: string, id: number) => {
    if (status === "ACCEPTED") {
      return (
        <Button
          disabled={true}
          size={"small"}
          style={{
            background: COLORS.basfLightGreen,
            color: COLORS.basfWhite,
          }}
        >
          Accepted
        </Button>
      );
    } else if (status === "REJECTED") {
      return (
        <Button
          disabled={true}
          size={"small"}
          style={{
            background: COLORS.basfRed,
            color: COLORS.basfWhite,
          }}
        >
          Rejected
        </Button>
      );
    } else if (status === "PENDING") {
      return (
        <Button
          disabled={true}
          size={"small"}
          style={{
            background: COLORS.basfOrange,
            color: COLORS.basfWhite,
          }}
        >
          Pending
        </Button>
      );
    } else {
      return (
        <Button
          disabled={Number(isLoading) === id}
          style={{
            background: COLORS.basfDarkBlue,
            color: COLORS.basfWhite,
            padding: "6px 4px",
          }}
          onClick={async () => {
            setIsLoading(String(id));
            await sendRequest(id);
            setIsLoading(null);
          }}
        >
          Send Request
        </Button>
      );
    }
  };

  console.log(availableCompanies);

  return (
    <>
      {showMessage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // textAlign: "center",
            marginTop: "5%",
            marginBottom: "5%",
            color: "#666",
          }}
        >
          <img src={introPng} width={250} />
          <h1 style={{ color: COLORS.basfDarkBlue, textAlign: "center" }}>
            Hunt'r
          </h1>

          <h2 style={{ color: COLORS.basfLightBlue, textAlign: "center" }}>
            Welcome to Hunt'r Collaboration Panel
          </h2>

          <p style={{ marginTop: "1%" }}>
            Dear, Customer thank you for join the hunt'r Collaboration panel.
          </p>

          <p style={{ color: COLORS.basfLightBlue, textAlign: "center" }}>
            Here is the list of all the available companies, you can send
            request and wait for request to approve,
            <br />
            after that you can access the collaboration panel
          </p>

          <br />

          <div
            style={{
              maxHeight: "50vh",
              overflow: "auto",
              width: "50%",
              border: "1px solid #ccc",
              padding: "24px",
              borderRadius: "6px",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid #ccc",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px",
                background: "#dedede",
              }}
            >
              <div style={{ width: "5%" }}>#</div>
              <div style={{ flex: "1 1" }}>
                <b>Project</b>
              </div>
              <div style={{ flex: "1 1" }}>
                <b>Vendor</b>
              </div>
              <div style={{ flex: "1 1" }}>
                <b>Status</b>
              </div>
            </div>
            {availableCompanies.map((company: any, index) => {
              return (
                <div
                  style={{
                    borderBottom: "1px solid #ccc",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <div style={{ flex: "1 1" }}>{index + 1}</div>
                  <div style={{ flex: "1 1" }}>{company.name}</div>
                  <div style={{ flex: "1 1" }}>{company.vendor_name}</div>
                  <div style={{ flex: "1 1" }}>
                    {getStatusButton(company.status, company.id)}
                  </div>
                </div>
              );
            })}
          </div>

          <br />

          <Button
            style={{
              background: COLORS.basfDarkBlue,
              color: COLORS.basfWhite,
              width: "10%",
            }}
            onClick={() => handleOkay()}
          >
            Logout
          </Button>
          <br />
        </div>
      )}
    </>
  );
};

export default HomePage;

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import AuthAction from "../../stores/actions/authAction";

interface Props {
  userData: any;
}

const ProfileData: React.FC<Props> = ({ userData }) => {
  const [username, setUsername] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setUserEmail] = React.useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;

  useEffect(() => {
    if (userData) {
      setFirstname(userData.data.first_name);
      setLastname(userData.data.last_name);
      setUsername(userData.data.username);
      setUserEmail(userData.data.email);
    }
  }, []);

  const handleUpdateProfile = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    const firstName = firstname;
    const lastName = lastname;
    const userName = username;
    const result = await dispatch(
      AuthAction.changeProfileDetails({ firstName, lastName, userName, email })
    );
    setButtonLoader(false);
  };

  return (
    <WrapperProfile>
      <form action={"#"} onSubmit={handleUpdateProfile}>
        <Paper style={{ padding: 30, width: "70%" }}>
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-first_name">
              Enter First Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-first_name"
              value={firstname}
              error={firstname && firstname.length ? false : true}
              label={"Enter First Name"}
              required={true}
              onChange={(text) => setFirstname(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter First Name"
              inputProps={{ style: { textAlign: "left" } }}
            />
          </FormControl>
          <br />
          <br />
          <FormControl size={"small"} fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-last_name">
              Enter Last Name
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-last_name"
              value={lastname}
              error={lastname && lastname.length ? false : true}
              label={"Enter Last Name"}
              required={true}
              onChange={(text) => setLastname(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter Last Name"
              inputProps={{ style: { textAlign: "left" } }}
            />
          </FormControl>
          <br />
          <br />
          <FormControl size={"small"} fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-username">
              Enter Username
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-username"
              value={username}
              error={username && username.length ? false : true}
              label={"Enter Username"}
              required={true}
              onChange={(text) => setUsername(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter Username"
              inputProps={{ style: { textAlign: "left" } }}
            />
          </FormControl>
          <br />
          <br />
          <FormControl size={"small"} fullWidth={true} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-email">
              Enter Email
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-email"
              value={email}
              error={email && email.length ? false : true}
              label={"Enter Email"}
              required={true}
              onChange={(text) => setUserEmail(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter Email"
              inputProps={{ style: { textAlign: "left" } }}
            />
          </FormControl>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              className="loginButton"
              type="submit"
              disabled={buttonLoader}
            >
              Update
            </Button>
          </Grid>
        </Paper>
      </form>
    </WrapperProfile>
  );
};

export default ProfileData;

const WrapperProfile = styled.div`
  .loginButton {
    background-color: rgb(74 141 211);
    width: 20%;
    margin-top: 5%;
    height: 45px;
    border-radius: 25px;
    color: white;
  }

  .loginButton:hover {
    background-color: #004a96;
  }
`;

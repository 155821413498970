import React, { lazy, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { encode, decode } from 'js-base64';

import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import Grid from "@material-ui/core/Grid";
import {
  Chat,
} from "@material-ui/icons";
import {
  Avatar, Button,
  Card, CardActions,
  CardContent, IconButton, List
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/Colors";
import DiscussionInput from "../../components/discussion/DiscussionInput";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import MessageBox from "../../components/discussion/MessageBox";


const initialPlayerState = {
  status: false,
  message: "",
  data: [
    {
      "id": null,
      "company_id": null,
      "name": null,
      "description": null,
      "icon": null,
      "is_default": null,
      "owner": null,
      "visible_to": null,
      "deleted": null,
      "deleted_at": null,
      "created_at": null,
      "updated_at": null
    }
  ],
};

const useStyles = makeStyles((theme) => ({
  discussButton: {
    backgroundColor: COLORS.white,
    width: 200,
    height: 40,
    display: "flex",
    alignItems: "center",
    position: "fixed",
    paddingLeft: 5,
    bottom: 15,
    left: "30%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfBoxGrey,
    },
  },

}));

const SingleMessagePage: React.FC = () => {
  const classes = useStyles();
  const [openInput, setOpenInput] = useState(true);
  const [openButton, setOpenButton] = useState(true);
  const [closeInput, setCloseInput ] = useState(false);
  const [itemId, setItemIs ] = useState();
  const navigator = useNavigate();
  const [activeScroll, setActiveScroll ] = useState(true);
  const bottomRef =  useRef<any>(null);


  const dispatch = useDispatch()<any>;
  const listInnerRef = useRef();
  const query = useQueryClient();
  const { message_id } = useParams();
  const { team_id } = useParams();


  const scrollHandle = () => {
    setActiveScroll(!activeScroll)
    if (bottomRef.current)
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Discussion"
      icon={<Chat />}
    >

      <Grid container spacing={2}  style={{
        width: "70%",
        position:"absolute",
      }}>

        <Card style={{width: "100%" }}>
          <CardContent>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MessageBox  messageId={decode(message_id!)}  teamId={decode(team_id!)} activeScroll={activeScroll}></MessageBox>
            </Grid>
          </CardContent>
          <CardActions disableSpacing>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <DiscussionInput
                key={1}
                openInput={openInput}
                messageType={"Header"}
                parentId={decode(message_id!)}
                styleInput={{
                  bottom: 15,
                  backgroundColor: "white",
                }}
                teamId={decode(team_id!)}
                scrollHandle={scrollHandle}
              ></DiscussionInput>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </HomeDefault>
  );
};

export default SingleMessagePage;

import { Route, Routes } from "react-router-dom";
import ForgotPassword from "../../views/auth/ForgotPassword";
import SignIn from "../../views/auth/SignIn";
import SignUp from "../../views/auth/SignUp";
import Invited from "../../views/auth/Invited";
import Contactus from "../../views/auth/Contactus";
import { LandingPage } from "../../views/auth/Landing-page";
import { PrivacyPolicy } from "../../views/auth/PrivacyPolicy";

const Auth = () => {
  return (
    <Routes>
      <Route path={"/"} element={<LandingPage />} />
      <Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
      <Route path={"/sign-in"} element={<SignIn />} />
      <Route path={"/forgot-password"} element={<ForgotPassword />} />
      <Route path={"/sign-up"} element={<SignUp />} />
      <Route path={"/invited/:invitedId"} element={<Invited />} />
      <Route path={"/contact-form"} element={<Contactus />} />
    </Routes>
  );
};

export default Auth;

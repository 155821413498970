import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button, Card, CardContent, CardHeader, Dialog,
  DialogActions,
  DialogContent, Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput, Tab, Tabs, Typography
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { COLORS } from "../../utils/Colors";
import DiscussionAction from "../../stores/actions/discussionAction";
import Moment from "react-moment";
import ChatSidePop from "./ChatSidePop";
import { BASE_URL, validImageTypes } from "../../utils/Constants";
import fileImage from "../../assets/image/free-file-icon-1453-thumb.png";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface Props {
  teamId: any;
  searchModal: boolean;
  handleSearchModal: any;
}

const MessageSearchBox: React.FC<Props> = ({teamId , searchModal, handleSearchModal}) => {
  const [keyword , setKeyword] = useState('');
  const [value, setValue] = React.useState(0);
  const [searchResult , setSearchResult] = useState({files: [] , chat: []});
  const [buttonLoader, setButtonLoader] = useState(false);

  const dispatch = useDispatch()<any>;

  const handleTabChange = (event: React.SyntheticEvent | any, newValue: any) => {
    setValue(newValue);

  };

  /**
   * process for search message data
   */
  const searchMessage = async (event: any) => {
    setButtonLoader(true)
    const team_id = teamId;
    const keyword = event;
    const result = await dispatch(DiscussionAction.searchDiscussion({team_id , keyword}))
    if(result && result.status) {
      setSearchResult(result.data)
    }
    setButtonLoader(false)
  }

  const handleSearchMessage = (event: any) => {
    setKeyword(event)
    searchMessage(event)
  }

  return (
    <>
    <Dialog open={searchModal} onClose={handleSearchModal} maxWidth={"xs"} fullWidth={true} >
        <form action={"#"} >
          <DialogContent >
            <FormControl
              className="textField margin"
              variant="outlined"
              size="small"
              style={{width: "100%"}}
            >
              <InputLabel htmlFor="outlined-adornment-search">
                Search
              </InputLabel>
              <OutlinedInput
                autoFocus
                id="outlined-adornment-search"
                type={"text"}
                value={keyword}
                label={"Search"}
                required={true}
                onChange={(text) => handleSearchMessage(text.target.value)}
                aria-describedby="outlined-weight-helper-text"
                placeholder="Search"
                inputProps={{ style: { textAlign: "left" } }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <Search/>
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <br/>
          </DialogContent>
          <DialogContent style={{minHeight: 250, alignItems: "center"}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    TabIndicatorProps={{style:{backgroundColor: COLORS.basfLightBlue , color: COLORS.basfLightBlue }}}
              >
                <Tab label={"Messages"} {...a11yProps(0)}  />
                <Tab label={"Files"} {...a11yProps(1)}  />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Grid style={{height: "250px" , overflow:"scroll"}}>
              {buttonLoader &&
                <p>Loding.....</p>
              }
              {searchResult && searchResult.chat.map((data: any, index: any) => {
                let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{data.created_at}</Moment>
                let name = data.first_name + ' ' + data.last_name;
                return (
                  <div key={index}>
                  <Card>
                    <CardHeader
                      avatar={
                        <IconButton
                          size="small"
                          aria-controls={'account-menu' ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={'true' ? 'true' : undefined}

                        >
                          <Avatar
                            style={{
                              width: 32,
                              height: 32,
                              textAlign: "right",
                              float: "right"
                            }}>LS</Avatar>
                        </IconButton>
                      }
                      title={name}
                      subheader={time}
                    />

                    <CardContent style={{ marginLeft: 60 }}>
                      <Typography paragraph>
                        {data.content}
                      </Typography>
                    </CardContent>
                  </Card>
                  <Divider/>
                  </div>
                );
              })}
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Grid style={{height: "250px" , overflow:"scroll"}}>
                {buttonLoader &&
                  <p>Loding.....</p>
                }
                {searchResult && searchResult.files.map((dataIs: any, indexJ: any) => {
                  let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{dataIs.created_at}</Moment>
                  let name = dataIs.first_name + ' ' + dataIs.last_name;
                  let id = dataIs.id;
                  let ext = dataIs.files ? dataIs.files.split('.').pop() : '';
                  let file_name = validImageTypes.includes(ext) ? BASE_URL + '' + dataIs.files : fileImage;
                  let file = dataIs.file_name;
                  return (
                    <div key={indexJ}>
                      <Card>
                        <CardHeader
                          avatar={
                            <IconButton
                              size="small"
                              aria-controls={'account-menu' ? 'account-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={'true' ? 'true' : undefined}

                            >
                              <Avatar
                                style={{
                                  width: 32,
                                  height: 32,
                                  textAlign: "right",
                                  float: "right"
                                }}>LS</Avatar>
                            </IconButton>
                          }
                          title={name}
                          subheader={time}
                        />

                        <CardContent style={{ marginLeft: 60 }}>
                          <Typography paragraph>
                            {(dataIs.files ?
                              <a href={BASE_URL + '' + dataIs.files} target={"_blank"} style={{textDecoration:"none"}}>
                                <img src={file_name}
                                     alt={file}
                                                                                          style={{
                                                                                            width: "10%",
                                                                                            float: "left"
                                                                                          }} />

                                {file}
                              </a> : '')}
                            {dataIs.content}
                          </Typography>
                        </CardContent>
                      </Card>
                      <Divider/>
                    </div>
                  );
                })}
              </Grid>
            </TabPanel>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSearchModal}>Cancel</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default MessageSearchBox;


import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Add, NoteAdd } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useSelector } from "react-redux";
import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import { COLORS } from "../../utils/Colors";
import InitiativeCreate from "../../components/initiatives/InitiativeCreate";
import InitiativeData from "../../components/initiatives/InitiativeData";
import { RootState } from "../../stores";

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: COLORS.basfOrange,
    width: 60,
    height: 60,
    display: "flex",
    padding: 14,
    borderRadius: 50,
    position: "absolute",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },
}));

const Initiative = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPop, setOpenPop] = useState(false);
  const [folderId, setFolderId] = useState("");
  const [saveInitiative, setSaveInitiative] = useState(false);

  const openAddPopup = () => {
    setOpenPop(!openPop);
  };

  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <>
      <HomeDefault
        illustrationBackground={illustration}
        image={illustration}
        heading="Initiative"
        icon={<NoteAdd />}
      >
        <Grid>
          <InitiativeData
            setFolderId={setFolderId}
            savedDocument={saveInitiative}
            folderId={folderId}
            setSaveDocument={setSaveInitiative}
          />

          {user && user.role != "User" && (
            <div className={clsx(classes.addButton)} onClick={openAddPopup}>
              <Add
                style={{
                  fontSize: "32px",
                  color: "white",
                }}
              />
            </div>
          )}
        </Grid>
      </HomeDefault>

      <InitiativeCreate
        anchorEl={anchorEl}
        openPop={openPop}
        openAddPopup={openAddPopup}
        folderId={folderId}
        setSaveInitiative={setSaveInitiative}
        saveInitiative={saveInitiative}
      />
    </>
  );
};

export default Initiative;

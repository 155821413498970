import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";

import AuthReducer from "./reducers/authReducer";
import LoadingReducer from "./reducers/loadingReducer";
import AlertReducer from "./reducers/alertReducer";
import DiscussionReducer from "./reducers/discussionReducer";
import SettingReducer from "./reducers/settingReducer";
import TaskReducer from "./reducers/taskReducer";
import DocumentReducer from "./reducers/documentReducer";
import InitiativeReducer from "./reducers/initiativeReducer";
import MessageLoadReducer from "./reducers/messageLoadReducer";

const rootReducer = combineReducers({
  loading: LoadingReducer,
  auth: AuthReducer,
  alert: AlertReducer,
  discussion: DiscussionReducer,
  setting: SettingReducer,
  tasks: TaskReducer,
  documents: DocumentReducer,
  initiatives: InitiativeReducer,
  messageLoad: MessageLoadReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export type RootState = ReturnType<typeof rootReducer>;

export default store;

import { RootState } from "../";
import { ThunkAction } from "redux-thunk";
import LoadingType from "../types/loadingType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../types/alertType";
import DocumentType, {
  AddDocument,
  AddFolder,
  AddLink,
  ConfigDoc,
  GetDocument,
  RemoveConfig,
  RemoveFolder,
  UpdateFolder,
} from "../types/documentType";
import FormData from "form-data";

interface Action {
  type: string;
  message?: string;
  payload?: any;
}

const DocumentAction = {
  addFolders:
    (data: AddFolder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_folder",
          {
            name: data.name,
            company_id: companyId,
            folder_id: data.folder_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addLinks:
    (data: AddLink): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_link",
          {
            name: data.name,
            fileName: data.link,
            company_id: companyId,
            folder_id: data.folder_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addDocuments:
    (data: AddDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );

        const formData = new FormData();
        formData.append("document", data.attachment);
        formData.append("company_id", companyId);
        formData.append("folder_id", data.folder_id);

        const message: any = await axios.post(
          BASE_URL + "api/v1/upload_document",
          formData,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getDocuments:
    (data: GetDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        dispatch({
          type: DocumentType.SET_FOLDER,
          payload: "",
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const urlIs = data.folder_id
          ? BASE_URL +
            "api/v1/get_folder_file/" +
            companyId +
            "/" +
            data.folder_id +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort
          : BASE_URL +
            "api/v1/get_folder_file/" +
            companyId +
            "/" +
            null +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort;
        const message: any = await axios.get(urlIs, {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: DocumentType.SET_FOLDER,
          payload: message.data,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getCustomerDocuments:
    (data: GetDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        dispatch({
          type: DocumentType.SET_FOLDER_CUSTOMER,
          payload: "",
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const urlIs = data.folder_id
          ? BASE_URL +
            "api/v1/get_folder_file_customer/" +
            companyId +
            "/" +
            data.folder_id +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort
          : BASE_URL +
            "api/v1/get_folder_file_customer/" +
            companyId +
            "/" +
            null +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort;
        const message: any = await axios.get(urlIs, {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        if (message.data.status) {
          dispatch({
            type: DocumentType.SET_FOLDER_CUSTOMER,
            payload: message.data,
          });

          return message.data;
        } else {
          dispatch({
            type: AlertTypes.SET_ALERT,
            payload: {
              type: AlertType.WARNING,
              message: message.data.message,
            },
          });
        }
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getDiscussionDocuments:
    (data: GetDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        dispatch({
          type: DocumentType.SET_DISCUSSION_FOLDER,
          payload: "",
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const urlIs = data.folder_id
          ? BASE_URL +
            "api/v1/get_folder_file_discussion/" +
            companyId +
            "/" +
            data.folder_id +
            "/" +
            data.teamId
          : BASE_URL +
            "api/v1/get_folder_file_discussion/" +
            companyId +
            "/" +
            null +
            "/" +
            data.teamId;
        const message: any = await axios.get(urlIs, {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: DocumentType.SET_DISCUSSION_FOLDER,
          payload: message.data,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  configDocument:
    (data: ConfigDoc): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );

        const message: any = await axios.post(
          BASE_URL + "api/v1/config_folder",
          {
            company_id: companyId,
            folder_id: data.folder_id,
            team_id: data.teamId,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  removeConfigDocument:
    (data: RemoveConfig): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/remove_config_folder",
          {
            config_id: data.config_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  removeDocument:
    (data: RemoveFolder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.delete(
          BASE_URL + "api/v1/delete_document",
          {
            data: {
              document_id: data.document_id,
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  updateFolders:
    (data: UpdateFolder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );

        const message: any = await axios.post(
          BASE_URL + "api/v1/update_folder",
          {
            name: data.name,
            id: data.id,
            folder_id: data.folder_id,
            company_id: companyId,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },
};

export default DocumentAction;

export const COLORS = {
    primary: "#21A0D2",
    secondary: "#e9ecf2",
    white: "#FFFFFF",
    grey: {
        light: "#fcfcfc",
        default: "#dee2e6",
        dark: "#262c33",
        darker: "#21272f",
    },
    basfDarkBlue: '#004A96',
    basfLightBlue: '#21A0D2',
    basfDarkGreen: '#00793A',
    basfLightGreen: '#65AC1E',
    basfOrange: '#F39500',
    basfRed: '#C50022',
    basfBlack: '#000000',
    basfDarkGrey: '#7C7C7C',
    basfMiddleGrey: '#ADADAD',
    basfLightGrey: '#DCDCDC',
    basfWhite: '#FFFFFF',
    basfBoxGrey: '#F0F0F0',
    basfCopyTextGrey: '#333333',
    basfPaleDarkBlue: '#E0E9F2',
    basfPaleLightBlue: '#E0F2F9',
    basfPaleDarkGreen: '#D9EBE2',
    basfPaleLightGreen: '#E0EED2',
    basfPaleOrange: '#FDEFD9',
    basfPaleRed: '#F8E0E4',
    hoverColor: '#71c3e3',
    hoverColorSecond: '#b3dbd4',
    basfLightOrange: '#EFB65CFF',
};

import React, {useEffect, useRef, useState} from "react";
import {ListItemIcon, Menu, MenuItem} from "@material-ui/core";
import { AttachFile,  Folder, Note } from "@material-ui/icons";
import InitiativeAddPopover from "./InitiativeAddPopover";
import { useDispatch } from "react-redux";
import { COLORS } from "../../utils/Colors";
import InitiativeAction from "../../stores/actions/initiativeAction";
import InitiativeNotesPop from "./InitiativeNotesPop";
import LinkAddPopover from "../documents/LinkAddPopover";

interface Props {
  anchorEl: any;
  openPop: boolean;
  openAddPopup: any;
  folderId: any
  setSaveInitiative: any,
  saveInitiative: any
}

const InitiativeCreate: React.FC<Props> = ({anchorEl , openPop , openAddPopup  , folderId , setSaveInitiative , saveInitiative}) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogNotes, setOpenDialogNotes] = useState(false);
  const input = React.useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()<any>;

  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  const handleDialogNotes = () => {
    setOpenDialogNotes(!openDialogNotes);
  };

  const onChangeAttachment = async (event: any) => {
    event.preventDefault();
    const attachment = event.target.files[0];
    const folder_id = folderId;
    const result     = await dispatch(InitiativeAction.addFiles({attachment , folder_id}));
    if (result && result.status) {
      setSaveInitiative(!saveInitiative)
      event.target.value = null;
    }

  }

  return (
    <>
      <input
        type="file"
        hidden
        name={"image"}
        ref={input}
        onChange={onChangeAttachment}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openPop}
        onClose={openAddPopup}
        onClick={openAddPopup}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        style={{left:"36%" , top: "70%", minHeight:" 250px"}}
      >
        <MenuItem  onClick={() => input.current && input.current.click()}>
          <ListItemIcon>
            <AttachFile fontSize="small" style={{color: COLORS.basfDarkBlue}} />
          </ListItemIcon>
          File Upload
        </MenuItem>
        <MenuItem  onClick={() => handleDialogNotes()}>
          <ListItemIcon>
            <Note fontSize="small" style={{color: COLORS.basfLightBlue}}/>
          </ListItemIcon>
          Create Notes
        </MenuItem>
        <MenuItem onClick={handleDialog}>
          <ListItemIcon>
            <Folder fontSize="small" style={{color:COLORS.basfOrange}}/>
          </ListItemIcon>
          Create Initiative Folder
        </MenuItem>
      </Menu>
      {openDialogNotes &&
        <InitiativeNotesPop
          openDialogNotes={openDialogNotes}
          handleDialogNotes={handleDialogNotes}
          folderId={folderId}
          setSaveInitiative={setSaveInitiative}
          saveInitiative={saveInitiative}
        ></InitiativeNotesPop>
      }

      {openDialog &&
        <InitiativeAddPopover
          openDialog={openDialog}
          handleDialog={handleDialog}
          folderId={folderId}
          setSavedDocument={setSaveInitiative}
          saveDocument={saveInitiative}
        />
      }
    </>
  );
}

export default InitiativeCreate;

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ChatSidePop from "./ChatSidePop";
import ReplySection from "./ReplySection";
import Moment from "react-moment";
import DiscussionAction from "../../stores/actions/discussionAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { BASE_URL, socket, validImageTypes } from "../../utils/Constants";
import fileImage from "../../assets/image/free-file-icon-1453-thumb.png";
import discussionPng from "../../assets/image/discussion.png";
import { ChatBubble, Search } from "@material-ui/icons";
import { COLORS } from "../../utils/Colors";
import MessageSearchBox from "./MessageSearchBox";
import MessageFiles from "./MessageFiles";
import Discussion from "../../views/home/Discussion";
import { Link } from "react-router-dom";
import { encode } from "js-base64";
import typingImg from "../../assets/image/typing.gif";

const useStyles = makeStyles((theme) => ({
  chatGrid: {
    maxHeight: "600px",
    overflowY: "scroll",
  },
  chatCard: {
    cursor: "pointer",
  },

  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 10,
  },

  joinButton: {
    backgroundColor: COLORS.basfPaleRed,
    color: COLORS.basfRed,
    paddingBottom: 4,
    paddingTop: 4,
    paddingLeft: 9,
    paddingRight: 9,
    borderRadius: 20,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfRed,
      color: COLORS.basfPaleRed,
    },
  },

  button: {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    marginLeft: "3%",
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },

  buttonUser: {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    marginLeft: "3%",
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      color: COLORS.basfDarkBlue,
    },
  },

  buttonShowMsg: {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    marginLeft: "3%",
    paddingRight: 4,
  },
}));

interface Props {
  teamdata: any;
  setOpenButton: any;
  openButton: any;
  setOpenInput: any;
  activeScroll: any;
  showInput: any;
}

const ChatMessage: React.FC<Props> = ({
  teamdata,
  setOpenButton,
  openButton,
  setOpenInput,
  activeScroll,
  showInput,
}) => {
  const [openSide, setOpenSide] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const [searchModal, setSearchModal] = useState(false);
  const [messageLimit, setMessageLimit] = useState("20");
  const [totalMessage, setTotalMessage] = useState(0);
  const [fileShow, setFileShow] = useState(false);
  const [scrollActive, setScrollActive] = useState(true);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 268);
  const [defaultScroll, setDefaultScroll] = useState(true);
  const [showScroll, setShowScroll] = useState(false);
  const [typingUpdate, setTypingUpdate] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const { messageData } = useSelector((state: RootState) => state.discussion);

  const lpoadMessage = useSelector((state: RootState) => state.messageLoad);

  const { user } = useSelector((state: RootState) => state.auth);

  const bottomRef = useRef<any>(null);

  const dispatch = useDispatch()<any>;

  const handlePopoverClose = () => {
    setOpenSide(!openSide);
  };

  // get message function
  const getMessageData = useCallback(
    async (limits: any, load: any) => {
      if (teamdata) {
        const team_id = teamdata;
        const limit = limits;

        if (load) {
          setDefaultScroll(false);
        }

        const result = await dispatch(
          DiscussionAction.getMessage({ team_id, limit })
        );
        if (result.status) {
          setMessageLimit(result.next);
          setTotalMessage(result.count);
        }
        if (load) {
          setDefaultScroll(true);
        }
      }
    },
    [dispatch, teamdata, scrollActive]
  );

  useEffect(() => {
    setInitialLoading(true);
    getMessageData("20", false).finally(() => setInitialLoading(false));
    setFileShow(false);
    setOpenButton(true);
    setOpenInput(false);
  }, [getMessageData]);

  useEffect(() => {
    socket.off("message_sent").on("message_sent", async (data) => {
      if (teamdata === data.companyid) {
        const team_id = teamdata;
        const limit = messageLimit;
        await dispatch(DiscussionAction.getMessage({ team_id, limit }));
        scrollHandle();
      }
    });
  }, [dispatch, teamdata]);

  const scrollHandle = () => {
    if (bottomRef.current) {
      bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight - 268);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  useEffect(() => {
    socket.off("typing_update").on("typing_update", async (data) => {
      if (teamdata === data.teamId) {
        data.count > 0 ? setTypingUpdate(true) : setTypingUpdate(false);
      } else {
        setTypingUpdate(false);
      }
    });
  }, [teamdata]);
  const handleSearchModal = async () => {
    setSearchModal(!searchModal);
  };

  const handleShowFiles = async () => {
    setFileShow(true);
    setOpenButton(false);
    setOpenInput(false);
  };

  const handleShowMessage = async () => {
    setFileShow(false);
    setOpenButton(true);
    setOpenInput(false);
  };

  const scrollTopHandle = async (event: any) => {
    let limit = parseInt(messageLimit) - 10;
    let scrollSize =
      event.target.scrollTop < 0 ? event.target.scrollTop + 200 : 0;
    // console.log(scrollSize , event.target.scrollTop / 100)
    if (
      event.target.scrollTop < scrollSize &&
      totalMessage > limit &&
      defaultScroll
    ) {
      event.target.scrollTo({ top: scrollSize, left: 0, behavior: "smooth" });
      getMessageData(messageLimit, true);
    }
  };

  const handleMouseEvent = async () => {
    setShowScroll(true);
  };

  return (
    <>
      <Grid container>
        <Grid item style={{ width: "100%" }}>
          <Typography
            variant="body2"
            style={{ display: "flex", marginBottom: "1%" }}
          >
            {!fileShow && (
              <Button
                className={clsx(
                  user && user.role === "User"
                    ? classes.buttonUser
                    : classes.button
                )}
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingBottom: 2,
                  paddingTop: 2,
                  borderRadius: 20,
                }}
                onClick={() => handleShowFiles()}
              >
                <>Documents</>
              </Button>
            )}
            {fileShow && (
              <Button
                className={clsx(
                  user && user.role === "User"
                    ? classes.buttonUser
                    : classes.button
                )}
                style={{
                  paddingRight: 10,
                  paddingLeft: 10,
                  paddingBottom: 2,
                  paddingTop: 2,
                  borderRadius: 20,
                }}
                onClick={() => handleShowMessage()}
              >
                <>Messages</>
              </Button>
            )}
            <FormControl
              className="textField margin"
              variant="outlined"
              size="small"
              style={{ marginLeft: "50%" }}
            >
              <InputLabel htmlFor="outlined-adornment-keyword">
                Search
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-keyword"
                type={"text"}
                value={""}
                label={"Search"}
                required={true}
                onClick={(text) => handleSearchModal()}
                aria-describedby="outlined-weight-helper-text"
                placeholder="Search"
                inputProps={{ style: { textAlign: "left" } }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Typography>
          <Divider />
        </Grid>

        {!fileShow && messageData && (
          <div
            style={{
              maxHeight: screenHeight + "px",
              display: "flex",
              overflowY: "auto",
              flexDirection: "column-reverse",
              width: "100%",
            }}
            onScroll={scrollTopHandle}
            onMouseEnter={() => handleMouseEvent()}
            onMouseLeave={() => setShowScroll(false)}
          >
            <div>
              {!defaultScroll && (
                <Card
                  className={clsx(classes.chatCard)}
                  style={{ textAlign: "center", marginTop: "10px" }}
                >
                  <Button
                    className={clsx(classes.buttonShowMsg)}
                    style={{ fontSize: "14px" }}
                  >
                    Updating conversation
                  </Button>
                </Card>
              )}

              {messageData &&
              messageData?.data?.length > 0 &&
              !initialLoading ? (
                messageData.data
                  .slice(0)
                  .reverse()
                  .map((chats: any, i: any) => {
                    let margin =
                      messageData.data.length - 1 === i ? "5%" : "2%";
                    let time = (
                      <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                        {chats.created_at}
                      </Moment>
                    );
                    let meetTime = (
                      <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                        {chats.meeting.date_time}
                      </Moment>
                    );
                    let id = chats.id;
                    let ext = chats.files ? chats.files.split(".").pop() : "";
                    let file_name = validImageTypes.includes(ext)
                      ? BASE_URL + "" + chats.files
                      : fileImage;
                    let file = chats.file_name;
                    let name = chats.first_name
                      ? chats.first_name + " " + chats.last_name
                      : chats.username;
                    let avator = chats.profile
                      ? BASE_URL + "" + chats.profile
                      : chats.username.substring(0, 2);
                    let bgColorFirst = chats.profile
                      ? COLORS.basfPaleRed
                      : COLORS.basfRed;
                    return (
                      <Card
                        className={clsx(classes.chatCard)}
                        style={{
                          width: "95%",
                          marginBottom: "1%",
                          marginLeft: "30px",
                          marginTop: "10px",
                          boxShadow: `0px 0px 0px 2px ${COLORS.basfBoxGrey}`,
                        }}
                        key={i}
                      >
                        <CardHeader
                          avatar={
                            <IconButton
                              size="small"
                              aria-controls={
                                "account-menu" ? "account-menu" : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={"true" ? "true" : undefined}
                            >
                              <Avatar
                                style={{
                                  width: 32,
                                  height: 32,
                                  textAlign: "right",
                                  float: "right",
                                  backgroundColor: bgColorFirst,
                                }}
                                alt={
                                  avator[0].toUpperCase() +
                                  avator.slice(1).toUpperCase()
                                }
                                src={avator}
                              ></Avatar>
                            </IconButton>
                          }
                          title={name}
                          subheader={time}
                          titleTypographyProps={{
                            style: { fontSize: 14, fontWeight: "bold" },
                          }}
                          subheaderTypographyProps={{ style: { fontSize: 12 } }}
                          action={
                            <ChatSidePop
                              messageId={id}
                              isMain={"true"}
                              teamId={teamdata}
                              isOwner={+chats?.sender_id === +user?.id}
                            />
                          }
                        />

                        <CardContent
                          style={{ marginLeft: 56, marginTop: "-20px" }}
                        >
                          <Typography paragraph>
                            {chats.message_type === "content" ? (
                              <>
                                {chats.files ? (
                                  <a
                                    href={BASE_URL + "" + chats.files}
                                    target={"_blank"}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <img
                                      src={file_name}
                                      alt={file}
                                      style={{
                                        width: "10%",
                                        float: "left",
                                      }}
                                    />
                                    <br />
                                    <br />
                                    {file}
                                  </a>
                                ) : (
                                  ""
                                )}
                                {chats.content}
                              </>
                            ) : (
                              ""
                            )}
                            {chats.message_type === "meeting" ? (
                              <>
                                <p>
                                  # {name} invites you to join the call{" "}
                                  {chats.content} at {meetTime}{" "}
                                </p>
                                <a
                                  href={chats.join_link}
                                  target={"_blank"}
                                  style={{ textDecoration: "none" }}
                                >
                                  {" "}
                                  <Button className={clsx(classes.joinButton)}>
                                    {chats.button_name}
                                  </Button>
                                </a>
                              </>
                            ) : (
                              ""
                            )}
                          </Typography>
                        </CardContent>
                        {chats.discussion.map((discossion: any, j: any) => {
                          let nameSub = discossion.first_name
                            ? discossion.first_name + " " + discossion.last_name
                            : discossion.username;
                          let chatid = discossion.id;
                          let ext2 = discossion.files
                            ? discossion.files.split(".").pop()
                            : "";
                          let meetTime2 = (
                            <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                              {discossion.meeting.date_time}
                            </Moment>
                          );
                          let file_name2 = validImageTypes.includes(ext2)
                            ? BASE_URL + "" + discossion.files
                            : fileImage;
                          let file2 = discossion.file_name;
                          let avator2 = discossion.profile
                            ? BASE_URL + "" + discossion.profile
                            : discossion.username.substring(0, 2);
                          let bgColor = discossion.profile
                            ? COLORS.basfPaleLightBlue
                            : COLORS.basfDarkBlue;
                          let timeSub = (
                            <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                              {discossion.created_at}
                            </Moment>
                          );
                          let marginTop = j != 0 ? "-55px" : "-30px";
                          return (
                            <>
                              <CardContent
                                style={{
                                  marginLeft: 60,
                                  marginTop: marginTop,
                                  backgroundColor: "#f2f1f0",
                                  marginRight: 20,
                                }}
                                key={j}
                              >
                                <CardHeader
                                  avatar={
                                    <IconButton
                                      size="small"
                                      aria-controls={
                                        "account-menu"
                                          ? "account-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        "true" ? "true" : undefined
                                      }
                                    >
                                      <Avatar
                                        style={{
                                          width: 32,
                                          height: 32,
                                          textAlign: "right",
                                          float: "right",
                                          backgroundColor: bgColor,
                                        }}
                                        alt={
                                          avator2[0].toUpperCase() +
                                          avator2.slice(1).toUpperCase()
                                        }
                                        src={avator2}
                                      ></Avatar>
                                    </IconButton>
                                  }
                                  title={nameSub}
                                  subheader={timeSub}
                                  titleTypographyProps={{
                                    style: { fontSize: 15, fontWeight: "bold" },
                                  }}
                                  subheaderTypographyProps={{
                                    style: { fontSize: 12 },
                                  }}
                                  action={
                                    <ChatSidePop
                                      messageId={chatid}
                                      isMain={"false"}
                                      teamId={teamdata}
                                    />
                                  }
                                />
                                <CardContent
                                  style={{ marginLeft: 60, marginTop: "-15px" }}
                                >
                                  <Typography paragraph>
                                    {discossion.message_type === "content" ? (
                                      <>
                                        {discossion.files ? (
                                          <a
                                            href={
                                              BASE_URL + "" + discossion.files
                                            }
                                            target={"_blank"}
                                          >
                                            <img
                                              src={file_name2}
                                              alt={file2}
                                              style={{
                                                width: "10%",
                                                float: "left",
                                              }}
                                            />
                                            <br />
                                            <br />
                                            {file2}
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                        {discossion.content}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {discossion.message_type === "meeting" ? (
                                      <>
                                        <p>
                                          # {nameSub} invites you to join the
                                          call {discossion.content} at{" "}
                                          {meetTime2}{" "}
                                        </p>
                                        <a
                                          href={discossion.join_link}
                                          target={"_blank"}
                                          style={{ textDecoration: "none" }}
                                        >
                                          {" "}
                                          <Button
                                            className={clsx(classes.joinButton)}
                                          >
                                            {discossion.button_name}
                                          </Button>
                                        </a>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                </CardContent>
                              </CardContent>
                            </>
                          );
                        })}
                        {chats.totalDiscussion > 1 && (
                          <Link
                            to={
                              "/home/chat/" +
                              encode(teamdata) +
                              "/" +
                              encode(id)
                            }
                            style={{
                              marginLeft: "6%",
                              textDecoration: "none",
                              color: COLORS.basfDarkBlue,
                            }}
                          >
                            ({chats.totalDiscussion - 1}) More
                          </Link>
                        )}
                        {/*{showInput &&*/}
                        <Divider
                          style={{ width: "100%", marginTop: "0px" }}
                        ></Divider>
                        <>
                          <CardActions>
                            <ReplySection
                              key={id}
                              messageId={id}
                              teamId={teamdata}
                              scrollHandle={scrollHandle}
                            />
                          </CardActions>
                        </>
                        {/*}*/}
                      </Card>
                    );
                  })
              ) : (
                <div />
              )}
            </div>
          </div>
        )}
        {typingUpdate && (
          <Card
            className={clsx(classes.chatCard)}
            style={{
              width: "93%",
              marginLeft: "30px",
              marginTop: "10px",
              boxShadow: `0px 0px 0px 2px ${COLORS.basfBoxGrey}`,
              alignItems: "start",
            }}
          >
            <img src={typingImg} width={50} style={{ marginLeft: "60px" }} />
          </Card>
        )}
      </Grid>
      {fileShow && <MessageFiles teamId={teamdata} showInput={showInput} />}
      {searchModal && (
        <MessageSearchBox
          teamId={teamdata}
          searchModal={searchModal}
          handleSearchModal={handleSearchModal}
        />
      )}
      {messageData && messageData.data.length === 0 && !fileShow && (
        <div style={{ textAlign: "center", maxWidth: "100%" }}>
          {/*<ChatBubble style={{color:COLORS.basfDarkBlue , fontSize:"24px" , marginTop: "13%" }}/>*/}
          <img src={discussionPng} style={{ marginTop: "5%" }} width={"50%"} />
          <h1 style={{ color: COLORS.basfLightBlue }}>Start Discussion...</h1>
          <p style={{ color: "#666" }}>
            This is first message of the discussion.
          </p>
        </div>
      )}
    </>
  );
};

export default ChatMessage;

import React, { useCallback, useEffect, useState } from "react";
import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import Grid from "@material-ui/core/Grid";
import { Add, CheckBoxOutlined } from "@material-ui/icons";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from "@material-ui/core";
import { COLORS } from "../../utils/Colors";
import TaskAction from "../../stores/actions/taskAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { Link } from "react-router-dom";
import { encode } from "js-base64";
import Moment from "react-moment";
import TaskProjectPop from "../../components/task/TaskProjectPop";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  button: {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.basfWhite,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },

  showButton: {
    backgroundColor: COLORS.basfLightBlue,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    marginBottom: 4,
    float: "right",
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
    },
  },
  cardHover: {
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
    },
  },

  hoverCard1: {
    backgroundColor: COLORS.basfWhite,
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
    },
  },

  hoverCard2: {
    backgroundColor: COLORS.basfPaleLightGreen,
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
    },
  },
}));

const Tasks = () => {
  const [openCreateProject, setCreateProject] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [projectStartDate, setProjectStartDate] = useState(() =>
    moment().format("YYYY-MM-DD")
  );
  const [projectEndDate, setProjectEndDate] = useState(() =>
    moment().format("YYYY-MM-DD")
  );
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showCardHover, setShowCardHover] = useState(false);
  const dispatch = useDispatch()<any>;
  const classes = useStyles();
  const { projects } = useSelector((state: RootState) => state.tasks);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleProjectCreate = () => {
    setCreateProject(!openCreateProject);
  };

  // Process using for get task projects
  const getTaskProjects = useCallback(async () => {
    await dispatch(TaskAction.getProject({}));
  }, [dispatch]);

  // process for save task projects into the database
  const saveProject = async (e: any) => {
    e.preventDefault();

    setButtonLoader(true);
    const name = projectName;
    const description = projectDescription;
    const startDate = projectStartDate;
    const endDate = projectEndDate;
    const result = await dispatch(
      TaskAction.addProject({ name, description, startDate, endDate })
    );
    if (result && result.status) {
      setProjectName("");
      setProjectDescription("");
      getTaskProjects();
      handleProjectCreate();
    }
    setButtonLoader(false);
  };

  useEffect(() => {
    getTaskProjects();
  }, [getTaskProjects]);

  return (
    <>
      <HomeDefault
        illustrationBackground={illustration}
        image={illustration}
        heading="Tasks"
        icon={<CheckBoxOutlined />}
      >
        <Grid
          container
          spacing={2}
          style={{
            width: "70%",
            maxHeight: "700px",
            overflowX: "hidden",
            position: "absolute",
          }}
        >
          {user && user.role === "Vendor" && (
            <Grid item xs={2} style={{ alignItems: "center" }}>
              <Button
                className={clsx(classes.button)}
                onClick={handleProjectCreate}
              >
                <Add></Add> New Project
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <p>All Projects</p>
          </Grid>
          {projects &&
            projects.data.map((project: any, index: any) => {
              const idP = encode(project.id);
              let color = index % 2 == 0 ? true : false;
              return (
                <Grid item xs={3} key={index}>
                  <Card
                    className={clsx(
                      color ? classes.hoverCard1 : classes.hoverCard2
                    )}
                  >
                    <CardHeader
                      title={project.project_name}
                      action={
                        user && user.role === "Vendor" ? (
                          <TaskProjectPop projectDetails={project} />
                        ) : (
                          ""
                        )
                      }
                      titleTypographyProps={{
                        style: { fontSize: 15, fontWeight: "bold" },
                      }}
                    />
                    <Divider />
                    <Link
                      to={"/home/tasks/list/" + idP}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <CardContent
                        style={{ cursor: "pointer" }}
                        className={clsx(classes.cardHover)}
                        onMouseEnter={() => setShowCardHover(!showCardHover)}
                        onMouseLeave={() => setShowCardHover(!showCardHover)}
                      >
                        <h4>{project.tasks} Tasks</h4>
                        <h5>
                          Start Date:{" "}
                          <Moment format={"MMM Do YYYY"}>
                            {project.start_date}
                          </Moment>
                        </h5>
                        <h5>
                          End Date:{" "}
                          <Moment format={"MMM Do YYYY"}>
                            {project.end_date}
                          </Moment>{" "}
                        </h5>
                        {/*<Button className={clsx(classes.showButton)}>Tasks</Button>*/}
                      </CardContent>
                    </Link>
                  </Card>
                </Grid>
              );
            })}
        </Grid>
      </HomeDefault>

      <Dialog
        open={openCreateProject}
        onClose={handleProjectCreate}
        fullWidth={true}
      >
        <form action={"#"} onSubmit={saveProject}>
          <DialogTitle>New Project</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              value={projectName}
              type="text"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectName(text.target.value)}
            />
            <br />
            <br />
            <TextField
              margin="dense"
              id="startdate"
              label="Start Date"
              value={projectStartDate}
              type="date"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectStartDate(text.target.value)}
            />
            <br />
            <br />
            <TextField
              margin="dense"
              id="enddate"
              label="End Date"
              value={projectEndDate}
              type="date"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectEndDate(text.target.value)}
            />
            <br />
            <TextField
              multiline={true}
              margin="dense"
              id="name"
              rows={4}
              label="Description"
              type="textarea"
              required={true}
              fullWidth
              variant="outlined"
              value={projectDescription}
              onChange={(text) => setProjectDescription(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleProjectCreate}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default Tasks;

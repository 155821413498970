import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import {
  CameraAlt,
  Lock,
  SupervisedUserCircle,
  VerifiedUserRounded,
} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import HomeDefault from "../../layouts/home/HomeDefault";
import illustration from "../../assets/image/auth.png";
import { BASE_URL } from "../../utils/Constants";
import ProfileData from "../../components/profile/ProfileData";
import PasswordUpdate from "../../components/profile/PasswordUpdate";
import AuthAction from "../../stores/actions/authAction";
import { RootState } from "../../stores";

const Profile: FC = () => {
  const [profileOpen, setProfileOpen] = useState<boolean>(true);
  const [passwordOpen, setPasswordOpen] = useState<boolean>(false);
  const [profileImage, setProfileImage] = useState<string>("");

  const dispatch = useDispatch()<any>;

  const handleOpenProfile = () => {
    setProfileOpen(!profileOpen);
    setPasswordOpen(!passwordOpen);
  };

  const input = useRef<HTMLInputElement>(null);

  const onChangeImage = async (event: any) => {
    event.preventDefault();
    const image = event.target.files[0];
    const result = await dispatch(AuthAction.changeProfileImage({ image }));
    if (result.status) {
      setProfileImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const { userData } = useSelector((state: RootState) => state.auth);

  const getProfileData = useCallback(async () => {
    await dispatch(AuthAction.getProfileData({}));
  }, [dispatch]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Profile"
      icon={<SupervisedUserCircle />}
    >
      <Grid
        container
        spacing={2}
        style={{
          width: "70%",
          maxHeight: "700px",
          overflowX: "scroll",
          position: "absolute",
        }}
      >
        <input
          type="file"
          hidden
          name={"image"}
          accept={"image/*"}
          ref={input}
          onChange={onChangeImage}
        />
        <Grid item xs={3} style={{ marginTop: "7%" }}>
          <>
            {/*<div style={{marginBottom: "5%"}}>*/}
            {userData && userData.data && (
              <img
                src={
                  profileImage
                    ? profileImage
                    : userData
                    ? BASE_URL + "" + userData.data.profile
                    : ""
                }
                alt=""
                className={"imgSize dropdown-toggle"}
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ width: 60 }}
              />
            )}

            <CameraAlt
              style={{ alignItems: "end", cursor: "pointer" }}
              onClick={() => input.current && input.current.click()}
            />
            {/*</div>*/}
          </>
          <MenuList>
            <Divider />
            <MenuItem selected={profileOpen} onClick={handleOpenProfile}>
              <ListItemIcon>
                <VerifiedUserRounded fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile Details</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem selected={passwordOpen} onClick={handleOpenProfile}>
              <ListItemIcon>
                <Lock fontSize="small" />
              </ListItemIcon>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
          </MenuList>
        </Grid>
        <Grid
          item
          xs={8}
          style={{ marginTop: "8%", width: "50%", marginLeft: "5%" }}
        >
          {profileOpen && userData && <ProfileData userData={userData} />}
          {passwordOpen && <PasswordUpdate />}
        </Grid>
      </Grid>
    </HomeDefault>
  );
};

export default Profile;

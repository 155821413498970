import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import { useDispatch } from "react-redux";
import DocumentAction from "../../stores/actions/documentAction";

interface Props {

    openDialogLink: boolean;
    handleDialogLink: any;
    folderId: any,
    setSavedDocument: any,
    saveDocument: any
}

const LinkAddPopover: React.FC<Props> = ({openDialogLink, handleDialogLink , folderId, setSavedDocument , saveDocument}) => {

    const [linkName, setLinkName] = useState('Untitled');
    const [linkIs, setLink] = useState('');
    const dispatch = useDispatch()<any>;
    const [buttonLoader, setButtonLoader] = useState(false);

    const saveFolder = async (e: any) => {
        e.preventDefault();
        setButtonLoader(true);
        const name = linkName;
        const folder_id = folderId;
        const link = linkIs;
        const result = await dispatch(DocumentAction.addLinks({name , link , folder_id}))
        if(result && result.status) {
            setSavedDocument(!saveDocument)
            setLinkName('');
            setLink('')
            handleDialogLink();
        }
        setButtonLoader(false);
    }


    return (
        <>
            <Dialog open={openDialogLink} onClose={handleDialogLink}>
                <form action={'#'} onSubmit={saveFolder}>
                <DialogTitle>New Link</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Folder Name"
                        value={linkName}
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={(text) => setLinkName(text.target.value)}
                    />
                    <br/>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="link"
                        label="Link"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={linkIs}
                        onChange={(text) => setLink(text.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogLink}>Cancel</Button>
                    <Button type={"submit"} disabled={buttonLoader}>Add</Button>
                </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default LinkAddPopover;

import React, { useCallback, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { ArrowBack, CheckBoxOutlined } from "@material-ui/icons";
import { Button, Card, CardHeader } from "@material-ui/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { decode } from "js-base64";
import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import { COLORS } from "../../utils/Colors";
import TaskAction from "../../stores/actions/taskAction";
import { RootState } from "../../stores";
import TaskGrid from "../../components/task/TaskGrid";
import TaskGantChart from "../../components/task/TaskGantChart";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.basfWhite,
    padding: 5,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },

  buttonUser: {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.basfWhite,
    padding: 5,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      color: COLORS.basfDarkBlue,
    },
  },
}));

interface Props {}

const TaskList: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const [loading, setLoading] = useState(true);
  const [ganttChartShow, setGanttChartShow] = useState(false);
  const [taskListShow, setTaskListShow] = useState(true);
  const [buttonTitle, setButtonTitle] = useState("Gantt Chart");
  const [gantFull, setGantFull] = useState(false);
  const dispatch = useDispatch()<any>;
  const { projectDetails } = useSelector((state: RootState) => state.tasks);
  const { user } = useSelector((state: RootState) => state.auth);
  const classes = useStyles();
  //Get task projects details
  const getTaskProjects = useCallback(async () => {
    setLoading(true);
    const projectid = decode(projectId!);
    await dispatch(TaskAction.getProjectDetails({ projectid }));

    setLoading(false);
  }, [dispatch]);

  //Call callback function
  useEffect(() => {
    getTaskProjects();
  }, [getTaskProjects]);

  // Handle views of task
  const handleView = () => {
    setGanttChartShow(!ganttChartShow);
    setTaskListShow(!taskListShow);
    setButtonTitle(taskListShow ? "Task List" : "Gantt Chart");
  };

  const openPieChart = () => {
    navigate(`/home/tasks/pie-chart/${projectId}`);
  };

  return (
    <>
      {!gantFull && (
        <HomeDefault
          illustrationBackground={illustration}
          image={illustration}
          heading="Tasks"
          icon={<CheckBoxOutlined />}
        >
          <Grid
            container
            spacing={2}
            style={{
              width: "70%",
              maxHeight: "650px",
              overflowX: "hidden",
              position: "absolute",
            }}
          >
            <Grid item xs={2}>
              <Link
                to={"/home/tasks"}
                style={{ textDecoration: "none", color: "black" }}
              >
                <Button
                  className={clsx(
                    user && user.role === "User"
                      ? classes.buttonUser
                      : classes.button
                  )}
                >
                  <ArrowBack /> All Projects
                </Button>
              </Link>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={clsx(
                  user && user.role === "User"
                    ? classes.buttonUser
                    : classes.button
                )}
                onClick={handleView}
              >
                {" "}
                {buttonTitle}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button className={classes.button} onClick={openPieChart}>
                Pie Chart
              </Button>
            </Grid>
            {loading ? null : (
              <Grid item xs={12}>
                {projectDetails &&
                  projectDetails.data.map((project: any, index: any) => {
                    const idP = project.id;
                    let time = (
                      <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                        {project.created_at}
                      </Moment>
                    );
                    return (
                      <>
                        <Card key={index} style={{ width: "100%" }}>
                          <CardHeader
                            title={project.project_name}
                            subheader={time}
                          />
                        </Card>
                      </>
                    );
                  })}

                {taskListShow && projectDetails && (
                  <TaskGrid
                    projectId={decode(projectId!)}
                    projectDetails={projectDetails}
                  />
                )}
                {ganttChartShow && (
                  <TaskGantChart
                    projectId={decode(projectId!)}
                    setGantFull={setGantFull}
                    gantFull={gantFull}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </HomeDefault>
      )}
      {gantFull && (
        <TaskGantChart
          projectId={decode(projectId!)}
          setGantFull={setGantFull}
          gantFull={gantFull}
        />
      )}
    </>
  );
};

export default TaskList;

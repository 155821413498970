import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle,
  Divider, FormControl,
  Grid,
  IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField
} from "@material-ui/core";
import {
  AttachFile,
  Link,
  BlurLinear,
  Description,
  FolderOpen,
  MoreVert,
  FolderOutlined,
  Folder, PictureAsPdf, Image, Receipt, TextFieldsSharp, Note, Notes, Search
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import DocumentAction from "../../stores/actions/documentAction";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { COLORS } from "../../utils/Colors";

import { BASE_URL } from "../../utils/Constants";
import FileFolderSidePopCL from "./FileFolderSidePopCL";

const useStyles = makeStyles((theme) => ({

  folderFile: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
    },
  },

  button : {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.white,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,

    },
  },

  buttonUser : {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.white,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      color: COLORS.basfDarkBlue,

    },
  }


}))

interface Props {
  setFolderId: any,
  savedDocument: boolean,
  folderId: any,
  setSaveDocument: any
}


const FileFolderCL: React.FC<Props> = ({setFolderId , savedDocument, folderId , setSaveDocument}) => {

  const dispatch = useDispatch()<any>;
  const classes = useStyles();
  const [folders, setFolders] = useState([{id: '' , name: ''}]);
  const [onclick , setOnClick] = useState(true);
  const [documentId, setDocumentId] = useState('');
  const [openFolderDelete , setOpenFolderDelete] = useState(false);
  const [openFolderEdit , setOpenFolderEdit] = useState(false);
  const [folderNameEdit, setFolderNameEdit] = useState('');
  const [searchCustomerFiles, setSearchCustomerFiles] = useState('');
  const [sortName , setSortName] = useState('');

  const { folderListCustomer  } = useSelector(
    (state: RootState) => state.documents
  );

  const { user } = useSelector(
    (state: RootState) => state.auth
  );

  // fucntion use for get file and folders
  const getFileFolders = useCallback( async (event: any | null) => {
    const result = dispatch(DocumentAction.getCustomerDocuments({folder_id: event , teamId: null , keyword: null , sort: null}));
    setSaveDocument(!savedDocument)
  }, [dispatch , setSaveDocument])


  useEffect(() => {
    getFileFolders(folderId)
  }, [getFileFolders , savedDocument])


  const clickFolder = async (id: any , name: any) => {
      let newData = { id: id, name: name }
      setFolders([...folders, newData]);
      getFileFolders(id)
      setFolderId(id);
  }

  const backClick = async (id: any) => {
    if(folders.length > 0) {
      let newArray = [];
      for (const foldIs of folders) {
        if(foldIs && foldIs.id === id){
          newArray.push({id: foldIs.id, name: foldIs.name});
          break;
        }
        else{
          newArray.push({id: foldIs.id, name: foldIs.name})
          setFolders(newArray);
        }
      }
    }
    if(id === '') {
      setFolders([{ id: '', name: '' }]);
    }
    setFolderId(id);
    getFileFolders(id)
  }

  const fileClick = async (url: any) => {
      window.open(url , '_blank');
  }

  const deleteFolder = async (e: any) => {
    const result = await dispatch(DocumentAction.removeDocument({document_id: e}));
    getFileFolders(folderId)
    setOpenFolderDelete(!openFolderDelete)
  }

  const updateFolder = async (id: any , name: any) => {
    const result = await dispatch(DocumentAction.updateFolders({name: name , id: id, folder_id: folderId}));
    if(result.status) {
      getFileFolders(folderId)
      setOpenFolderEdit(!openFolderEdit)
    }
  }
  const searchCustomerFileFolder = async () => {
    if(searchCustomerFiles.length > 2) {
      const result = dispatch(DocumentAction.getCustomerDocuments({
        folder_id: folderId,
        teamId: null,
        keyword: searchCustomerFiles,
        sort: sortName ? sortName : null
      }));
    }
    else{
      getFileFolders(folderId)
    }
  }

  const handleSorting = async (e: any) => {
    setSortName(e.target.value as string)
    if(e.target.value.length > 0) {
      const result = dispatch(DocumentAction.getCustomerDocuments({
        folder_id: folderId,
        teamId: null,
        keyword: searchCustomerFiles ? searchCustomerFiles : null,
        sort: e.target.value
      }));
    }
    else{
      getFileFolders(folderId)
    }
  }


  return (
    <>
    <Grid container spacing={2} style={{marginBottom: "1%"}}>
      <Grid item xs={12}>
        <Button className={clsx(user && user.role === 'User' ? classes.buttonUser :  classes.button)} onClick={() => backClick('')}>Main</Button>
        {folders && folders.map((fold: any, ind: any) => {
          if(fold.id) {
            return (
              <Button className={clsx(user && user.role === 'User' ? classes.buttonUser :  classes.button)} onClick={() => backClick(fold.id)} style={{marginLeft: "1%"}} key={ind}>{fold.name}</Button>
            )
          }
        })}
        <FormControl
          className="textField margin"
          variant="outlined"
          size="small"
          style={{marginLeft:"50%"}}
        >
          <InputLabel htmlFor="outlined-adornment-keyword">
            Search
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-keyword"
            type={"text"}
            value={searchCustomerFiles}
            label={"Search"}
            required={true}
            onChange={(text) => setSearchCustomerFiles(text.target.value)}
            aria-describedby="outlined-weight-helper-text"
            placeholder="Search"
            inputProps={{ style: { textAlign: "left" } }}
            endAdornment={
              <InputAdornment position="end" >
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => searchCustomerFileFolder()}
                >
                  <Search/>
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <FormControl
          className="textField margin"
          variant="outlined"
          size="small"
        >
        <InputLabel id="demo-select-small">Sort</InputLabel>
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={sortName}
          label="Color"
          onChange={handleSorting}
        >
          <MenuItem selected={true} >Sort</MenuItem>
          <MenuItem value={'Name'}>Name</MenuItem>
          <MenuItem value={'Old'}>Oldest on top</MenuItem>
          <MenuItem value={'New'}>Newest on top</MenuItem>
        </Select>
        </FormControl>
      </Grid>
    </Grid>

    <Grid container spacing={2} style={{ width: "70%",
      maxHeight: "600px",
      overflowX: "hidden",
      position:"absolute",
    }}>
      <Grid item xs={12}>
        <h4>Folder</h4>
        {folderListCustomer && folderListCustomer.data.folder.map((folder: any, index: any) => {
          let time = 'Owner: '+folder.username;
          let id = folder.id;
          let name = folder.name;
          return (
            <>
            <Card key={index} style={{width: "100%", marginBottom: 4}}  >
              <CardHeader
                className={clsx(classes.folderFile)}
                onMouseEnter={() => setOnClick(true)}
                onClick={() =>  onclick ? clickFolder(id , name) : ''}
                avatar={
                  <Folder style={{color: COLORS.basfOrange}}/>
                }
                title={name}
                subheader={time}
                action={
                    <FileFolderSidePopCL
                      type={'Folder'}
                      dataDetails={folder}
                      setOnClick={setOnClick}
                      setOpenFolderDelete={setOpenFolderDelete}
                      openFolderDelete={openFolderDelete}
                      setDocumentId={setDocumentId}
                      setOpenFolderEdit={setOpenFolderEdit}
                      openFolderEdit={openFolderEdit}
                      setFolderNameEdit={setFolderNameEdit}
                    />
                }
              />
            </Card>
            <Divider/>
            </>
          )
        })}

      </Grid>

      <Grid item xs={12}>
        <h4>File</h4>
        {folderListCustomer && folderListCustomer.data.files.map((folder: any, index: any) => {
          // let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.updated_at}</Moment>
          let time = 'Owner: '+folder.username;
          let url = folder.type === 'FILE' ? BASE_URL+''+folder.name : folder.fileName;
          let nameShow = folder.type === 'FILE' ? folder.fileName : folder.name;
          let icon = folder.type === 'FILE' &&
                      folder.ext === 'sql' ? <Receipt style={{color: COLORS.basfDarkBlue}}/> :
                           folder.ext === 'pdf' ? <PictureAsPdf style={{color: COLORS.basfRed}}/>  :
                            folder.ext === 'jpg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                              folder.ext === 'png' ?  <Image style={{color: COLORS.basfOrange}}/> :
                                folder.ext === 'jpeg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                                  folder.ext === 'svg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                                    folder.ext === 'link' ? <Link style={{color: COLORS.basfDarkBlue}}/> :
                                      folder.ext === 'txt' ? <Notes style={{color: COLORS.basfDarkBlue}}/> :
                                        folder.ext === 'docx' ? <Description style={{color: COLORS.basfDarkBlue}} /> :
                                          folder.ext === 'xlsx' ? <Description style={{color: COLORS.basfDarkGreen}}/> :
                                        <Description/>;
          let id = folder.id;
          return (
            <>
              {/*<a href={url} target={"_blank"} style={{textDecoration: "none"}} >*/}
                <Card key={index} style={{width: "100%", marginBottom: 4}} >
                  <CardHeader
                    className={clsx(classes.folderFile)}
                    onMouseEnter={() => setOnClick(true)}
                    onClick={() => onclick ?  fileClick(url) : ''}
                    avatar={
                      icon
                    }
                    title={nameShow}
                    subheader={time}
                    action={
                        <FileFolderSidePopCL
                          type={'File'}
                          dataDetails={folder}
                          setOnClick={setOnClick}
                          setOpenFolderDelete={setOpenFolderDelete}
                          openFolderDelete={openFolderDelete}
                          setDocumentId={setDocumentId}
                          setOpenFolderEdit={setOpenFolderEdit}
                          openFolderEdit={openFolderEdit}
                          setFolderNameEdit={setFolderNameEdit}
                        />
                    }
                  />
                </Card>
              {/*</a>*/}
              <Divider/>
            </>
          )
        })}

      </Grid>

    </Grid>

      <Dialog
        open={openFolderDelete}
        keepMounted
        onClose={() => setOpenFolderDelete(!openFolderDelete)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Document?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the document ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderDelete(!openFolderDelete)}>Cancel</Button>
          <Button onClick={() => deleteFolder(documentId)} className={clsx(classes.button)}>Confirm</Button>
        </DialogActions>
      </Dialog>


      <Dialog
        open={openFolderEdit}
        keepMounted
        onClose={() => setOpenFolderEdit(!openFolderEdit)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Rename ?"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Folder Name"
            type="text"
            fullWidth
            variant="standard"
            value={folderNameEdit}
            required={true}
            onChange={(text) => setFolderNameEdit(text.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderEdit(!openFolderEdit)}>Cancel</Button>
          <Button onClick={() => updateFolder(documentId , folderNameEdit)} className={clsx(classes.button)}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  )

}

export default FileFolderCL;

import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip
} from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Delete, Edit } from "@material-ui/icons";
import clsx from "clsx";
import TaskAction from "../../stores/actions/taskAction";
import { useDispatch } from "react-redux";


interface Props {
  subTaskDetails: any,
  setEditTaskStatus: any,
  editTaskStatus: any
}



const SubTaskManage: React.FC<Props> = ({subTaskDetails,  setEditTaskStatus , editTaskStatus}) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const [openEditSubTask, setOpenEditSubTask] = useState(false);

  const [subTask , setSubTask] = useState("");
  const [taskId , setTaskId] = useState("");
  const [subTaskId , setSubTaskId] = useState("");
  const [subTaskStart , setSubTaskStart ] = useState("");
  const [subTaskEnd , setSubTaskEnd ] = useState("");

  const dispatch = useDispatch()<any>;

  // process for save task projects into the database
  const deleteSubTask = async (event: any , e: any) => {
    setButtonLoader(true)
    const result = await dispatch(TaskAction.deleteSubTask({subTaskId: event}))
    if(result && result.status) {
      setEditTaskStatus(!editTaskStatus)
      if(e.close){
        e.close()
      }
    }
    setButtonLoader(false)
  }

  // process for save sub task projects into the database
  const updateSubTask = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true)

    const result = await dispatch(TaskAction.updateSubTask({subTask: subTask, startDate: subTaskStart , endDate: subTaskEnd , taskId: taskId , subTaskId: subTaskId}))
    if(result && result.status) {
      setSubTask('')
      setSubTaskStart('')
      setSubTaskEnd('')
      setSubTaskId('')
      setEditTaskStatus(!editTaskStatus)
      handleEditSubTask('')
    }
    setButtonLoader(false)
  }

  /**
   *
   * @param e
   */
  const handleEditSubTask = async (e: any) => {
    setSubTask(subTaskDetails.sub_task);
    setSubTaskStart(subTaskDetails.start_date);
    setSubTaskEnd(subTaskDetails.end_date);
    setTaskId(subTaskDetails.task_id);
    setSubTaskId(subTaskDetails.id);
    setOpenEditSubTask(!openEditSubTask)
  }




  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={"Delete"}>
              <IconButton aria-label="settings" {...bindTrigger(popupState)}>
                <Delete style={{fontSize: "17px"}} />
              </IconButton>
            </Tooltip>
            <Tooltip title={"Edit"}>
              <IconButton onClick={() => handleEditSubTask('')}>
                <Edit style={{fontSize: "17px"}}/>
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)} style={{marginTop: 46, marginLeft: "-2%"}}>
              <MenuItem  style={{ fontSize: 13}} onClick={() => deleteSubTask(subTaskDetails.id , popupState) }>Yes</MenuItem>
              <MenuItem  style={{ fontSize: 13}}>No</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>

      <Dialog open={openEditSubTask} onClose={handleEditSubTask} maxWidth={"xs"} fullWidth={true}>
        <form action={"#"} onSubmit={updateSubTask}>
          <DialogTitle>Add Sub Tasks </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="subTasks"
              label="Sub Tasks"
              value={subTask}
              required={true}
              type="text"
              fullWidth
              variant="standard"
              onChange={(text) => setSubTask(text.target.value)}
            />
            <br/>
            <br/>
            <TextField
              InputProps={{inputProps: { min: subTaskStart+' 00:00', max: subTaskEnd+' 00:00'} }}
              margin="dense"
              id="startDate"
              label="Start Date"
              value={subTaskStart ? subTaskStart : setSubTaskStart(subTaskStart+ ' 00:00')}
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setSubTaskStart(text.target.value)}
            />
            <br/>
            <br/>
            <TextField
              InputProps={{inputProps: { min: subTaskStart, max: subTaskEnd+' 00:00'} }}
              margin="dense"
              id="taskDesc"
              label="End Date "
              value={subTaskEnd ? subTaskEnd : setSubTaskEnd(subTaskEnd+' 00:00')}
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setSubTaskEnd(text.target.value)}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditSubTask}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

}

export default SubTaskManage;

import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";
import { COLORS } from "../../utils/Colors";
import routes from "../../routes/Routes";
import Drawer from "@material-ui/core/Drawer";
import {
  Avatar,
  Badge,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import Footer from "./Footer";
import { Add } from "@material-ui/icons";
import AddTeamPopover from "../discussion/AddTeam";
import DiscussionAction from "../../stores/actions/discussionAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { encode } from "js-base64";
import TeamPopover from "./TeamPopover";
import DirectCreatPop from "../discussion/DirectCreate";
import SettingAction from "../../stores/actions/settingAction";
import logoImage from "../../assets/image/logo.png";
import { BASE_URL, socket } from "../../utils/Constants";

const StyledBadge = styled(Badge)<{ colors: string }>(({ theme, colors }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: `${colors}`,
    color: `${colors}`,
    boxShadow: `0 0 0 1px white`,
    "&::after": {
      position: "inherit",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },

  "& .MuiBadge-dot": {
    height: "10px",
    padding: "0",
    minWidth: "10px",
    borderRadius: "4px",
  },
}));

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  dividerColor: {
    backgroundColor: COLORS.basfLightGrey,
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    fontWeight: "bold",
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: COLORS.basfDarkBlue,
    borderColor: COLORS.basfDarkBlue,
  },
  drawerHeader: {
    display: "flex",
    marginLeft: "-28%",
    marginTop: "-4%",
    marginBottom: "4%",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: COLORS.basfDarkBlue,
    justifyContent: "center",
  },
  drawerPaperUser: {
    width: drawerWidth,
    backgroundColor: COLORS.basfOrange,
    borderColor: COLORS.basfOrange,
  },
  drawerHeaderUser: {
    display: "flex",
    marginLeft: "-28%",
    marginTop: "-4%",
    marginBottom: "4%",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: COLORS.basfOrange,
    justifyContent: "center",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  linkColor: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
}));

interface Props {
  open: boolean;
  handleDrawerOpen: any;
}

const Sidebar: React.FC<Props> = ({ open, handleDrawerOpen }) => {
  const location = useLocation();
  const pathUrl = location.pathname;
  const classes = useStyles();
  const [openAddPop, setOpenAddPro] = useState(false);
  const [openDirectMessage, setOpenDirectMessage] = useState(false);
  const dispatch = useDispatch()<any>;
  const { teamData, teamDirect } = useSelector(
    (state: RootState) => state.discussion
  );

  const { companyDetails } = useSelector((state: RootState) => state.setting);

  const { user } = useSelector((state: RootState) => state.auth);

  const handleAddTeam = () => {
    setOpenAddPro(!openAddPop);
  };

  const getCompanyMember = useCallback(async () => {
    const company_id: string | any = localStorage.getItem(
      "@userData:companyId"
    );
    await dispatch(DiscussionAction.getCompanyMember({ company_id }));
  }, [dispatch]);

  const handleDirectMessage = () => {
    setOpenDirectMessage(!openDirectMessage);
    getCompanyMember();
  };

  const getTeamData = useCallback(async () => {
    const company_id: string | any = localStorage.getItem(
      "@userData:companyId"
    );
    const get_type = false;
    const result = await dispatch(
      DiscussionAction.getTeamsAll({ type: "Team", company_id, get_type })
    );
    const result2 = await dispatch(
      DiscussionAction.getTeamsAllPersonal({
        type: "Personal",
        company_id,
        get_type,
      })
    );
    const result3 = await dispatch(
      SettingAction.getCompanyDetails({ company_id })
    );
  }, [dispatch]);

  useEffect(() => {
    getTeamData();
  }, [getTeamData]);

  useEffect(() => {
    socket.off("live_update").on("live_update", async () => {
      const company_id: string | any = localStorage.getItem(
        "@userData:companyId"
      );
      const get_type = false;
      await dispatch(
        DiscussionAction.getTeamsAllPersonal({
          type: "Personal",
          company_id,
          get_type,
        })
      );
    });
  }, [dispatch]);

  useEffect(() => {
    socket
      .off("sidebar_message_sent")
      .on("sidebar_message_sent", async (data) => {
        const company_id: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const get_type = false;

        await dispatch(
          DiscussionAction.getTeamsAllPersonal({
            type: "Personal",
            company_id,
            get_type,
          })
        );
      });
  }, [dispatch]);

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper:
          user && user.role === "User"
            ? classes.drawerPaperUser
            : classes.drawerPaper,
      }}
    >
      <div
        className={
          user && user.role === "User"
            ? classes.drawerHeaderUser
            : classes.drawerHeader
        }
      >
        <div className="dropdown" style={{ alignItems: "center" }}>
          {/*<img*/}
          {/*    src={logoImage}*/}
          {/*    alt=""*/}
          {/*    className={"dropdown-toggle"}*/}
          {/*    id="dropdownMenuButton"*/}
          {/*    data-toggle="dropdown"*/}
          {/*    aria-haspopup="true"*/}
          {/*    aria-expanded="false"*/}
          {/*    style={{width: 100}}*/}
          {/*/>*/}
          &emsp;
          <img
            src={logoImage}
            alt=""
            className={"dropdown-toggle"}
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ width: "52%" }}
          />
          {/*<Avatar style={{*/}
          {/*  width: 32,*/}
          {/*  height: 32,*/}
          {/*  textAlign: "right",*/}
          {/*  marginLeft:"30%",*/}
          {/*  backgroundColor: COLORS.basfRed*/}
          {/*}}>{(companyDetails && companyDetails.data ?  companyDetails.data.company_name.substring(0, 1) : '')}</Avatar>*/}
          {/*<span style={{color:COLORS.basfWhite, fontStyle:"bold"}}>*/}
          {/*  {(companyDetails && companyDetails.data ?  companyDetails.data.company_name : 'Default Project')}*/}
          {/*</span>*/}
        </div>
      </div>

      <List style={{ marginTop: "3%" }}>
        <Divider className={classes.dividerColor} />
      </List>
      <List style={{ marginTop: "1%" }}>
        {routes.slice(0, 6).map((route, index) => {
          let showDoc =
            user && user.role === "User" && route.id === 3 ? false : true;

          const showRequestModule = !(user?.role === "User" && route.id === 0);

          return (
            <>
              {route.visible && showDoc && showRequestModule && (
                <Link to={route.link} className={classes.linkColor} key={index}>
                  <SidebarContainer>
                    <ListItem
                      button
                      selected={route.link === pathUrl}
                      style={{
                        background: `${
                          route.link === pathUrl
                            ? user && user.role === "User"
                              ? COLORS.basfDarkBlue
                              : COLORS.basfOrange
                            : ""
                        }`,
                        textDecoration: "none",
                        color: "white",
                        margin: 4,
                        marginLeft: 18,
                        justifyContent: "center",
                        borderRadius: 10,
                        width: 210,
                      }}
                      className="menuButton"
                    >
                      {route.icon}
                      <ListItemText
                        disableTypography
                        primary={route.label}
                        style={{
                          textAlign: "left",
                          fontSize: "15px",
                          marginLeft: 20,
                        }}
                      />
                    </ListItem>
                  </SidebarContainer>
                </Link>
              )}
            </>
          );
        })}
      </List>

      <List>
        <Divider className={classes.dividerColor} />
        {user && user.role === "Vendor" && (
          <ListItem
            style={{
              textDecoration: "none",
              color: "white",

              marginLeft: 24,
              justifyContent: "center",
              borderRadius: 10,
              width: 210,
              cursor: "pointer",
            }}
            onClick={handleAddTeam}
          >
            <ListItemText>Add Team</ListItemText>
            <Add></Add>
          </ListItem>
        )}
        {teamData &&
          teamData?.data?.length > 0 &&
          teamData.data.map((teams: any, j: any) => {
            const id = encode(teams.id);
            const typeIs = teams.is_default;
            const isDirect = false;
            const icon = teams.is_default ? "# " : "# ";
            return (
              <ListItem
                selected={"/home/discussion/" + id === pathUrl}
                style={{
                  background: `${
                    "/home/discussion/" + id === pathUrl
                      ? user && user.role === "User"
                        ? COLORS.basfDarkBlue
                        : COLORS.basfOrange
                      : ""
                  }`,
                  fontSize: 12,
                }}
              >
                <Link
                  to={"/home/discussion/" + id}
                  className={classes.linkColor}
                  key={j}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: 21,
                    justifyContent: "left",
                    width: 200,
                    cursor: "pointer",
                  }}
                >
                  <ListItemText style={{ fontSize: 11 }}>
                    {icon}
                    {teams.name}
                  </ListItemText>
                </Link>
                {user && user.role === "Vendor" && !typeIs && (
                  <TeamPopover
                    type={typeIs}
                    isDirect={isDirect}
                    teamId={teams.id}
                    isPublic={teams.visible_to === "Public"}
                  />
                )}
              </ListItem>
            );
          })}
      </List>

      <List style={{ marginBottom: 50 }}>
        <Divider className={classes.dividerColor} />
        {user && user.role === "Vendor" && (
          <ListItem
            style={{
              textDecoration: "none",
              color: "white",
              marginLeft: 22,
              justifyContent: "center",
              borderRadius: 10,
              width: 210,
              cursor: "pointer",
            }}
            onClick={handleDirectMessage}
          >
            <ListItemText>Direct Chat</ListItemText>
            <Add></Add>
          </ListItem>
        )}
        {teamDirect &&
          teamDirect?.data?.length > 0 &&
          teamDirect.data.map((teamsD: any, k: any) => {
            const idD = encode(teamsD.teamId);
            const icon = teamsD.is_default ? "# " : "# ";
            const typeIs = 1;
            const isDirect = true;
            const liveColor = teamsD.live_status
              ? COLORS.basfLightGreen
              : COLORS.basfRed;
            return (
              <ListItem
                selected={"/home/discussion/" + idD === pathUrl}
                style={{
                  background: `${
                    "/home/discussion/" + idD === pathUrl
                      ? user && user.role === "User"
                        ? COLORS.basfDarkBlue
                        : COLORS.basfOrange
                      : ""
                  }`,
                  fontSize: 12,
                }}
              >
                <Link
                  to={"/home/discussion/" + idD}
                  className={classes.linkColor}
                  key={k}
                  style={{
                    textDecoration: "none",
                    color: "white",
                    marginLeft: 21,
                    justifyContent: "left",
                    width: 200,
                    cursor: "pointer",
                    display: "flex",
                  }}
                >
                  <StyledBadge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    style={{ zIndex: 0 }}
                    colors={liveColor}
                  >
                    <Avatar
                      style={{
                        width: 35,
                        height: 35,
                        backgroundColor: COLORS.basfMiddleGrey,
                      }}
                      alt={teamsD ? teamsD.icon : ""}
                      src={teamsD ? BASE_URL + "" + teamsD.profile : ""}
                    >
                      {teamsD.icon}
                    </Avatar>
                  </StyledBadge>
                  <ListItemText
                    style={{ fontSize: 11, display: "flex", marginLeft: "6%" }}
                  >
                    {teamsD.un_read > 0 ? (
                      <>
                        <b>{teamsD.name}</b>
                        <span
                          style={{
                            height: "13px",
                            width: "13px",
                            padding: "2px",
                            borderRadius: "50%",
                            fontSize: "13px",
                            display: "inline",
                            backgroundColor: COLORS.basfWhite,
                            marginLeft: "2px",
                            color: COLORS.basfBlack,
                          }}
                        >
                          {teamsD.un_read}
                        </span>
                      </>
                    ) : (
                      <>{teamsD.name}</>
                    )}
                  </ListItemText>
                </Link>
                <TeamPopover
                  type={typeIs}
                  isDirect={isDirect}
                  teamId={teamsD.teamId}
                  isPublic={false}
                />
              </ListItem>
            );
          })}
      </List>
      <Footer
        title={
          companyDetails && companyDetails?.data
            ? companyDetails.data.company_name
            : "Default Project"
        }
        id={companyDetails && companyDetails.data ? companyDetails.data.id : ""}
      />
      {openAddPop && (
        <AddTeamPopover
          openAddTeam={openAddPop}
          handleAddTeam={handleAddTeam}
        />
      )}
      {openDirectMessage && (
        <DirectCreatPop
          openDirectMessage={openDirectMessage}
          handleDirectMessage={handleDirectMessage}
          teamDirect={teamDirect?.data || []}
        />
      )}
    </Drawer>
  );
};

export default Sidebar;

const SidebarContainer = styled.main`
  .menuButton:hover {
    background-color: ${COLORS.basfOrange};
  }
`;

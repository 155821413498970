import React, { useEffect, useState } from "react";
import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import Grid from "@material-ui/core/Grid";
import { CalendarTodayOutlined } from "@material-ui/icons";
import ViewCalender from "../../components/calender/ViewCalender";

const initialPlayerState = {
  docId: "",
  name: "",
  createdAt: null,
};

const Calender = () => {
  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Calender"
      icon={<CalendarTodayOutlined />}
    >
      <Grid style={{
        width: "70%",
        position:"absolute",
      }}>
        <ViewCalender/>
      </Grid>
    </HomeDefault>
  );
};

export default Calender;

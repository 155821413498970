import LoadingTypes from "../types/loadingType";

export interface LoadingState {
  loadMessage: boolean;
}

interface ReducerAction {
  type: string;
}

const INITIAL_STATE: LoadingState = {
  loadMessage: false,
};

const MessageLoadReducer = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case LoadingTypes.RESET_LOADING:
      return INITIAL_STATE;
    case LoadingTypes.LOADING_MESSAGE:
      return {
        loadMessage: true,
      };
    case LoadingTypes.LOADING_MESSAGE_FALSE:
      return {
        loadMessage: false,
      };
    default:
      return state;
  }
};

export default MessageLoadReducer;

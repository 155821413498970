import React, { FC } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export type ForgotPasswordStep1FormInputs = {
  email: string;
};

type Props = {
  onSubmit: (values: ForgotPasswordStep1FormInputs) => void;
};

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

export const ForgotPasswordStep1Form: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordStep1FormInputs>({
    resolver: yupResolver(schema),
  });

  return (
    <form action={"#"} onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="textField margin" variant="outlined" size="small">
        <InputLabel htmlFor="outlined-adornment-username">Email</InputLabel>
        <OutlinedInput
          id="outlined-adornment-username"
          label="Enter Email"
          aria-describedby="outlined-weight-helper-text"
          placeholder="Enter your registered email *"
          {...register("email")}
          error={Boolean(errors?.email?.message)}
        />
        {errors?.email?.message && (
          <FormHelperText error>{errors?.email?.message}</FormHelperText>
        )}
      </FormControl>

      <br />
      <br />
      <Button
        variant="contained"
        className="loginButton"
        type="submit"
        disabled={isSubmitting}
      >
        Continue
      </Button>
    </form>
  );
};

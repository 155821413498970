import { RootState } from "../";
import { ThunkAction } from "redux-thunk";
import LoadingType from "../types/loadingType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../types/alertType";
import DocumentType, {
  AddDocument,
  AddFolder,
  AddNotes,
  GetDocument,
  RemoveInitiative,
  UpdateFolder,
} from "../types/initiativeType";
import FormData from "form-data";
import InitiativeTypes from "../types/initiativeType";

interface Action {
  type: string;
  message?: string;
  payload?: any;
}

const InitiativeAction = {
  addInitiative:
    (data: AddFolder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_initiative",
          {
            name: data.name,
            company_id: companyId,
            folder_id: data.folder_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addNotes:
    (data: AddNotes): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_notes",
          {
            name: data.name,
            description: data.description,
            company_id: companyId,
            folder_id: data.folder_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addFiles:
    (data: AddDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );

        const formData = new FormData();
        formData.append("document", data.attachment);
        formData.append("company_id", companyId);
        formData.append("folder_id", data.folder_id);

        const message: any = await axios.post(
          BASE_URL + "api/v1/create_file",
          formData,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getInitiatives:
    (data: GetDocument): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        dispatch({
          type: InitiativeTypes.SET_INITIATIVE,
          payload: "",
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const urlIs = data.folder_id
          ? BASE_URL +
            "api/v1/get_initiative/" +
            companyId +
            "/" +
            data.folder_id +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort
          : BASE_URL +
            "api/v1/get_initiative/" +
            companyId +
            "/" +
            null +
            "/" +
            data.teamId +
            "/" +
            data.keyword +
            "/" +
            data.sort;
        const message: any = await axios.get(urlIs, {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: InitiativeTypes.SET_INITIATIVE,
          payload: message.data,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  removeInitiative:
    (data: RemoveInitiative): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.delete(
          BASE_URL + "api/v1/delete_initiative",
          {
            data: {
              document_id: data.document_id,
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  updateInitiatives:
    (data: UpdateFolder): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/update_initiative",
          {
            name: data.name,
            desc: data.desc,
            id: data.id,
            folder_id: data.folder_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },
};

export default InitiativeAction;

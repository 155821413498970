import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import ChatSidePop from "./ChatSidePop";
import Moment from "react-moment";
import DiscussionAction from "../../stores/actions/discussionAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { Link, useParams } from "react-router-dom";
import { BASE_URL, socket, validImageTypes } from "../../utils/Constants";
import fileImage from "../../assets/image/free-file-icon-1453-thumb.png";
import ReplySection from "./ReplySection";
import { COLORS } from "../../utils/Colors";
import { Close } from "@material-ui/icons";
import { encode } from "js-base64";

const useStyles = makeStyles((theme) => ({
  chatGrid: {},
  chatCard: {
    cursor: "pointer",
  },

  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: 10,
  },

  joinButton: {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.white,
      color: COLORS.basfBlack,
      boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
    },
  },
}));

interface Props {
  messageId: any;
  teamId: any;

  activeScroll: any;
}

const MessageBox: React.FC<Props> = ({ messageId, teamId, activeScroll }) => {
  const [openSide, setOpenSide] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight - 368);
  const classes = useStyles();
  const [showScroll, setShowScroll] = useState(false);
  const { singleMessageData } = useSelector(
    (state: RootState) => state.discussion
  );

  const bottomRef = useRef<any>(null);

  const { user } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch()<any>;

  const getSingleMessageData = useCallback(async () => {
    if (messageId) {
      const message_id = messageId;
      await dispatch(DiscussionAction.getSingleMessage({ message_id }));
      scrollHandle();
    }
  }, [dispatch, messageId]);

  useEffect(() => {
    getSingleMessageData();
  }, [getSingleMessageData, activeScroll]);

  useEffect(() => {
    socket.off("message_sent").on("message_sent", async () => {
      await dispatch(
        DiscussionAction.getSingleMessage({ message_id: messageId })
      );
      scrollHandle();
    });
  }, [dispatch, messageId]);

  const scrollHandle = () => {
    if (bottomRef.current)
      bottomRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleResize = () => {
    setScreenHeight(window.innerHeight - 368);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  return (
    <>
      <Grid>
        {singleMessageData &&
          singleMessageData.data.length > 0 &&
          singleMessageData.data.reverse().map((chats: any, i: any) => {
            let time = (
              <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                {chats.created_at}
              </Moment>
            );
            let meetTime = (
              <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                {chats.meeting.date_time}
              </Moment>
            );
            let id = chats.id;
            let ext = chats.files ? chats.files.split(".").pop() : "";
            let file_name = validImageTypes.includes(ext)
              ? BASE_URL + "" + chats.files
              : fileImage;
            let name = chats.first_name
              ? chats.first_name + " " + chats.last_name
              : chats.username;
            let avator = chats.profile
              ? BASE_URL + "" + chats.profile
              : chats.username.substring(0, 2);
            let bgColorFirst = chats.profile
              ? COLORS.basfWhite
              : COLORS.basfRed;
            return (
              <>
                <Card key={i}>
                  <Link
                    to={"/home/discussion/" + encode(teamId)}
                    style={{ float: "right", textDecoration: "none" }}
                  >
                    {" "}
                    <Button>
                      <Close></Close>
                    </Button>
                  </Link>
                  <CardHeader
                    avatar={
                      <IconButton
                        size="small"
                        aria-controls={
                          "account-menu" ? "account-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={"true" ? "true" : undefined}
                      >
                        <Avatar
                          style={{
                            width: 32,
                            height: 32,
                            textAlign: "right",
                            float: "right",
                            backgroundColor: bgColorFirst,
                          }}
                          alt={
                            avator[0].toUpperCase() +
                            avator.slice(1).toUpperCase()
                          }
                          src={avator}
                        ></Avatar>
                      </IconButton>
                    }
                    title={name}
                    subheader={time}
                    action={
                      <ChatSidePop
                        messageId={id}
                        isMain={"false"}
                        teamId={teamId}
                      />
                    }
                  />

                  <CardContent style={{ marginLeft: 60 }}>
                    <Typography paragraph>
                      {chats.message_type === "content" ? (
                        <>
                          {chats.files ? (
                            <a
                              href={BASE_URL + "" + chats.files}
                              target={"_blank"}
                            >
                              <img
                                src={file_name}
                                style={{
                                  width: "10%",
                                  marginBottom: "5%",
                                  marginLeft: "25%",
                                }}
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {chats.content}
                        </>
                      ) : (
                        ""
                      )}
                      {chats.message_type === "meeting" ? (
                        <>
                          <p>
                            # {name} invites you to join the call{" "}
                            {chats.content} at {meetTime}{" "}
                          </p>
                          <a
                            href={chats.join_link}
                            target={"_blank"}
                            style={{ textDecoration: "none" }}
                          >
                            {" "}
                            <Button className={clsx(classes.joinButton)}>
                              {chats.button_name}
                            </Button>
                          </a>
                        </>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </CardContent>
                  <Divider style={{ width: "100%" }}></Divider>
                  {/*<Grid style={{*/}
                  {/*  maxHeight: screenHeight+"px",*/}
                  {/*  overflowY: showScroll ? "scroll" : "hidden" ,*/}
                  {/*}}*/}
                  {/*      onMouseEnter={() => setShowScroll(true)}*/}
                  {/*      onMouseLeave={() => setShowScroll(false)}*/}
                  {/*>*/}
                  <div
                    style={{
                      maxHeight: screenHeight + "px",
                      display: "flex",
                      overflowY: "auto",
                      flexDirection: "column-reverse",
                      width: "100%",
                    }}
                    // onScroll={scrollTopHandle}
                    onMouseEnter={() => setShowScroll(true)}
                    onMouseLeave={() => setShowScroll(false)}
                  >
                    <div>
                      {chats.discussion.map((discossion: any, j: any) => {
                        let ext2 = discossion.files
                          ? discossion.files.split(".").pop()
                          : "";
                        let nameSub = discossion.first_name
                          ? discossion.first_name + " " + discossion.last_name
                          : discossion.username;
                        let chatid = discossion.id;
                        let meetTime2 = (
                          <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                            {discossion.meeting.date_time}
                          </Moment>
                        );
                        let file_name2 = validImageTypes.includes(ext2)
                          ? BASE_URL + "" + discossion.files
                          : fileImage;
                        let avator2 = discossion.profile
                          ? BASE_URL + "" + discossion.profile
                          : discossion.username.substring(0, 2);
                        let bgColor = discossion.profile
                          ? COLORS.basfWhite
                          : COLORS.basfDarkBlue;
                        let timeSub = (
                          <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                            {discossion.created_at}
                          </Moment>
                        );
                        return (
                          <>
                            <CardContent
                              key={j}
                              style={{
                                backgroundColor: COLORS.white,
                                width: "100%",
                              }}
                            >
                              <CardHeader
                                avatar={
                                  <IconButton
                                    size="small"
                                    aria-controls={
                                      "account-menu"
                                        ? "account-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={"true" ? "true" : undefined}
                                  >
                                    <Avatar
                                      style={{
                                        width: 32,
                                        height: 32,
                                        textAlign: "right",
                                        float: "right",
                                        backgroundColor: bgColor,
                                      }}
                                      alt={
                                        avator2[0].toUpperCase() +
                                        avator2.slice(1).toUpperCase()
                                      }
                                      src={avator2}
                                    ></Avatar>
                                  </IconButton>
                                }
                                title={nameSub}
                                subheader={timeSub}
                                action={
                                  <ChatSidePop
                                    messageId={chatid}
                                    isMain={"false"}
                                    teamId={teamId}
                                    isFeed={true}
                                    isOwner={
                                      +discossion?.sender_id === +user?.id
                                    }
                                  />
                                }
                              />
                              <CardContent style={{ marginLeft: 60 }}>
                                <Typography paragraph>
                                  {discossion.message_type === "content" ? (
                                    <>
                                      {discossion.files ? (
                                        <a
                                          href={
                                            BASE_URL + "" + discossion.files
                                          }
                                          target={"_blank"}
                                        >
                                          <img
                                            src={file_name2}
                                            style={{
                                              width: "10%",
                                              float: "left",
                                            }}
                                          />
                                          <br />
                                          <br />
                                          {discossion.file_name}
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                      {discossion.content}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {discossion.message_type === "meeting" ? (
                                    <>
                                      <p>
                                        # {nameSub} invites you to join the call{" "}
                                        {discossion.content} at {meetTime2}{" "}
                                      </p>
                                      <a
                                        href={discossion.join_link}
                                        target={"_blank"}
                                        style={{ textDecoration: "none" }}
                                      >
                                        {" "}
                                        <Button
                                          className={clsx(classes.joinButton)}
                                        >
                                          {discossion.button_name}
                                        </Button>
                                      </a>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                              </CardContent>
                            </CardContent>
                            <Divider />
                          </>
                        );
                      })}
                    </div>
                    {/*<div ref={bottomRef}></div>*/}
                  </div>
                </Card>
              </>
            );
          })}
      </Grid>
    </>
  );
};

export default MessageBox;

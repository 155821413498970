import React, { useCallback, useEffect, useRef, useState } from "react";

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'

import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import TaskAction from "../../stores/actions/taskAction";
import { stringify } from "querystring";



export interface Props {

}

const ViewCalender: React.FC<Props> = ({}) => {

  const [screenHeight, setScreenHeight ] = useState((window.innerHeight - 168));
  const [currentDate , setCurrentDate] = useState('');
  const dispatch = useDispatch()<any>;
  const { calenderData  } = useSelector(
    (state: RootState) => state.tasks
  )

  const handleResize = () => {
    setScreenHeight((window.innerHeight - 168))
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })

  const getCalenderData = useCallback( async (date: any) => {
    await dispatch(TaskAction.getCalenderTask({date: date}));
  } , [dispatch])

  useEffect (() => {
    getCalenderData('')
  } , [getCalenderData])

  const handleCalenderClick = async (event: any) => {
    getCalenderData(event.endStr)
  }

  return (
    <Grid container spacing={2} style={{
      maxHeight: screenHeight+"px",
      overflowX: "scroll",
      alignItems: "center",
      bottom: 0,
    }}>
      <Grid item xs={12}>
        {calenderData &&
          <FullCalendar
            plugins={[ dayGridPlugin , interactionPlugin , timeGridPlugin ]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth'
            }}
            initialView='dayGridMonth'
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            datesSet={(text) => handleCalenderClick(text) }
            events={calenderData.data}
          />
        }
      </Grid>
    </Grid>
  )

}

export default ViewCalender;

const AuthTypes = {
  RESET_AUTH: "RESET_AUTH",
  AUTHENTICATE: "AUTHENTICATE",
  RESET_CHECK_USER: "RESET_CHECK_USER",
  SET_USER_CHECK: "USER_CHECK",
  SIGN_IN: "SIGN_IN",
  SIGN_OUT: "SIGN_OUT",
  SET_USER: "SET_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  GET_USER_PROFILE: "GET_USER_PROFILE",
};

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  profile: string;
  created_at: any;
}

export interface SignUp {
  username: string;
  email: string;
  password: string;
  role: string;
  company: string | null;
  company_name: string | null;
}

export interface SignUpInvited {
  username: string;
  email: string;
  password: string;
  role: string;
  invitaionId: string;
}



export interface CheckUser {
  username: string;
}

export interface SignIn {
  username: string;
  password: string;
}

export interface SignOut {
  tokens: string;
}

export interface GetUser {
  id: string;
}

export interface VerifyToken {
  tokens: string;
}

export interface SendOtp {
  email: string;
}

export interface VerifyOTP {
  otp: string;
  email: string;
}

export interface ChangePassword {
  email: string;
  otp: string;
  password: string;
  confirmPassword: string;
}

export interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface UpdateProfileData {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;

}

export interface UpdateProfileImage {
  image: string;
}

export interface GetProfileData {

}

export default AuthTypes;

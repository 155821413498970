import DiscussionTypes, { Message } from "../types/discussionType";

export interface MessageState {
  message: Message | null;
  sends: boolean;
}

interface ReducerAction {
  type: string;
  payload?: any;
}

const initial_state = {
  teamData: null,
  messageData: null,
  singleMessageData: null,
  companyMembers: null,
  teamDirect: null,
  messageFiles: null,
  teamMembers: null,
  teamDetails: null,
  searchUser: null,
};

const discussionReducer = (state = initial_state, action: ReducerAction) => {
  switch (action.type) {
    case DiscussionTypes.SET_TEAM_DAFULT:
      return {
        ...state,
        teamData: action.payload,
      };

    case DiscussionTypes.SET_TEAM:
      return {
        ...state,
        teamData: action.payload,
      };

    case DiscussionTypes.SET_TEAM_DETAILS:
      return {
        ...state,
        teamDetails: action.payload,
      };

    case DiscussionTypes.SET_MESSAGE:
      return {
        ...state,
        messageData: action.payload,
      };

    case DiscussionTypes.SET_SINGLE_MSG:
      return {
        ...state,
        singleMessageData: action.payload,
      };

    case DiscussionTypes.SET_COMPANY_MEMBER:
      return {
        ...state,
        companyMembers: action.payload,
      };

    case DiscussionTypes.SET_TEAM_PERSONL:
      return {
        ...state,
        teamDirect: action.payload,
      };

    case DiscussionTypes.SET_MESSAGE_FILES:
      return {
        ...state,
        messageFiles: action.payload,
      };

    case DiscussionTypes.SET_TEAM_MEMBER:
      return {
        ...state,
        teamMembers: action.payload,
      };

    case DiscussionTypes.SET_SEARCH_USER:
      return {
        ...state,
        searchUser: action.payload,
      };

    case DiscussionTypes.DELETE_CHAT_MESSAGE: {
      const messageData: any = state.messageData;

      if (!action.payload?.isFeed) {
        const data = [...messageData.data];

        const index = data.findIndex(
          (d) => +d.id === +action.payload?.messageId
        );

        if (index > -1) {
          data.splice(index, 1);

          return {
            ...state,
            messageData: { ...messageData, data: [...data] },
          };
        }
      } else {
        let singleMessageData: any = state.singleMessageData;
        singleMessageData = { ...singleMessageData };

        if (singleMessageData?.data?.[0]?.discussion) {
          const index = singleMessageData?.data?.[0]?.discussion.findIndex(
            (d: any) => +d.id === +action.payload?.messageId
          );

          if (index > -1) {
            singleMessageData?.data?.[0]?.discussion?.splice(index, 1);

            return {
              ...state,
              singleMessageData: {
                ...singleMessageData,
              },
            };
          }
        }
      }

      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default discussionReducer;

import AuthTypes, { User } from "../types/authTypes";

export interface AuthState {
  user: User | null;
  authenticated: boolean;
  token: any;
  userData: any;
}

interface ReducerAction {
  type: string;
  payload?: any;
  token?: any;
}

const INITIAL_STATE: AuthState = {
  user: null,
  authenticated: false,
  token: null,
  userData: null,
};

const AuthReducer = (state = INITIAL_STATE, action: ReducerAction) => {
  switch (action.type) {
    case AuthTypes.RESET_AUTH:
      localStorage.removeItem("@userData:token");
      localStorage.removeItem("@userData:companyId");
      localStorage.removeItem("@userData:userId");
      return INITIAL_STATE;

    case AuthTypes.SET_USER:
      localStorage.setItem("@userData:token", JSON.stringify(action.token));
      localStorage.setItem("@userData:userId", JSON.stringify(action.payload.id));
      localStorage.setItem("@userData:companyId", JSON.stringify(action.payload.companyId));
      return {
        ...state,
        user: action.payload,
        authenticated: true,
        token: action.token,
      };

    case AuthTypes.SET_USER_CHECK:
      return {
        ...state,
        user: action.payload,
      };

    case AuthTypes.GET_USER_PROFILE:
        return {
          ...state,
          userData: action.payload
        }


    default:
      return state;
  }
};

export default AuthReducer;

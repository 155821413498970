import InitiativeTypes from "../types/initiativeType";

interface DocumentActions {
  type: string,
  payload?: any,
}


const initial_state = {
  initiativeList: null
}

const InitiativeReducer = (state = initial_state , action: DocumentActions) => {
  switch (action.type) {

    case InitiativeTypes.SET_INITIATIVE:
      return {
        ...state,
        initiativeList: action.payload
      }

    default:
      return state
  }
}

export default InitiativeReducer;



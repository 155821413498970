import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/Colors";
import { Add } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import TaskAction from "../../stores/actions/taskAction";
import Moment from "react-moment";
import TaskTaskPop from "./TaskTaskPop";
import Autocomplete from "@mui/material/Autocomplete";
import clsx from "clsx";
import TaskStatusPopup from "./TaskStatusPopup";
import LinearProgress, {
  LinearProgressProps,
} from "@material-ui/core/LinearProgress";
import SubTaskManage from "./SubTaskManage";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box style={{ width: "100%", margin: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box style={{ minWidth: 35 }}>
        <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },

  hoverCard1: {
    backgroundColor: COLORS.basfWhite,
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
    },
  },

  hoverCard2: {
    backgroundColor: COLORS.basfBoxGrey,
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
    },
  },

  hoverMore: {
    float: "right",
    marginRight: "1%",
    color: COLORS.basfDarkBlue,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.basfLightGrey,
      cursor: "pointer",
      color: COLORS.basfDarkBlue,
      borderRadius: "50px",
    },
  },

  buttonSubTask: {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,

    fontSize: "12px",
    marginLeft: "0%",
    textAlign: "right",
    textTransform: "none",
    "&:hover": {
      backgroundColor: COLORS.basfOrange,
      cursor: "pointer",
      color: COLORS.basfWhite,
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  projectId: any;
  projectDetails: any;
}

const TaskGrid: React.FC<Props> = ({ projectId, projectDetails }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [statusName, setStatusName] = useState("");
  const [statusColor, setStatusColor] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [openNewStatus, setNewStatus] = useState(false);

  const [openNewTask, setNewTask] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [taskStartDate, setTaskStartDate] = useState(
    projectDetails.data[0].start_date + " 00:00:00"
  );
  const [taskEndDate, setTaskEndDate] = useState(
    projectDetails.data[0].end_date + " 00:00:00"
  );
  const [taskAllocated, setTaskAllocated] = useState("");
  const [taskStatus, setTaskStatus] = useState("");
  const [inputTeam, setInputTeam] = useState("");
  const [inputTeamId, setInputTeamId] = useState("");
  const [inputTaskType, setInputTaskType] = useState("");
  const [inputTaskDepend, setInputTaskDepend] = useState("");

  const [subTask, setSubTask] = useState("");
  const [taskId, setTaskId] = useState("");
  const [subTaskStart, setSubTaskStart] = useState("");
  const [subTaskEnd, setSubTaskEnd] = useState("");
  const [openNewSubTask, setNewSubTask] = useState(false);

  const [editTaskStatus, setEditTaskStatus] = useState(false);
  const [showAutoComplete, setShowAutoComplet] = useState(false);

  const { allocateList, statusListOnly, statusList, taskList } = useSelector(
    (state: RootState) => state.tasks
  );

  const { user } = useSelector((state: RootState) => state.auth);

  const [colorIs, setColor] = useState([]);

  const dispatch = useDispatch()<any>;

  //Get task projects details
  const getProjectsStatus = useCallback(async () => {
    const projectid = projectId;
    const result = await dispatch(TaskAction.getProjectStatus({ projectid }));
  }, [dispatch]);

  const getAllocatedData = useCallback(
    async (typeIs: any) => {
      const type = typeIs;
      const keyword = "";
      const result = await dispatch(
        TaskAction.searchTaskAllocate({ type, keyword })
      );
      if (result.status) {
        setShowAutoComplet(true);
      }
    },
    [dispatch]
  );

  //Call callback function
  useEffect(() => {
    getProjectsStatus();
  }, [getProjectsStatus, editTaskStatus]);

  const searchKeyword = async (event: any) => {};

  const handleChange = (event: React.SyntheticEvent | any, newValue: any) => {
    setValue(newValue);
  };

  const handleAllocate = (event: any) => {
    setShowAutoComplet(false);
    setTaskAllocated(event.target.value);
    getAllocatedData(event.target.value);
    setInputTeam("");
    setInputTeamId("");
  };

  const handleNewStatus = () => {
    setNewStatus(!openNewStatus);
  };

  const handleNewTask = (e: any | null) => {
    setTaskStatus(e);
    setNewTask(!openNewTask);
  };

  const handleSubTask = (e: any | null) => {
    setNewSubTask(!openNewSubTask);
  };

  const openSubTaskAdd = (id: any | null, startTask: any, endTask: any) => {
    setTaskId(id);
    setNewSubTask(!openNewSubTask);
    setSubTaskStart(startTask);
    setSubTaskEnd(endTask);
  };

  // process for save task projects into the database
  const saveStatus = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true);
    const name = statusName;
    const color = statusColor;
    const projectid = projectId;
    const result = await dispatch(
      TaskAction.addProjectStatus({ name, color, projectid })
    );
    if (result && result.status) {
      setStatusName("");
      setStatusColor("");
      getProjectsStatus();
      handleNewStatus();
    }
    setButtonLoader(false);
  };

  // process for save task projects into the database
  const saveTask = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true);
    const name = taskName;
    const description = taskDescription;
    const startDate = taskStartDate;
    const endDate = taskEndDate;
    const status = taskStatus;
    const projectid = projectId;
    const type = taskAllocated;
    const allocated = inputTeamId;

    const result = await dispatch(
      TaskAction.addProjectTask({
        name,
        description,
        startDate,
        endDate,
        projectid,
        status,
        type,
        allocated,
        task_type: inputTaskType,
        depend_task: inputTaskDepend,
      })
    );
    if (result && result.status) {
      setTaskName("");
      setTaskDescription("");
      setTaskStartDate("");
      setTaskEndDate("");
      setTaskStatus("");
      setSubTask("");
      getProjectsStatus();
      handleNewTask("");
    }
    setButtonLoader(false);
  };

  // process for save sub task projects into the database
  const saveSubTask = async (event: any) => {
    event.preventDefault();
    setButtonLoader(true);

    const result = await dispatch(
      TaskAction.addSubTask({
        subTask: subTask,
        startDate: subTaskStart,
        endDate: subTaskEnd,
        taskId: taskId,
      })
    );
    if (result && result.status) {
      setSubTask("");
      setSubTaskStart("");
      setSubTaskEnd("");
      getProjectsStatus();
      handleSubTask("");
    }
    setButtonLoader(false);
  };

  const handleChangeColor = (event: any) => {
    setStatusColor(event.target.value as string);
  };

  const handleTaskType = (event: any) => {
    setInputTaskType(event.target.value as string);
  };

  const handleTaskDepend = (event: any) => {
    setInputTaskDepend(event.target.value as string);
  };

  return (
    <>
      <Paper style={{ width: "100%", marginTop: "2%", flexGrow: 1 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: COLORS.basfLightBlue,
                color: COLORS.basfLightBlue,
              },
            }}
          >
            {statusList?.data &&
              statusList?.data?.map((status: any, index: any) => {
                return (
                  <Tab
                    label={status.status_name}
                    {...a11yProps(index)}
                    style={{ color: status.color }}
                  />
                );
              })}
            {user && user.role === "Vendor" && (
              <Button onClick={handleNewStatus}>
                {" "}
                <Add></Add> New
              </Button>
            )}
          </Tabs>
        </Box>
        {statusList?.data &&
          statusList.data.map((statusSecond: any, indexJ: any) => {
            return (
              <TabPanel value={value} index={indexJ}>
                {user && user.role === "Vendor" && (
                  <>
                    <Button onClick={() => handleNewTask(statusSecond.id)}>
                      <Add /> New Task
                    </Button>
                    <TaskStatusPopup
                      statusList={statusSecond}
                      setEditTaskStatus={setEditTaskStatus}
                      editTaskStatus={editTaskStatus}
                    />
                  </>
                )}
                {statusSecond?.tasks &&
                  statusSecond.tasks.map((task: any, indexK: any) => {
                    let date = (
                      <Moment format={"MMM Do YYYY, h:mm a"}>
                        {task.created_at}
                      </Moment>
                    );
                    let startDate = (
                      <Moment format={"MMM Do YYYY, h:mm a"}>
                        {task.start_date}
                      </Moment>
                    );
                    let endDate = (
                      <Moment format={"MMM Do YYYY, h:mm a"}>
                        {task.end_date}
                      </Moment>
                    );
                    let allocated =
                      task.allocate_type != null
                        ? " Allocated To: " + task.allocatedTo
                        : "";
                    let color = indexK % 2 == 0 ? true : false;
                    let margin = indexK === 0 ? "2%" : "";
                    return (
                      <div key={indexK}>
                        <Card
                          className={clsx(
                            color ? classes.hoverCard1 : classes.hoverCard2
                          )}
                          style={{ marginTop: margin }}
                        >
                          <CardHeader
                            title={task.task_name}
                            titleTypographyProps={{
                              style: { fontSize: 15, fontWeight: "bold" },
                            }}
                            subheaderTypographyProps={{
                              style: { fontSize: 12 },
                            }}
                            action={
                              user && user.role === "Vendor" ? (
                                <TaskTaskPop
                                  projectDetails={projectDetails}
                                  taskDetails={task}
                                  statusList={statusListOnly}
                                  setEditTaskStatus={setEditTaskStatus}
                                  editTaskStatus={editTaskStatus}
                                />
                              ) : (
                                ""
                              )
                            }
                            subheader={date}
                          ></CardHeader>
                          <CardMedia
                            style={{ marginLeft: "1.5%", marginRight: "1.5%" }}
                          >
                            <span style={{ fontSize: 12 }}>
                              {task.task_description}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: 11,
                                color: COLORS.basfDarkGrey,
                              }}
                            >
                              Start: {startDate} , End: {endDate}
                            </span>
                          </CardMedia>
                          <CardContent>
                            <Typography variant="body2">
                              {allocated}
                              <br />
                              Progress:{" "}
                              <LinearProgressWithLabel value={task.progress} />
                              <br />
                              Type:{" "}
                              <span style={{ textTransform: "capitalize" }}>
                                {task.task_type}
                              </span>
                              <br />
                              <span style={{ color: COLORS.basfRed }}>
                                Reason's:
                              </span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: task.reason,
                                }}
                              />
                              <List>
                                {task.sub_tasks.length > 0 && (
                                  <h4 style={{ marginLeft: "2%" }}>
                                    Sub Task's
                                  </h4>
                                )}
                                {task?.sub_tasks?.length > 0 &&
                                  task.sub_tasks.map(
                                    (sub_task: any, j: any) => {
                                      let subStartDate = (
                                        <Moment format={"MMM Do YYYY, h:mm a"}>
                                          {sub_task.start_date}
                                        </Moment>
                                      );
                                      let subEndDate = (
                                        <Moment format={"MMM Do YYYY, h:mm a"}>
                                          {sub_task.end_date}
                                        </Moment>
                                      );

                                      return (
                                        <ListItem key={j}>
                                          <ListItemText
                                            primary={sub_task.sub_task}
                                          />
                                          <span
                                            style={{
                                              fontSize: 11,
                                              color: COLORS.basfDarkGrey,
                                            }}
                                          >
                                            Start: {subStartDate} , End:{" "}
                                            {subEndDate}
                                          </span>
                                          {user && user.role === "Vendor" ? (
                                            <SubTaskManage
                                              subTaskDetails={sub_task}
                                              setEditTaskStatus={
                                                setEditTaskStatus
                                              }
                                              editTaskStatus={editTaskStatus}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </ListItem>
                                      );
                                    }
                                  )}
                              </List>
                              {user && user.role === "Vendor" ? (
                                <Button
                                  className={clsx(classes.buttonSubTask)}
                                  onClick={() =>
                                    openSubTaskAdd(
                                      task.id,
                                      task.start_date,
                                      task.end_date
                                    )
                                  }
                                >
                                  Sub Task <Add style={{ fontSize: "12px" }} />{" "}
                                </Button>
                              ) : (
                                ""
                              )}
                            </Typography>
                          </CardContent>
                        </Card>
                        <Divider />
                      </div>
                    );
                  })}
                {statusSecond.tasks.length <= 0 && (
                  <Card>
                    <CardContent> No Tasks</CardContent>
                  </Card>
                )}
              </TabPanel>
            );
          })}
      </Paper>

      <Dialog
        open={openNewStatus}
        onClose={handleNewStatus}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <form action={"#"} onSubmit={saveStatus}>
          <DialogTitle>New Status</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="statusName"
              label="Status Name"
              value={statusName}
              required={true}
              type="text"
              fullWidth
              variant="standard"
              onChange={(text) => setStatusName(text.target.value)}
            />
            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Color</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={statusColor}
                label="Color"
                onChange={handleChangeColor}
              >
                <MenuItem
                  value={"#F39500"}
                  style={{ color: COLORS.basfOrange }}
                  selected={true}
                >
                  Orange
                </MenuItem>
                <MenuItem
                  value={"#00793A"}
                  style={{ color: COLORS.basfDarkGreen }}
                >
                  Green
                </MenuItem>
                <MenuItem
                  value={"#004A96"}
                  style={{ color: COLORS.basfDarkBlue }}
                >
                  Blue
                </MenuItem>
                <MenuItem value={"#C50022"} style={{ color: COLORS.basfRed }}>
                  Red
                </MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewStatus}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openNewTask}
        onClose={handleNewTask}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <form action={"#"} onSubmit={saveTask}>
          <DialogTitle>New Task </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="taskName"
              label="Task Name"
              value={taskName}
              type="text"
              required={true}
              fullWidth
              variant="outlined"
              onChange={(text) => setTaskName(text.target.value)}
            />
            <br />
            <br />
            <TextField
              margin="dense"
              id="taskDesc"
              label="Task Description"
              value={taskDescription}
              type="text"
              required={true}
              fullWidth
              variant="outlined"
              onChange={(text) => setTaskDescription(text.target.value)}
            />
            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Allocate To</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={taskAllocated}
                label="Allocate To"
                required={true}
                onChange={handleAllocate}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value={"TEAM"}>Team</MenuItem>
                <MenuItem value={"PERSONAL"}>Individual</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            {(taskAllocated === "TEAM" || taskAllocated === "PERSONAL") && (
              <>
                {showAutoComplete && (
                  <Autocomplete
                    id="free-solo-demo"
                    // calling the freeSolo prop inside the Autocomplete component
                    value={inputTeam || null}
                    onChange={(event: any, newValue: any | null) => {
                      setInputTeam(newValue.lable);
                      setInputTeamId(newValue.id);
                    }}
                    // freeSolo
                    options={
                      allocateList?.data
                        ? allocateList.data.map((option: any) => ({
                            id: option.id,
                            label: option.name,
                          }))
                        : [{ label: "Loading...", id: 0 }]
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Search " + taskAllocated}
                      />
                    )}
                  />
                )}
                <br />
                <br />
              </>
            )}

            <TextField
              margin="dense"
              id="startDate"
              label="Start Date"
              value={taskStartDate}
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setTaskStartDate(text.target.value)}
            />
            <br />
            <br />
            <TextField
              margin="dense"
              id="taskDesc"
              label="End Date"
              value={taskEndDate}
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setTaskEndDate(text.target.value)}
            />

            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Task Type</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={inputTaskType}
                label="Task Type"
                required={true}
                onChange={handleTaskType}
              >
                <MenuItem value={"task"}>Task</MenuItem>
                <MenuItem value={"milestone"}>Milestone</MenuItem>
              </Select>
            </FormControl>

            <br />
            <br />
            <FormControl size="small" fullWidth={true}>
              <InputLabel id="demo-select-small">Dependent task</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={inputTaskDepend}
                label="Dependent task"
                onChange={handleTaskDepend}
              >
                {taskList &&
                  taskList?.map((taskData: any, taski: any) => {
                    return (
                      <MenuItem value={taskData.id}>
                        {taskData.task_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNewTask}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        open={openNewSubTask}
        onClose={handleSubTask}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <form action={"#"} onSubmit={saveSubTask}>
          <DialogTitle>Add Sub Tasks </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="subTasks"
              label="Sub Tasks"
              value={subTask}
              required={true}
              type="text"
              fullWidth
              variant="standard"
              onChange={(text) => setSubTask(text.target.value)}
            />
            <br />
            <br />
            <TextField
              InputProps={{
                inputProps: {
                  min: subTaskStart + " 00:00",
                  max: subTaskEnd + " 00:00",
                },
              }}
              margin="dense"
              id="startDate"
              label="Start Date"
              value={
                subTaskStart
                  ? subTaskStart
                  : setSubTaskStart(subTaskStart + " 00:00")
              }
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setSubTaskStart(text.target.value)}
            />
            <br />
            <br />
            <TextField
              InputProps={{
                inputProps: { min: subTaskStart, max: subTaskEnd + " 00:00" },
              }}
              margin="dense"
              id="taskDesc"
              label="End Date "
              value={
                subTaskEnd ? subTaskEnd : setSubTaskEnd(subTaskEnd + " 00:00")
              }
              type="datetime-local"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setSubTaskEnd(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubTask}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default TaskGrid;

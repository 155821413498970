import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
  FormHelperText,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import validator from "validator";

import illustration from "../../assets/image/auth.png";
import Default from "../../layouts/auth/Default";
import { RootState } from "../../stores";
import AuthActions from "../../stores/actions/authAction";
import { Wrapper } from "./SignIn.styles";
import { BASE_URL } from "../../utils/Constants";

export enum UserRole {
  Vendor = "Vendor",
  User = "User",
}

type RegisterInputs = {
  username: string;
  email: string;
  password: string;
  role: UserRole;
  company: string;
  project: string;
};

const checkUniqueUser = (value: string) =>
  axios
    .post(BASE_URL + "api/v1/email_check", {
      username: value,
    })
    .then(async (result) => result.data.status)
    .catch(() => {});

const schema = yup
  .object({
    username: yup
      .string()
      .required()
      .test(
        "checkIfValidUsername",
        "Only alpha and numeric values are allowed",
        (value: string) => {
          return validator.isAlphanumeric(value);
        }
      )
      .test(
        "checkUsernameUnique",
        "This username is already registered.",
        checkUniqueUser
      ),
    email: yup
      .string()
      .email()
      .required()
      .test(
        "checkEmailUnique",
        "This email is already registered.",
        checkUniqueUser
      ),
    password: yup.string().required().min(8),
    role: yup.string().required(),
    company: yup.string().when("role", {
      is: (value: UserRole) => value === UserRole.Vendor,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
    project: yup.string().when("role", {
      is: (value: UserRole) => value === UserRole.Vendor,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    }),
  })
  .required();

const SignUp = () => {
  const history = useNavigate();
  const dispatch = useDispatch()<any>;
  const { authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    const tokens = localStorage.getItem("@userData:token");
    if ((authenticated && token) || tokens) {
      // [ROUTE] Go to dashboard page
      history("/home");
    }
  }, [authenticated, history]);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<RegisterInputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<RegisterInputs> = async (data) => {
    await dispatch(
      AuthActions.signUp({
        username: data.username,
        email: data.email,
        password: data.password,
        role: data.role,
        company: data.project,
        company_name: data.company,
      })
    );
  };

  const role = watch("role");

  return (
    <Wrapper>
      <Default
        illustrationBackground={illustration}
        image={illustration}
        heading="Create new account with email"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          style={{ minHeight: "25vh" }}
        >
          <form action={"#"} onSubmit={handleSubmit(onSubmit)}>
            <Paper
              elevation={3}
              className="paper"
              style={{ maxWidth: "25rem" }}
            >
              <>
                <FormControl
                  className="textField margin"
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-username">
                    Enter Username *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-username"
                    label="Enter Username *"
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Enter Username *"
                    inputProps={{ style: { textAlign: "left" } }}
                    {...register("username")}
                    error={Boolean(errors?.username?.message)}
                  />
                  {errors?.username?.message && (
                    <FormHelperText error>
                      {errors?.username?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <br />
                <br />

                <FormControl
                  className="textField margin"
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-email">
                    Enter Email *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-email"
                    label={"Enter Email *"}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Enter Email *"
                    inputProps={{ style: { textAlign: "left" } }}
                    {...register("email")}
                    error={Boolean(errors?.email?.message)}
                  />
                  {errors?.email?.message && (
                    <FormHelperText error>
                      {errors?.email?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <br />
                <br />

                <FormControl
                  className="textField margin"
                  variant="outlined"
                  size="small"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Enter Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={passwordVisible ? "text" : "password"}
                    label={"Enter Password *"}
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Enter Password *"
                    inputProps={{ style: { textAlign: "left" } }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                          edge="end"
                        >
                          {passwordVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    {...register("password")}
                    error={Boolean(errors?.password?.message)}
                  />
                  {errors?.password?.message && (
                    <FormHelperText error>
                      {errors?.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>

                <br />
                <br />

                <FormControl>
                  <FormLabel id="select-vendor-user-role">Are you ?</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="select-vendor-user-role"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Vendor"
                      control={
                        <Radio
                          onChange={() => setValue("role", UserRole.Vendor)}
                        />
                      }
                      label="Vendor"
                    />
                    <FormControlLabel
                      value="User"
                      control={
                        <Radio
                          onChange={() => setValue("role", UserRole.User)}
                        />
                      }
                      label="Customer"
                    />
                  </RadioGroup>
                  {errors?.role?.message && (
                    <FormHelperText error>
                      {errors?.role?.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <br />
                <br />
                {role === UserRole.Vendor && (
                  <>
                    <FormControl
                      className="textField margin"
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-company">
                        Enter Company Name *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-company"
                        label="Enter Company Name *"
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Enter Company Name *"
                        inputProps={{ style: { textAlign: "left" } }}
                        {...register("company")}
                        error={Boolean(errors?.company?.message)}
                      />
                      {errors?.company?.message && (
                        <FormHelperText error>
                          {errors?.company?.message}
                        </FormHelperText>
                      )}
                    </FormControl>

                    <br />

                    <FormControl
                      className="textField margin"
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel htmlFor="outlined-adornment-project">
                        Default Project Name *
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-project"
                        label="Default Project Name *"
                        aria-describedby="outlined-weight-helper-text"
                        placeholder="Default Project Name *"
                        inputProps={{ style: { textAlign: "left" } }}
                        {...register("project")}
                        error={Boolean(errors?.project?.message)}
                      />

                      {errors?.project?.message && (
                        <FormHelperText error>
                          {errors?.project?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </>
                )}

                <br />
                <br />

                <Button
                  variant="contained"
                  className="loginButton"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Continue
                </Button>
              </>

              <br />
              <br />

              <Link
                to={"/"}
                style={{ color: "#586EF5", textDecoration: "none" }}
              >
                Login ?
              </Link>
              <br />
            </Paper>
          </form>
        </Grid>
      </Default>
    </Wrapper>
  );
};

export default SignUp;

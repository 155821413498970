import React, { useCallback, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DiscussionAction from "../../stores/actions/discussionAction";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import { RootState } from "../../stores";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/Colors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: COLORS.basfLightBlue,
    color: COLORS.white,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
    },
  },
}));

interface Props {
  openDirectMessage: boolean;
  handleDirectMessage: any;
  teamDirect: any;
}

const DirectCreatPop: React.FC<Props> = ({
  openDirectMessage,
  handleDirectMessage,
  teamDirect,
}) => {
  const classes = useStyles();
  const [inputTeam, setInputTeam] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const { companyMembers } = useSelector(
    (state: RootState) => state.discussion
  );

  const dispatch = useDispatch()<any>;

  const saveTeamDirect = async (e: any) => {
    e.preventDefault();
    const company_id: string | any = localStorage.getItem(
      "@userData:companyId"
    );
    const get_type = false;
    const memberId = inputTeam;
    const result = await dispatch(
      DiscussionAction.addTeamDirect({ memberId, type: "Personal" })
    );
    if (result.status) {
      await dispatch(
        DiscussionAction.getTeamsAllPersonal({
          type: "Personal",
          company_id,
          get_type,
        })
      );
      setInputTeam("");
      handleDirectMessage();
    } else {
      handleDirectMessage();
    }
    setButtonLoader(false);
  };

  return (
    <>
      <Dialog open={openDirectMessage}>
        <form action={"#"} onSubmit={saveTeamDirect}>
          <DialogTitle>Direct Message</DialogTitle>
          <DialogContent>
            <Stack spacing={2} sx={{ width: 300 }}>
              <Autocomplete
                id="free-solo-demo"
                // calling the freeSolo prop inside the Autocomplete component

                value={inputTeam || null}
                onChange={(event: any, newValue: any | null) => {
                  setInputTeam(newValue);
                }}
                freeSolo
                options={
                  companyMembers
                    ? companyMembers.data
                        .filter((f: any) => {
                          const found = teamDirect.find(
                            (t: any) => +t.memberId === f.member_id
                          );
                          return !found;
                        })
                        .map((option: any) => option.username)
                    : [{ label: "Loading...", id: 0 }]
                }
                renderInput={(params) => (
                  <TextField {...params} label="Search User" />
                )}
              />
            </Stack>
            <br />
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDirectMessage}>Cancel</Button>
            <Button
              type="submit"
              disabled={inputTeam ? buttonLoader : true}
              className={clsx(classes.button)}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default DirectCreatPop;

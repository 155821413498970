const TaskTypes = {
  SET_PROJECT: "SET_PROJECT",
  SET_PROJECT_DETAILS: "SET_PROJECT_DETAILS",
  SET_PROJECT_STATUS: "SET_PROJECT_STATUS",
  SET_STATUS: "SET_STATUS",
  SET_PROJECT_TASK: "SET_PROJECT_TASK",
  SET_PROJECT_GANTT: "SET_PROJECT_GANTT",
  SET_ALLOCATE: "SET_ALLOCATE",
  SET_CALENDER_DATE: "SET_CALENDER_DATE"
}

export interface AddProject {
  name: string,
  description: string | null,
  startDate: string,
  endDate: string
}

export interface UpdateProject {
  projectId: string,
  name: string,
  description: string | null,
  startDate: string,
  endDate: string
}

export interface GetProject {

}

export interface GetProjectDetails {
    projectid: string,
}

export interface DeleteStatus {
  statusId: string,
}

export interface DeleteSubTask {
  subTaskId: string,
}

export interface GetProjectTasks {
  projectid: string,
  statusId: string
}

export interface GetProjectGant {
  projectid: string
}

export interface AddProjectStatus {
  name: string,
  color: string,
  projectid: string
}

export interface UpdateProjectStatus {
  name: string,
  color: string,
  statusId: string,
  projectId: String
}

export interface AddProjectTask {
  name: string,
  description: string | null,
  startDate: string,
  endDate: string,
  status: string,
  projectid: string,
  type: string | null,
  allocated: string | null,
  task_type: string | null,
  depend_task: string | null
}

export interface EditTask {
  taskId: string,
  name: string,
  description: string | null,
  startDate: string,
  endDate: string,
  status: string,
  reason: string,
  type: string | null,
  allocated: string | null,
  progress: number | null,
  task_type: string | null,
  depend_task: string | null
}

export interface SearchTeamAndMember {
  type: string,
  keyword: string | null
}

export interface GetCalenderData {
  date: string | null,
}

export interface TaskDelete {
  task_id: string,
}

export interface AddSubTask {
  subTask: string,
  startDate: string,
  endDate: string,
  taskId: string
}

export interface updateSubTask {
  subTask: string,
  startDate: string,
  endDate: string,
  taskId: string,
  subTaskId: string
}




export default TaskTypes;

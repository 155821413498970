import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, CardHeader, Divider, Grid, IconButton } from "@material-ui/core";
import {
  AttachFile,
  Link,
  BlurLinear,
  Description,
  FolderOpen,
  MoreVert,
  PlusOne,
  Add,
  Receipt, PictureAsPdf, Image, Notes
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { COLORS } from "../../utils/Colors";

import { BASE_URL, validImageTypes } from "../../utils/Constants";
import DiscussionAction from "../../stores/actions/discussionAction";
import fileImage from "../../assets/image/free-file-icon-1453-thumb.png";
import DiscussionFileFolder from "../documents/DiscussionFileFolder";
import DocumentAction from "../../stores/actions/documentAction";
import errorPng from "../../assets/image/error.png";

const useStyles = makeStyles((theme) => ({

  folderFile: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
    },
  },

  buttonUnActive : {
    backgroundColor: COLORS.basfPaleOrange,
    color: COLORS.basfOrange,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfOrange,
      color: COLORS.basfWhite,

    },
  },

  button : {
    backgroundColor: COLORS.basfOrange,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,

    },
  },

  buttonUnActiveUser : {
    backgroundColor: COLORS.hoverColor,
    color: COLORS.basfDarkBlue,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.basfDarkBlue,
      color: COLORS.basfWhite,

    },
  },

  buttonUser : {
    backgroundColor: COLORS.basfDarkBlue,
    color: COLORS.basfWhite,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 4,
    paddingRight: 4,
    "&:hover": {
      backgroundColor: COLORS.hoverColor,
      color: COLORS.basfDarkBlue,

    },
  }


}))

interface Props {
  teamId: any,
  showInput: any;
}

const MessageFiles: React.FC<Props> = ({teamId , showInput}) => {
  const dispatch = useDispatch()<any>;
  const classes = useStyles();
  const [buttonFiles , setButtonFiles] = useState(true);
  const [buttonFolders , setButtonFolders] = useState(false);
  const [folderId , setFolderId] = useState('');

  const { messageFiles } = useSelector(
    (state: RootState) => state.discussion
  );

  const { user } = useSelector(
    (state: RootState) => state.auth
  );

  // get message function
  const getMessageFilesData = useCallback(async () =>  {
    if(teamId) {
      const team_id = teamId;
      let limit = '10';
      await dispatch(DiscussionAction.getDiscussionFiles({ team_id , limit}))
      await dispatch(DocumentAction.getDiscussionDocuments({folder_id: null , teamId: team_id , keyword: null , sort: null}));
    }
  } , [dispatch , teamId])

  useEffect( () => {
    getMessageFilesData()

  } , [getMessageFilesData]);

  const handleButtonFile = async () => {
    setButtonFiles(true)
    setButtonFolders(false)
  }

  const handleButtonFolder = async  () => {
    setButtonFiles(false)
    setButtonFolders(true)
  }


  return (
    <>
      <Grid container spacing={1} style={{
        marginTop: "4%"
      }}>
        <Grid item xs={12} style={{marginLeft: "3%" , marginBottom: "3%"}}>
          <Button className={clsx(buttonFiles ? (user && user.role === 'User' ? classes.buttonUser : classes.button) : (user.role === 'User' ? classes.buttonUnActiveUser : classes.buttonUnActive))} style={{marginRight: "2%"}} onClick={() => handleButtonFile()}>
            Files
          </Button>
          <Button className={clsx(buttonFolders ? (user && user.role === 'User' ? classes.buttonUser : classes.button) : (user.role === 'User' ? classes.buttonUnActiveUser : classes.buttonUnActive))} onClick={() => handleButtonFolder()}>
            Documents
          </Button>
        </Grid>
        {buttonFiles && messageFiles && messageFiles.data.map((folder: any, index: any) => {
          let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.created_at}</Moment>
          let id = folder.id;
          let ext = folder.files ? folder.files.split('.').pop() : '';
          let file_name = validImageTypes.includes(ext) ? BASE_URL+''+folder.files :  fileImage;
          let icon = folder.type === 'FILE' &&
          ext === 'sql' ? <Receipt style={{color: COLORS.basfDarkBlue}}/> :
            ext === 'pdf' ? <PictureAsPdf style={{color: COLORS.basfRed}}/>  :
              ext === 'jpg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                ext === 'png' ?  <Image style={{color: COLORS.basfOrange}}/> :
                  ext === 'jpeg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                    ext === 'svg' ?  <Image style={{color: COLORS.basfOrange}}/> :
                      ext === 'link' ? <Link style={{color: COLORS.basfDarkBlue}}/> :
                        ext === 'txt' ? <Notes style={{color: COLORS.basfDarkBlue}}/> :
                          ext === 'docx' ? <Description style={{color: COLORS.basfDarkBlue}} /> :
                            ext === 'xlsx' ? <Description style={{color: COLORS.basfDarkGreen}}/> :
                              <Description/>;

          return (
            <Grid item xs={12} sm={12} key={index}>
              <a href={BASE_URL+''+folder.files} target={"_blank"} style={{textDecoration: "none"}} >
                <Card key={index} style={{width: "100%", marginBottom: 4}} className={clsx(classes.folderFile)}>
                  <CardHeader
                    avatar={
                      icon
                    }
                    title={folder.file_name}
                    subheader={time}
                  />
                </Card>
              </a>
            </Grid>
          )
        })}
        {buttonFolders &&
          <Grid item xs={11} style={{marginLeft:"3%"}}>
            <DiscussionFileFolder setFolderId={setFolderId}  folderId={folderId}  teamId={teamId} showInput={showInput}/>
          </Grid>
        }
      </Grid>
      {buttonFiles && messageFiles && messageFiles.data.length <= 0 &&
        <>
          <div style={{textAlign:"center" ,  maxWidth: "100%"}}>
            {/*<ChatBubble style={{color:COLORS.basfDarkBlue , fontSize:"24px" , marginTop: "13%" }}/>*/}
            <img src={errorPng} style={{marginTop: "5%" }} width={"20%"}/>
            <h2 style={{color: COLORS.basfLightBlue}}>Sorry files not found</h2>
          </div>
        </>
      }
    </>
  )
}

export default MessageFiles;

import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider, FormControl,
  IconButton, InputLabel,
  Menu,
  MenuItem, Select, TextField, Tooltip
} from "@material-ui/core";
import { Chat, Delete, Edit, MoreVert, Replay, VerifiedUser } from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { decode } from "js-base64";
import SettingAction from "../../stores/actions/settingAction";
import { useDispatch } from "react-redux";
import TaskAction from "../../stores/actions/taskAction";


const useStyles = makeStyles((theme) => ({

  iconStyle: {
    fontSize: 13 ,
    marginRight: 5
  },
}))

interface Props{
  projectDetails: any,

}

const TaskProjectPop: React.FC<Props> = ({projectDetails}) => {
  const classes = useStyles();
  const [openDialog , setOpenDialog] = useState(false);
  const dispatch = useDispatch()<any>;
  const [openUpdateProject, setOpenUpdateProject] = useState(false);
  const [projectNameEdit, setProjectNameEdit] = useState('');
  const [projectDescriptionEdit, setProjectDescriptionEdit] = useState('');
  const [projectStartDateEdit, setProjectStartDateEdit] = useState('');
  const [projectEndDateEdit, setProjectEndDateEdit] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);



  // Process using for get task projects
  const getTaskProjects = useCallback( async () => {
    await dispatch(TaskAction.getProject({}))
  } , [dispatch])

  const deleteProject = async (e: any) => {
    const result = await dispatch(TaskAction.deleteTaskProject({projectid: projectDetails.id}))
    if(result && result.status) {
      getTaskProjects();
      setOpenDialog(!openDialog);
    }
  }

  const handleDeleteProject = async (e: any) => {
    if(e.close){
      e.close()
    }
    setOpenDialog(!openDialog);
  }

  const handleUpdateProject = async (e: any) => {
    if(e.close){
      e.close()
    }
    setProjectNameEdit(projectDetails.project_name)
    setProjectStartDateEdit(projectDetails.start_date)
    setProjectEndDateEdit(projectDetails.end_date)
    setProjectDescriptionEdit(projectDetails.description)
    setOpenUpdateProject(!openUpdateProject);

  }

  const updateProject = async (e: any) => {
    const result = await dispatch(TaskAction.updateProject({projectId: projectDetails.id , name: projectNameEdit , description: projectDescriptionEdit, startDate: projectStartDateEdit , endDate: projectEndDateEdit}))
    if(result && result.status) {
      getTaskProjects();
      setOpenUpdateProject(!openUpdateProject)
    }
    setButtonLoader(false)

  }

  const day = new Date().getDate();
  const month = (new Date().getMonth() + 1);

  const dateNow = new Date().getFullYear()+'-'+ (month > 9 ? month : '0'+ month)   + '-' + (day > 9 ? day : '0'+ day);




  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={"More"}>
              <IconButton aria-label="settings" {...bindTrigger(popupState)}>
                <MoreVert  />
              </IconButton>
            </Tooltip>
            <Menu {...bindMenu(popupState)} style={{marginTop: 46, marginLeft: "-2%"}}>
              <MenuItem  onClick={() => handleDeleteProject(popupState)} style={{ fontSize: 13}}><Delete className={clsx(classes.iconStyle)}/> Delete</MenuItem>
              <MenuItem onClick={() => handleUpdateProject(popupState)} style={{ fontSize: 13}}><Edit className={clsx(classes.iconStyle)}/>Edit</MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      <Dialog
        open={openDialog}
        keepMounted
        onClose={() => setOpenDialog(!openDialog)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Project?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure to delete the project ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(!openDialog)}>Cancel</Button>
          <Button onClick={deleteProject}>Confirm</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openUpdateProject} onClose={() => setOpenUpdateProject(!openUpdateProject)} fullWidth={true}>
        <form action={"#"} onSubmit={updateProject}>
          <DialogTitle>Update Project</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              value={projectNameEdit}
              type="text"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectNameEdit(text.target.value)}
            />
            <br/>
            <br/>
            <TextField
              margin="dense"
              id="startdate"
              label="Start Date"
              value={projectStartDateEdit ? projectStartDateEdit : dateNow}
              type="date"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectStartDateEdit(text.target.value)}
            />
            <br/>
            <br/>
            <TextField
              margin="dense"
              id="enddate"
              label="End Date"
              value={projectEndDateEdit ? projectEndDateEdit : dateNow}
              type="date"
              fullWidth
              required={true}
              variant="outlined"
              onChange={(text) => setProjectEndDateEdit(text.target.value)}
            />

            <br/>
            <TextField
              multiline={true}
              margin="dense"
              id="name"
              rows={4}
              label="Description"
              type="textarea"
              fullWidth
              variant="outlined"
              value={projectDescriptionEdit}
              onChange={(text) => setProjectDescriptionEdit(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpdateProject(!openUpdateProject)}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Update</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default TaskProjectPop;

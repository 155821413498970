import DocumentTypes from "../types/documentType";

interface DocumentActions {
  type: string,
  payload?: any,
}


const initial_state = {
  folderList: null,
  folderListCustomer: null,
  discussionFiles: null

}

const DocumentReducer = (state = initial_state , action: DocumentActions) => {
  switch (action.type) {

    case DocumentTypes.SET_FOLDER:
      return {
        ...state,
        folderList: action.payload
      }

    case DocumentTypes.SET_FOLDER_CUSTOMER:
      return {
        ...state,
        folderListCustomer: action.payload
      }

    case DocumentTypes.SET_DISCUSSION_FOLDER:
      return {
        ...state,
        discussionFiles: action.payload
      }



    default:
      return state
  }
}

export default DocumentReducer;



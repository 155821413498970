import React, {useState} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControl, FormControlLabel,
  FormLabel, Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import DiscussionAction from "../../stores/actions/discussionAction";

interface Props {

  openAddTeam: boolean;
  handleAddTeam: any;
}

const AddTeamPopover: React.FC<Props> = ({openAddTeam , handleAddTeam }) => {

  const [inputTeam, setInputTeam] = useState("");
  const [teamType , setTeamType] = useState("Public");
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;

  const saveTeam = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true)
    const type = teamType;
    const name = inputTeam;
    const company_id: string | any = localStorage.getItem("@userData:companyId");
    const get_type = false;
    const result = await dispatch(DiscussionAction.addTeam({name , type}))
    await dispatch(DiscussionAction.getTeamsAll({type : 'Team' , company_id , get_type }))
    setButtonLoader(false)
    setInputTeam('')
    handleAddTeam();
  }

  const setRolesHandle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTeamType(event.target.value);
  };


  return(
    <>

      <Dialog open={openAddTeam} >
        <form action={"#"} onSubmit={saveTeam}>
        <DialogTitle>New Team</DialogTitle>
        <DialogContent>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Team Name"
            type="text"
            fullWidth
            variant="standard"
            value={inputTeam}
            onChange={(text) => setInputTeam(text.target.value)}
          />
          <br/>
          <br/>
          <FormControl>
            <FormLabel id="select-team-type">
              Team Type
            </FormLabel>
            <br/>

            <RadioGroup
              row
              aria-labelledby="select-team-type"
              name="row-radio-buttons-group"
              defaultValue={"Public"}
            >
              <FormControlLabel
                value="Public"
                control={<Radio onChange={setRolesHandle} />}
                label="Public"
              />
              <FormControlLabel
                value="Private"
                control={<Radio onChange={setRolesHandle} />}
                label="Private"
              />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddTeam} >Cancel</Button>
          <Button type="submit" disabled={buttonLoader}>Create</Button>
        </DialogActions>
        </form>
      </Dialog>


    </>
  );

}

export default AddTeamPopover

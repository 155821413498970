import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Assets
import illustration from "../../assets/image/auth.png";
import clsx from "clsx";
// Default Design
import Default from "../../layouts/auth/Default";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import { RootState } from "../../stores";
import AuthActions from "../../stores/actions/authAction";

//Style add
import { Wrapper } from "./SignIn.styles";

import { Link, useNavigate, useParams } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { REGEX } from "../../utils/Constants";
import SettingAction from "../../stores/actions/settingAction";

const Invited = () => {
  const history = useNavigate();
  const dispatch = useDispatch()<any>;
  const { user, authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const sLoading = useSelector((state: RootState) => state.loading);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [company, setCompany] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [values, setValues] = useState({ password: "", showPassword: false });
  const [buttonLoader, setButtonLoader] = useState(sLoading.show);
  const [usernameExist, setUsernameExist] = useState("");
  const [stepOne, setStepOne] = useState(true);
  const regex = REGEX;
  const { invitedId } = useParams();

  const getInvitedMember = useCallback(async () => {

    setButtonLoader(true)

    const inviteId = invitedId ? invitedId : '' ;
    const result = await dispatch(SettingAction.getInviteMember({inviteId}));

    if(result.status) {
        setEmail(result.data[0].email)
        setCompany(result.data[0].company_name)
        setCompanyId(result.data[0].company_id)
    }

    setButtonLoader(false)
  } , [dispatch])

  useEffect(() => {
    const tokens = localStorage.getItem("@userData:token");
    if ((authenticated && token) || tokens) {
      // [ROUTE] Go to dashboard page
      history("/home");
    }

    getInvitedMember();
    setRole("User")
  }, [authenticated, history , getInvitedMember ]);

  // submit data to api
  const handleSignUp = async (e: any) => {
    e.preventDefault();
    setButtonLoader(sLoading.show);
    const invitaionId = invitedId ? invitedId : '' ;
    await dispatch(AuthActions.signUpInvited({ username, email, password, role , invitaionId }));
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };


  // Process use for check username already exist or not in database
  const checkUsernameExistOrNot = async (event: any) => {
    event.preventDefault();
    const checkUsername = await dispatch(AuthActions.checkUser({ username }));
    if (checkUsername && !checkUsername.data.status) {
      setUsernameExist("Username already taken !!");
    } else {
      setUsernameExist("");
    }
  };


  return (
    <Wrapper>
      <Default
        illustrationBackground={illustration}
        image={illustration}
        heading="Create new account with email"
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: "25vh" }}
        >
          <form action={"#"} onSubmit={handleSignUp}>
            <Paper elevation={3} className="paper">
              {stepOne && (
                <>
                  {usernameExist && (
                    <p style={{ color: "red" }}>{usernameExist}</p>
                  )}
                  <FormControl
                    className="textField margin"
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-username">
                      Enter Username
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-username"
                      value={username}
                      required={true}
                      label="Enter Username"
                      error={username.length ? false : true}
                      onChange={(text) => setUsername(text.target.value)}
                      onBlur={checkUsernameExistOrNot}
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="Enter Username"
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </FormControl>

                  <br />
                  <br />
                  <FormControl
                    className="textField margin"
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Enter Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={password}
                      required={true}
                      label={"Enter Password"}
                      error={password.length ? false : true}
                      onChange={(text) => setPassword(text.target.value)}
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="Enter Password"
                      inputProps={{ style: { textAlign: "left" } }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <br />
                  <br />
                  <FormControl
                    className="textField margin"
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-email">
                      Enter Email
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      value={email}
                      readOnly={true}
                      label={"Enter Email"}
                      required={true}
                      error={email.length ? false : true}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <FormControl
                    className="textField margin"
                    variant="outlined"
                    size="small"
                  >
                    <InputLabel htmlFor="outlined-adornment-company">
                      Company
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-company"
                      value={company}
                      readOnly={true}
                      label={"Company"}
                      required={true}
                      error={company.length ? false : true}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{ style: { textAlign: "left" } }}
                    />
                  </FormControl>
                  <br/>
                  <br/>
                  <Button
                    variant="contained"
                    className="loginButton"
                    type="submit"
                    disabled={buttonLoader}
                  >
                    Continue
                  </Button>
                </>
              )}

              <br />
              <br />
              <Link
                to={"/"}
                style={{ color: "#586EF5", textDecoration: "none" }}
              >
                Login ?
              </Link>
              <br />
            </Paper>
          </form>
        </Grid>
      </Default>
    </Wrapper>
  );
};

export default Invited;

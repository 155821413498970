import React from "react";
import {
  CalendarTodayOutlined,
  CheckBoxOutlined,
  Folder,
  HomeOutlined,
  NoteAdd,
  RecentActors,
} from "@material-ui/icons";

interface MenuItem {
  id: number;
  label: string;
  pathname: string;
  link: string;
  icon: any;
  visible: boolean;
}

const style = {
  fontSize: "20px",
  marginLeft: 5,
};

const routes: Array<MenuItem> = [
  {
    id: 0,
    label: "Joining Requests",
    pathname: "/home/joining-requests",
    link: "/home/joining-requests",
    icon: <HomeOutlined style={style} />,
    visible: true,
  },
  {
    id: 1,
    label: "Projects",
    pathname: "/home/project",
    link: "/home/project",
    icon: <HomeOutlined style={style} />,
    visible: true,
  },
  {
    id: 2,
    label: "Initiative",
    pathname: "/home/initiatives",
    link: "/home/initiatives",
    icon: <NoteAdd style={style} />,
    visible: true,
  },
  {
    id: 3,
    label: "Library",
    pathname: "/home/library",
    link: "/home/library",
    icon: <Folder style={style} />,
    visible: true,
  },
  {
    id: 4,
    label: "Customer Library",
    pathname: "/home/customer-library",
    link: "/home/customer-library",
    icon: <RecentActors style={style} />,
    visible: true,
  },
  {
    id: 5,
    label: "Calender",
    pathname: "/home/calender",
    link: "/home/calender",
    icon: <CalendarTodayOutlined style={style} />,
    visible: true,
  },
  {
    id: 6,
    label: "Task",
    pathname: "/home/tasks",
    link: "/home/tasks",
    icon: <CheckBoxOutlined style={style} />,
    visible: true,
  },
];

export default routes;

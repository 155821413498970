import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/Colors";
import {
  AppBar,
  Avatar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import {
  AccountCircle,
  ExitToApp
} from "@material-ui/icons";
import { User } from "../../stores/types/authTypes";
import { BASE_URL } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AuthAction from "../../stores/actions/authAction";
import PageHeader from "./PageHeader";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "white",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
}));

interface Props {
  open: boolean;
  handleDrawerOpen: any;
  logoutHandle: any;
  heading: any;
  icon: any;
}

const Header: React.FC<Props> = ({
  open,
  handleDrawerOpen,
  logoutHandle,
  heading, icon
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openProfile, setOpenProfile] = useState(false);
  const location = useLocation();
  const pathUrl = location.pathname;
  const classes = useStyles();
  const dispatch = useDispatch()<any>;
  const handleDrawer = () => {
    handleDrawerOpen(open);
  };


  const handleOpen = () => {
    setOpenProfile(!openProfile);
  };

  const { userData  } = useSelector(
    (state: RootState) => state.auth
  );

  const getProfileData = useCallback( async () => {
    await dispatch(AuthAction.getProfileData({}))

  } , [dispatch])

  useEffect( () => {
    getProfileData()
  } , [getProfileData])



  return (
    <div>
      <HeaderStyle>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            {/*<Typography variant="h6" noWrap className={classes.title}>*/}

            {/*</Typography>*/}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawer}
            >
              <MenuIcon style={{ color: COLORS.primary }} />
            </IconButton>
          <Box>
            <PageHeader title={heading} open={open} icon={icon}></PageHeader>
          </Box>

            <Box style={{ flexGrow: 1 }} />
            <Box  style={{flexGrow: 0}}>
              <Tooltip title="Account settings">
              <IconButton
                size="small"
                aria-controls={openProfile ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProfile ? "true" : undefined}
                onClick={handleOpen}
              >
                  <Avatar
                    style={{
                      width: 32,
                      height: 32,
                      textAlign: "right",
                      float: "right",
                    }}
                    alt={userData ? userData.data.username : ""}
                    src={userData ? BASE_URL+''+userData.data.profile : ''}
                  >
                  </Avatar>
              </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={openProfile}
                onClose={handleOpen}
                onClick={handleOpen}
                transformOrigin={{ horizontal: "right", vertical: "bottom" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                style={{
                  marginTop: "3%"
                }}
              >
                <MenuItem>
                  <Link to={"../profile"} style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" , display:"flex"}}>
                    <ListItemIcon>
                      <AccountCircle fontSize="small" />
                    </ListItemIcon>
                      Profile

                  </Link>
                </MenuItem>
                <Divider />
                {/*<MenuItem>*/}
                {/*  <ListItemIcon>*/}
                {/*    <Settings fontSize="small" />*/}
                {/*  </ListItemIcon>*/}
                {/*  Settings*/}
                {/*</MenuItem>*/}
                <MenuItem onClick={logoutHandle}>
                  <ListItemIcon>
                    <ExitToApp fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
      </HeaderStyle>

    </div>
  );
};

export default Header;

const HeaderStyle = styled.div`
  display: flex;

  .appBar {
    background-color: white;
    transition-duration: 1s;
  }

  .appBarShift {
    margin-right: ${drawerWidth};
    width: calc(100% - ${drawerWidth}px);
    transition-duration: 1s;
  }

  .title {
    flex-grow: 1px;
  }

  .hide {
    display: none;
  }
`;

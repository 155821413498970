import React, { useState } from "react";
import {
  Button,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel, Radio,
  RadioGroup,
  TextField
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import SettingAction from "../../stores/actions/settingAction";


interface Props {
  openCompanyAdd: any;
  handleAddCompany: any;
}


const AddCompany: React.FC<Props> = ({openCompanyAdd , handleAddCompany}) => {
  const [inputCompany, setInputCompany] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;

  const saveCompany = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true)
    const name = inputCompany;
    const result = await dispatch(SettingAction.addCompany({name , users: []}))

    if(result && result.status) {
      await dispatch(SettingAction.getCompanyData({}))
      setInputCompany('')
      handleAddCompany()
    }
    setButtonLoader(false)
  }


  return (
    <>
      {openCompanyAdd &&
      <Dialog open={openCompanyAdd}
              keepMounted
              fullWidth={true}
              maxWidth={"xs"}
              onClose={() => handleAddCompany()}
      >
        <form action={"#"} onSubmit={saveCompany}>
          <DialogTitle>New Project</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              type="text"
              fullWidth
              variant="standard"
              value={inputCompany}
              onChange={(text) => setInputCompany(text.target.value)}
            />
            <br/>
            <br/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddCompany} >Cancel</Button>
            <Button type="submit" disabled={buttonLoader}>Create</Button>
          </DialogActions>
        </form>
      </Dialog>
      }
    </>
  )
}

export default AddCompany;

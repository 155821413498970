import React, {useState} from "react";
import {IconButton} from "@material-ui/core";
import { Close, Replay, Reply } from "@material-ui/icons";
import DiscussionInput from "./DiscussionInput";
import Grid from "@material-ui/core/Grid";


interface Props  {
    messageId: any | null;
    teamId: any | null;
    scrollHandle: any;
}

const ReplySection:React.FC<Props> = ({messageId , teamId , scrollHandle}) => {

    const [openReplyInput, setOpenReplyInput] = useState(false);
    const [openReplyButton, setOpenReplyButton] = useState(true);
    const [closeReplyButton, setCloseReplyButton] = useState(false);

    const handleReplyButton = () => {
        setOpenReplyInput(true)
        setOpenReplyButton(false)
        setCloseReplyButton(true)
    }

    const handleReplyButtonClose = () => {
        setOpenReplyInput(false)
        setOpenReplyButton(true)
        setCloseReplyButton(false)
    }

    return (
        <Grid container spacing={2} style={{marginLeft: "5%"}}>
            {openReplyButton &&
                <span aria-label="Reply" title="Reply" onClick={handleReplyButton} style={{display: "flex" , fontSize: "14px", marginTop: "10px"}}>
                    <Reply/> Reply
                </span>
            }
            <Grid item lg={10} md={10} sm={10} xs={10} xl={10} >
            <DiscussionInput openInput={openReplyInput} messageType={"SubMessage"} parentId={messageId} styleInput={{width: "100%", }} teamId={teamId} scrollHandle={scrollHandle}></DiscussionInput>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} xl={2} >
            {closeReplyButton &&
              <IconButton title="Close" onClick={handleReplyButtonClose}>
                  <Close />
              </IconButton>
            }
            </Grid>
        </Grid>
    )
}
export default ReplySection;

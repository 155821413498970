import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  Chat,
  Delete,
  Edit,
  MoreVert,
  Replay,
  VerifiedUser,
} from "@material-ui/icons";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { decode } from "js-base64";
import SettingAction from "../../stores/actions/settingAction";
import { useDispatch } from "react-redux";

interface Props {
  companyId: any;
  isDefault: any;
  companyName: any;
}

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13,
    marginRight: 5,
  },
}));

const CompanySidePop: React.FC<Props> = ({
  companyId,
  isDefault,
  companyName,
}) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogAddMem, setOpenDialogAddMem] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;
  const [memberEmail, setMemberEmail] = useState("");
  const [openCompanyEdit, setOpenCompanyEdit] = useState(false);
  const [inputCompanyEdit, setInputCompanyEdit] = useState("");

  const getCompanyList = useCallback(async () => {
    await dispatch(SettingAction.getCompanyData({}));
  }, [dispatch]);

  const deleteCompany = async (e: any) => {
    const company_id = companyId;
    const result = await dispatch(SettingAction.deleteCompany({ company_id }));
    if (result && result.status) {
      await dispatch(SettingAction.getCompanyData({}));
    }
  };

  const handleDeleteCompany = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setOpenDialog(!openDialog);
  };

  const handleEditCompany = async (e: any, name: string) => {
    if (e.close) {
      e.close();
    }
    setInputCompanyEdit(name);
    setOpenCompanyEdit(!openCompanyEdit);
  };

  const handleCompanyAddMember = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setOpenDialogAddMem(!openDialogAddMem);
  };

  const addMember = async (e: any) => {
    setButtonLoader(true);
    const email = memberEmail;
    const result = await dispatch(
      SettingAction.inviteCompanyMember({ companyId, email })
    );
    if (result.status) {
      setMemberEmail("");
      setOpenDialogAddMem(!openDialogAddMem);
      getCompanyList();
    }
    setButtonLoader(false);
  };

  const updateCompany = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    const name = inputCompanyEdit;
    const result = await dispatch(
      SettingAction.editCompany({ name, id: companyId })
    );
    if (result && result.status) {
      await dispatch(SettingAction.getCompanyData({}));
      const company_id: string | any = localStorage.getItem(
        "@userData:companyId"
      );

      if (company_id) {
        await dispatch(SettingAction.getCompanyDetails({ company_id }));
      }

      setInputCompanyEdit("");
      setOpenCompanyEdit(!openCompanyEdit);
    }
    setButtonLoader(false);
  };

  return (
    <>
      <PopupState variant="popover" popupId="demoMenu">
        {(popupState) => (
          <React.Fragment>
            <Tooltip title={"More"}>
              <IconButton aria-label="settings" {...bindTrigger(popupState)}>
                <MoreVert />
              </IconButton>
            </Tooltip>
            <Menu
              {...bindMenu(popupState)}
              style={{ marginTop: 46, marginLeft: "-2%" }}
            >
              <MenuItem
                onClick={() => handleCompanyAddMember(popupState)}
                style={{ fontSize: 13 }}
              >
                <VerifiedUser className={clsx(classes.iconStyle)} />
                Add Member
              </MenuItem>
              <MenuItem
                onClick={() => handleEditCompany(popupState, companyName)}
                style={{ fontSize: 13 }}
              >
                <Edit className={clsx(classes.iconStyle)} />
                Edit
              </MenuItem>
              {!isDefault && (
                <MenuItem
                  onClick={() => handleDeleteCompany(popupState)}
                  style={{ fontSize: 13 }}
                >
                  <Delete className={clsx(classes.iconStyle)} /> Delete
                </MenuItem>
              )}
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
      {openDialog && (
        <Dialog
          open={openDialog}
          keepMounted
          onClose={() => setOpenDialog(!openDialog)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Delete Company?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure to delete the company ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(!openDialog)}>Cancel</Button>
            <Button onClick={deleteCompany}>Confirm</Button>
          </DialogActions>
        </Dialog>
      )}
      {openDialogAddMem && (
        <Dialog
          open={openDialogAddMem}
          keepMounted
          onClose={() => handleCompanyAddMember("")}
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth={"xs"}
        >
          <DialogTitle>{"Invite Member?"}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              id="companyMember"
              label="Member Email"
              value={memberEmail}
              type="email"
              fullWidth
              variant="standard"
              onChange={(text) => setMemberEmail(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCompanyAddMember("")}>Cancel</Button>
            <Button
              type="button"
              disabled={buttonLoader}
              onClick={() => addMember("")}
            >
              Invite
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openCompanyEdit && (
        <Dialog
          open={openCompanyEdit}
          keepMounted
          fullWidth={true}
          maxWidth={"xs"}
          onClose={() => handleEditCompany("", "")}
        >
          <form action={"#"} onSubmit={updateCompany}>
            <DialogTitle>Update Project</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Project Name"
                type="text"
                fullWidth
                variant="standard"
                value={inputCompanyEdit}
                onChange={(text) => setInputCompanyEdit(text.target.value)}
              />
              <br />
              <br />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleEditCompany("", "")}>Cancel</Button>
              <Button type="submit" disabled={buttonLoader}>
                Update
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
};

export default CompanySidePop;

import React, {useEffect, useRef, useState} from "react";
import {ListItemIcon, Menu, MenuItem} from "@material-ui/core";
import {Computer, FileCopy, Folder} from "@material-ui/icons";
import LinkAddPopover from "./LinkAddPopover";
import FolderAddPopover from "./FolderAddPopover";
import { useDispatch } from "react-redux";
import DocumentAction from "../../stores/actions/documentAction";
import { COLORS } from "../../utils/Colors";

interface Props {
    anchorEl: any;
    openPop: boolean;
    openAddPopup: any;
    folderId: any
    setSavedDocument: any,
    saveDocument: any
}

const MenuPopover: React.FC<Props> = ({anchorEl , openPop , openAddPopup  , folderId , setSavedDocument , saveDocument}) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogLink, setOpenDialogLink] = useState(false);
    const input = React.useRef<HTMLInputElement>(null)
    const dispatch = useDispatch()<any>;

    const handleDialog = () => {
        setOpenDialog(!openDialog);
    };

    const handleDialogLink = () => {
        setOpenDialogLink(!openDialogLink);
    };

    const onChangeAttachment = async (event: any) => {
        event.preventDefault();
        const attachment = event.target.files[0];
        const folder_id = folderId;
        const result     = await dispatch(DocumentAction.addDocuments({attachment , folder_id}));
        if (result && result.status) {
            setSavedDocument(!saveDocument)
            event.target.value = null;
        }

    }

    return (
      <>
          <input
            type="file"
            hidden
            name={"image"}
            ref={input}
            onChange={onChangeAttachment}

          />
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openPop}
            onClose={openAddPopup}
            onClick={openAddPopup}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            style={{left:"36%" , top: "70%", minHeight:" 250px"}}
          >
              <MenuItem  onClick={() => input.current && input.current.click()}>
                  <ListItemIcon>
                      <Computer fontSize="small" style={{color: COLORS.basfDarkBlue}}/>
                  </ListItemIcon>
                  Import From Computer
              </MenuItem>
              <MenuItem onClick={handleDialogLink}>
                  <ListItemIcon>
                      <FileCopy fontSize="small" style={{color: COLORS.basfLightBlue}}/>
                  </ListItemIcon>
                  New File
              </MenuItem>
              <MenuItem onClick={handleDialog}>
                  <ListItemIcon>
                      <Folder fontSize="small" style={{color:COLORS.basfOrange}}/>
                  </ListItemIcon>
                  New Folder
              </MenuItem>
          </Menu>
          <LinkAddPopover
            openDialogLink={openDialogLink}
            handleDialogLink={handleDialogLink}
            folderId={folderId}
            setSavedDocument={setSavedDocument}
            saveDocument={saveDocument}
          ></LinkAddPopover>

          <FolderAddPopover
            openDialog={openDialog}
            handleDialog={handleDialog}
            folderId={folderId}
            setSavedDocument={setSavedDocument}
            saveDocument={saveDocument}
          ></FolderAddPopover>
      </>
    );
}

export default MenuPopover;

const DocumentTypes = {
  SET_FOLDER: "SET_FOLDER",
  SET_FOLDER_CUSTOMER: "SET_FOLDER_CUSTOMER",
  SET_DISCUSSION_FOLDER: "SET_DISCUSSION_FOLDER",
}


export interface AddFolder {
  name: string,
  folder_id: string | null
}

export interface UpdateFolder {
  name: string,
  id: string,
  folder_id: string | null
}

export interface AddLink {
  name: string,
  link: string,
  folder_id: string | null
}


export interface AddDocument {
  attachment: string,
  folder_id: string | null
}

export interface GetDocument {
  folder_id: string | null,
  teamId: string | null,
  keyword: string | null,
  sort: string | null
}

export interface ConfigDoc {
  folder_id: string | null,
  teamId: string | null
}

export interface RemoveConfig {
  config_id: string
}

export interface RemoveFolder {
  document_id: string
}




export default DocumentTypes;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import DiscussionAction from "../../stores/actions/discussionAction";


export interface Props {
  popupMeeting: any,
  handleMeetingPop: any,
  teamId: any,
  parentId: any,
  scrollHandle: any
}


const CreateMeeting: React.FC<Props> = ({popupMeeting , handleMeetingPop , teamId , parentId , scrollHandle}) => {

  const [meetingTopic , setMeetingTopic] = useState('');
  const [meetingDate , setMeetingDate] = useState('');
  const [meetingAgenda , setMeetingAgenda] = useState('');
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;

  const saveFolder = async (event: any) => {
    event.preventDefault();
    const team_id = teamId;
    const parent_message_id = parentId;
    const topic = meetingTopic;
    const start_time = meetingDate;
    const agenda  = meetingAgenda;
    let limit = '10';
    const result = await dispatch(DiscussionAction.createMeetingMessage({team_id , parent_message_id , topic , start_time, agenda}));
    if(result.data.status) {
      setMeetingTopic('')
      setMeetingDate('');
      setMeetingAgenda('')
      scrollHandle()
      handleMeetingPop(!popupMeeting)
      await dispatch(DiscussionAction.getMessage({ team_id , limit }))
    }
  }

  const day = new Date().getDate();
  const month = (new Date().getMonth() + 1);

  const dateNow = new Date().getFullYear()+'-'+ (month > 9 ? month : '0'+ month)   + '-' + (day > 9 ? day : '0'+ day);

  return (
    <>
      <Dialog open={popupMeeting} onClose={handleMeetingPop} fullWidth={true} maxWidth={'xs'}>
        <form action={'#'} onSubmit={saveFolder}>
          <DialogTitle>Add Meeting</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Topic"
              type="text"
              fullWidth
              variant="standard"
              required={true}
              value={meetingTopic}
              onChange={(text) => setMeetingTopic(text.target.value)}
            />
            <br/><br/>

              <TextField
                InputProps={{inputProps: { min: dateNow}}}
                margin="dense"
                id="name"
                label="Start Date"
                type="date"
                fullWidth
                required={true}
                variant="standard"
                value={meetingDate ? meetingDate : setMeetingDate(dateNow)}
                onChange={(text) => setMeetingDate(text.target.value)}
              />

            <br/><br/>

            <TextField
              margin="dense"
              id="name"
              label="Agenda"
              type="text"
              fullWidth
              variant="standard"
              value={meetingAgenda}
              onChange={(text) => setMeetingAgenda(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleMeetingPop}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )

}

export default CreateMeeting;

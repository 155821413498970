import React, { useCallback, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton
} from "@material-ui/core";
import clsx from "clsx";
import Moment from "react-moment";
import { Description, FolderOpen, Link, MoreVert } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../utils/Colors";
import { useDispatch, useSelector } from "react-redux";
import DocumentAction from "../../stores/actions/documentAction";
import { RootState } from "../../stores";

const useStyles = makeStyles((theme) => ({

    iconStyle: {
        fontSize: 13 ,
        marginRight: 5
    },

    folderFile: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: COLORS.hoverColorSecond,
            boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
        },
    },

    button : {
        backgroundColor: COLORS.basfLightBlue,
        color: COLORS.white,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 4,
        paddingRight: 4,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: COLORS.hoverColorSecond,

        },
    },

    shareButton : {
        backgroundColor: COLORS.basfLightBlue,
        color: COLORS.white,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: COLORS.hoverColorSecond,

        },
    }

}))


interface Propos {
 teamId: any,
 showFolderFiles: any,
 setShowFolderFiles: any,
}

const ConfigFileFolder: React.FC<Propos> = ({teamId , showFolderFiles , setShowFolderFiles}) => {
    const [stateDocument , setStateDocument] = useState(false);
    const classes = useStyles();
    const dispatch = useDispatch()<any>;
    const [folders, setFolders] = useState([{id: '' , name: ''}]);
    const [folderId , setFolderId] = useState('');
    const { folderList  } = useSelector(
      (state: RootState) => state.documents
    );

    // fucntion use for get file and folders
    const getFileFolders = useCallback( async (event: any | null) => {
        const result = dispatch(DocumentAction.getDocuments({folder_id: event , teamId: null , keyword: null , sort: null}));
    }, [dispatch])


    const openConfigPop = () => {
        setStateDocument(!stateDocument)
    }

    const handleConfigFFClose = (e: any = null) => {
        setStateDocument(!stateDocument)
        getFileFolders('')
        setFolders([{ id: '', name: '' }]);
    }

    useEffect(() => {
            getFileFolders('')
    }, [getFileFolders])


    const clickFolder = async (id: any , name: any) => {
        let newData = {id: id, name: name}
        setFolders([...folders , newData]);
        getFileFolders(id)
        setFolderId(id);
    }

    const backClick = async (id: any) => {
        if(folders.length > 0) {
            let newArray = [];
            for (const foldIs of folders) {
                if(foldIs && foldIs.id === id){
                    newArray.push({id: foldIs.id, name: foldIs.name});
                    break;
                }
                else{
                    newArray.push({id: foldIs.id, name: foldIs.name})
                    setFolders(newArray);
                }
            }
            setFolderId(id)
        }
        if(id === '') {
            setFolders([{ id: '', name: '' }]);
            setFolderId('')
        }
        // setFolderId(id);
        getFileFolders(id)
    }


    const configFolder = async () => {
        const result = await dispatch(DocumentAction.configDocument({folder_id: folderId , teamId: teamId}))
        if (result.status) {
            await dispatch(DocumentAction.getDocuments({folder_id: null , teamId: teamId , keyword: null , sort: null}));
            setStateDocument(!stateDocument)
            setShowFolderFiles(!showFolderFiles)
        }
    }


    return (
      <>
          <div style={{alignItems: "center" ,  maxWidth: "100%" , textAlign: "center" , margin: "10%"}}>
              <Button className={clsx(classes.button)} style={{padding: 10, fontSize: "16px"}} onClick={() => openConfigPop()}>Choose Folder</Button>
          </div>
          {stateDocument &&
          <Dialog open={stateDocument} onClose={handleConfigFFClose} fullWidth={true} maxWidth={"xs"}>
              <DialogTitle>Documents</DialogTitle>
              <DialogContent style={{height: "350px", overflow: "scroll"}}>
                  <Grid container spacing={2}>
                      <Grid item xs={12}>
                          <>
                              <a className={clsx(classes.button)} onClick={() => backClick('')}>Main</a>
                              {folders && folders.map((fold: any, ind: any) => {
                                  if(fold.id) {
                                      return (
                                        <a className={clsx(classes.button)} onClick={() => backClick(fold.id)} style={{marginLeft: "1%"}} key={ind}> {fold.name}</a>
                                      )
                                  }
                              })}
                          </>
                      </Grid>
                      <Grid item xs={12}>
                          <h4>Folder</h4>
                          {folderList && folderList.data.folder.map((folder: any, index: any) => {
                              let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.updated_at}</Moment>
                              let id = folder.id;
                              let name = folder.name;
                              return (
                                <>
                                    <Card key={index} style={{width: "100%", marginBottom: 4}} className={clsx(classes.folderFile)} onClick={() => clickFolder(id , name)}>
                                        <CardHeader
                                          avatar={
                                              <FolderOpen/>
                                          }

                                          title={name}
                                          subheader={time}
                                        />
                                    </Card>
                                    <Divider/>
                                </>
                              )
                          })}

                      </Grid>

                      <Grid item xs={12}>
                          <h4>File</h4>
                          {folderList && folderList.data.files.map((folder: any, index: any) => {
                              let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.updated_at}</Moment>
                              let nameShow = folder.type === 'FILE' ? folder.fileName : folder.name;
                              let icon = folder.type === 'FILE' ? <Description/> : <Link/>
                              return (
                                <>
                                    <Card key={index} style={{width: "100%", marginBottom: 4}} >
                                        <CardHeader
                                          avatar={
                                              icon
                                          }
                                          title={nameShow}
                                          subheader={time}
                                        />
                                    </Card>
                                    <Divider/>
                                </>
                              )
                          })}

                      </Grid>

                  </Grid>
              </DialogContent>
              <DialogActions>
                  <Button onClick={handleConfigFFClose}>Cancel</Button>
                  <Button onClick={() => configFolder()} className={clsx(classes.shareButton)}>Config</Button>
              </DialogActions>
          </Dialog>
          }

      </>
    )
}

export default ConfigFileFolder;

import React, { useEffect, useRef, useState } from "react";
import illustration from "../../assets/image/auth.png";
import { Add, Folder, HomeWork } from "@material-ui/icons";
import HomeDefault from "../../layouts/home/HomeDefault";
import { Button } from "@material-ui/core";
import { COLORS } from "../../utils/Colors";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import AddCompany from "../../components/setting/AddCompany";
import CompanyList from "../../components/setting/CompanyList";
import Grid from "@material-ui/core/Grid";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";


interface Props {

}

const useStyles = makeStyles((theme) => ({

  addCompanyButton: {
    backgroundColor: COLORS.basfOrange,
    top: "1%",
    cursor: "pointer",
    color: COLORS.basfWhite,
    "&:hover": {
      backgroundColor: COLORS.basfPaleOrange,
      color: COLORS.basfOrange,
    },
  },

}))


const Company: React.FC<Props> = ({}) => {
  const classes = useStyles();
  const [openAddCompanyPop, setOpenAddCompanyPro] = useState(false);

  const { user } = useSelector(
    (state: RootState) => state.auth
  );

  const handleAddCompany = () => {
    setOpenAddCompanyPro(!openAddCompanyPop)
  }



  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Project's"
      icon={<HomeWork />}
    >
      <Grid
      style={{
        width: "70%",
        position:"absolute",

      }}>
        <Grid style={{width: "100%"}}>
          {user && user.role === 'Vendor' &&
            <Button className={clsx(classes.addCompanyButton)} onClick={handleAddCompany}>
              <Add></Add> New
            </Button>
          }
          <CompanyList></CompanyList>
        </Grid>
        {openAddCompanyPop &&
          <AddCompany openCompanyAdd={openAddCompanyPop} handleAddCompany={handleAddCompany} />
        }
      </Grid>
    </HomeDefault>


  )


}

export default Company



import React, { useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Delete, Edit, MoreVert } from "@material-ui/icons";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13,
    marginRight: 5,
  },
}));

interface Props {
  type: any;
  dataDetails: any;
  setOnClick: any;
  openFolderDelete: any;
  setOpenFolderDelete: any;
  setDocumentId: any;
  setOpenFolderEdit: any;
  openFolderEdit: any;
  setFolderNameEdit: any;
  setOpenNotesEdit: any;
  openNotesEdit: any;
  setFolderNoteTitleEdit: any;
  folderNoteTitleEdit: any;
  setFolderNoteDescEdit: any;
  folderNoteDescEdit: any;
}

const InitiativeSidePop: React.FC<Props> = ({
  type,
  dataDetails,
  setOnClick,
  setOpenFolderDelete,
  openFolderDelete,
  setDocumentId,
  setOpenFolderEdit,
  openFolderEdit,
  setFolderNameEdit,
  setOpenNotesEdit,
  openNotesEdit,
  setFolderNoteTitleEdit,
  folderNoteTitleEdit,
  setFolderNoteDescEdit,
  folderNoteDescEdit,
}) => {
  const classes = useStyles();
  const [folderName, setFolderName] = useState("");

  const { user } = useSelector((state: RootState) => state.auth);

  const handleDeleteFodler = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setDocumentId(dataDetails.id);
    setOpenFolderDelete(!openFolderDelete);
  };

  const handleEditFodler = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setDocumentId(dataDetails.id);
    setFolderNameEdit(dataDetails.name);
    setOpenFolderEdit(!openFolderEdit);
  };

  const handleEditNote = async (e: any) => {
    if (e.close) {
      e.close();
    }
    setDocumentId(dataDetails.id);
    setFolderNoteTitleEdit(dataDetails.name);
    setFolderNoteDescEdit(dataDetails.description);
    setOpenNotesEdit(!openNotesEdit);
  };

  return (
    <>
      {user && user.role != "User" && (
        <PopupState variant="popover" popupId="demoMenu">
          {(popupState) => (
            <React.Fragment>
              <Tooltip title={"More"}>
                <IconButton
                  aria-label="settings"
                  {...bindTrigger(popupState)}
                  onMouseEnter={() => setOnClick(false)}
                >
                  <MoreVert />
                </IconButton>
              </Tooltip>
              <Menu
                {...bindMenu(popupState)}
                style={{ marginTop: 46, marginLeft: "-2%" }}
              >
                {type === "Folder" && (
                  <MenuItem
                    onClick={() => handleEditFodler(popupState)}
                    style={{ fontSize: 13 }}
                  >
                    <Edit className={clsx(classes.iconStyle)} />
                    Rename
                  </MenuItem>
                )}
                {type === "NOTE" && (
                  <MenuItem
                    onClick={() => handleEditNote(popupState)}
                    style={{ fontSize: 13 }}
                  >
                    <Edit className={clsx(classes.iconStyle)} />
                    Edit
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => handleDeleteFodler(popupState)}
                  style={{ fontSize: 13 }}
                >
                  <Delete className={clsx(classes.iconStyle)} /> Delete
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      )}
    </>
  );
};

export default InitiativeSidePop;

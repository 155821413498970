import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Grid,
  IconButton,
} from "@material-ui/core";
import {
  AttachFile,
  Link,
  BlurLinear,
  Description,
  FolderOpen,
  MoreVert,
  Add,
  Delete,
  Folder,
  Receipt,
  PictureAsPdf,
  Image,
  Notes,
} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import DocumentAction from "../../stores/actions/documentAction";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { COLORS } from "../../utils/Colors";
import { BASE_URL } from "../../utils/Constants";
import ConfigFileFolder from "./ConfigFileFolder";
import MenuPopover from "./FileCreate";
import filePng from "../../assets/image/folder.png";

const useStyles = makeStyles((theme) => ({
  folderFile: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,
      boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
    },
  },

  button: {
    color: COLORS.basfLightBlue,
    cursor: "pointer",
    "&:hover": {
      color: COLORS.hoverColorSecond,
    },
  },
  addButton: {
    backgroundColor: COLORS.primary,
    width: 60,
    height: 60,
    display: "flex",
    padding: 14,
    borderRadius: 50,
    position: "fixed",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColor,
    },
  },

  deleteButton: {
    backgroundColor: COLORS.basfPaleRed,
    paddingLeft: 9,
    paddingRight: 9,
    borderRadius: 20,
    cursor: "pointer",
    color: COLORS.basfRed,
    "&:hover": {
      backgroundColor: COLORS.basfRed,
      color: COLORS.basfWhite,
    },
  },
}));

interface Props {
  setFolderId: any;
  folderId: any;
  teamId: any;
  showInput: any;
}

const DiscussionFileFolder: React.FC<Props> = ({
  setFolderId,
  folderId,
  teamId,
  showInput,
}) => {
  const dispatch = useDispatch()<any>;
  const classes = useStyles();
  const [folders, setFolders] = useState([{ id: "", name: "" }]);
  const [showFolderFiles, setShowFolderFiles] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPop, setOpenPop] = useState(false);
  const [savedDocument, setSavedDocument] = useState(false);
  const [saveFolderId, setSaveFolderId] = useState("");

  const { discussionFiles } = useSelector(
    (state: RootState) => state.documents
  );

  const { user } = useSelector((state: RootState) => state.auth);

  // fucntion use for get file and folders
  const getFileDiscussionFolders = useCallback(
    async (event: any | null, team: any | null) => {
      const result = await dispatch(
        DocumentAction.getDiscussionDocuments({
          folder_id: event,
          teamId: team,
          keyword: null,
          sort: null,
        })
      );
      if (result.showChoose) {
        setShowFolderFiles(true);
      }
      setSaveFolderId(result.folderId);
    },
    [dispatch]
  );

  const removeDiscussionFolder = useCallback(
    async (event: any | null) => {
      const result = await dispatch(
        DocumentAction.removeConfigDocument({ config_id: event })
      );
      if (result.status) getFileDiscussionFolders(folderId, teamId);
    },
    [dispatch, teamId]
  );

  useEffect(() => {
    getFileDiscussionFolders(folderId, teamId);
  }, [getFileDiscussionFolders, showFolderFiles, savedDocument]);

  const clickFolder = async (id: any, name: any) => {
    let newData = { id: id, name: name };
    setFolders([...folders, newData]);
    getFileDiscussionFolders(id, teamId);
    setFolderId(id);
  };

  const backClick = async (id: any, teamId: any) => {
    if (folders.length > 0) {
      let newArray = [];
      for (const foldIs of folders) {
        if (foldIs && foldIs.id === id) {
          newArray.push({ id: foldIs.id, name: foldIs.name });
          break;
        } else {
          newArray.push({ id: foldIs.id, name: foldIs.name });
          setFolders(newArray);
        }
      }
    }
    if (id === "") {
      setFolders([{ id: "", name: "" }]);
    }
    setFolderId(id);
    getFileDiscussionFolders(id, teamId);
  };

  const removeFolder = async (e: any) => {
    removeDiscussionFolder(e);
  };

  const openAddPopup = () => {
    setOpenPop(!openPop);
  };

  return (
    <>
      {discussionFiles && !discussionFiles.showChoose && (
        <>
          <Grid container spacing={2} style={{ marginBottom: "1%" }}>
            <Grid item xs={12}>
              <a
                className={clsx(classes.button)}
                onClick={() => backClick("", teamId)}
              >
                Main
              </a>
              {folders &&
                folders.map((fold: any, ind: any) => {
                  if (fold.id) {
                    return (
                      <a
                        className={clsx(classes.button)}
                        onClick={() => backClick(fold.id, teamId)}
                        style={{ marginLeft: "1%" }}
                        key={ind}
                      >
                        {"> " + fold.name}
                      </a>
                    );
                  }
                })}
              {user && user.role === "Vendor" && (
                <a
                  className={clsx(classes.deleteButton)}
                  style={{ float: "right", display: "flex" }}
                  onClick={() => removeFolder(discussionFiles.configFolderId)}
                >
                  Remove
                </a>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {discussionFiles && discussionFiles.data.folder.length > 0 && (
                <>
                  <h4>Folder</h4>
                  {discussionFiles.data.folder.map(
                    (folder: any, index: any) => {
                      let time = (
                        <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                          {folder.updated_at}
                        </Moment>
                      );
                      let id = folder.id;
                      let name = folder.name;
                      return (
                        <>
                          <Card
                            key={index}
                            style={{ width: "100%", marginBottom: 4 }}
                            className={clsx(classes.folderFile)}
                            onClick={() => clickFolder(id, name)}
                          >
                            <CardHeader
                              avatar={
                                <Folder style={{ color: COLORS.basfOrange }} />
                              }
                              title={name}
                              subheader={time}
                              action={
                                <IconButton aria-label="settings">
                                  <MoreVert />
                                </IconButton>
                              }
                            />
                          </Card>
                          <Divider />
                        </>
                      );
                    }
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              {discussionFiles && discussionFiles.data.files.length > 0 && (
                <>
                  <h4>File</h4>
                  {discussionFiles.data.files.map((folder: any, index: any) => {
                    let time = (
                      <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                        {folder.updated_at}
                      </Moment>
                    );
                    let url =
                      folder.type === "FILE"
                        ? BASE_URL + "" + folder.name
                        : folder.fileName;
                    let nameShow =
                      folder.type === "FILE" ? folder.fileName : folder.name;
                    let icon =
                      folder.type === "FILE" && folder.ext === "sql" ? (
                        <Receipt style={{ color: COLORS.basfDarkBlue }} />
                      ) : folder.ext === "pdf" ? (
                        <PictureAsPdf style={{ color: COLORS.basfRed }} />
                      ) : folder.ext === "jpg" ? (
                        <Image style={{ color: COLORS.basfOrange }} />
                      ) : folder.ext === "png" ? (
                        <Image style={{ color: COLORS.basfOrange }} />
                      ) : folder.ext === "jpeg" ? (
                        <Image style={{ color: COLORS.basfOrange }} />
                      ) : folder.ext === "svg" ? (
                        <Image style={{ color: COLORS.basfOrange }} />
                      ) : folder.ext === "link" ? (
                        <Link style={{ color: COLORS.basfDarkBlue }} />
                      ) : folder.ext === "txt" ? (
                        <Notes style={{ color: COLORS.basfDarkBlue }} />
                      ) : folder.ext === "docx" ? (
                        <Description style={{ color: COLORS.basfDarkBlue }} />
                      ) : folder.ext === "xlsx" ? (
                        <Description style={{ color: COLORS.basfDarkGreen }} />
                      ) : (
                        <Description />
                      );
                    return (
                      <>
                        <a
                          href={url}
                          target={"_blank"}
                          style={{ textDecoration: "none" }}
                        >
                          <Card
                            key={index}
                            style={{ width: "100%", marginBottom: 4 }}
                            className={clsx(classes.folderFile)}
                          >
                            <CardHeader
                              avatar={icon}
                              title={nameShow}
                              subheader={time}
                              action={
                                <IconButton aria-label="settings">
                                  <MoreVert />
                                </IconButton>
                              }
                            />
                          </Card>
                        </a>
                        <Divider />
                      </>
                    );
                  })}
                </>
              )}
            </Grid>
            {showInput && (
              <div className={clsx(classes.addButton)} onClick={openAddPopup}>
                <Add
                  style={{
                    fontSize: "32px",
                    color: "white",
                  }}
                ></Add>
              </div>
            )}
          </Grid>
          <MenuPopover
            anchorEl={anchorEl}
            openPop={openPop}
            openAddPopup={openAddPopup}
            folderId={saveFolderId}
            setSavedDocument={setSavedDocument}
            saveDocument={savedDocument}
          ></MenuPopover>
        </>
      )}
      {showFolderFiles &&
        discussionFiles &&
        discussionFiles.showChoose &&
        user &&
        user.role === "Vendor" && (
          <ConfigFileFolder
            teamId={teamId}
            showFolderFiles={showFolderFiles}
            setShowFolderFiles={setShowFolderFiles}
          />
        )}
      {showFolderFiles &&
        discussionFiles &&
        discussionFiles.showChoose &&
        user &&
        user.role === "User" && (
          <>
            <div style={{ textAlign: "center", maxWidth: "100%" }}>
              {/*<ChatBubble style={{color:COLORS.basfDarkBlue , fontSize:"24px" , marginTop: "13%" }}/>*/}
              <img src={filePng} style={{ marginTop: "5%" }} width={"20%"} />
              <h2 style={{ color: COLORS.basfLightBlue }}>
                Waiting for folder
              </h2>
              <p style={{ color: "#666" }}>
                Please inform to admin for folder attach with discussion .
              </p>
            </div>
          </>
        )}
    </>
  );
};

export default DiscussionFileFolder;

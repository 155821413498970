import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import { useDispatch } from "react-redux";
import DocumentAction from "../../stores/actions/documentAction";

interface Props {

    openDialog: boolean,
    handleDialog: any,
    folderId: any,
    setSavedDocument: any,
    saveDocument: any
}

const FolderAddPopoverCL: React.FC<Props> = ({openDialog , handleDialog , folderId , setSavedDocument , saveDocument}) => {

    const [folderName, setFolderName] = useState('');
    const dispatch = useDispatch()<any>;
    const [buttonLoader, setButtonLoader] = useState(false);

    const saveFolder = async (e: any) => {
        e.preventDefault();
        setButtonLoader(true);
        const name = folderName;
        const folder_id = folderId;
        const result = await dispatch(DocumentAction.addFolders({name , folder_id}))
        if(result && result.status) {
            setSavedDocument(!saveDocument)
            setFolderName('');
            handleDialog();
        }
        setButtonLoader(false);
    }

    return(
        <>
            <Dialog open={openDialog} onClose={handleDialog}>
                <form action={'#'} onSubmit={saveFolder}>
                <DialogTitle>New Folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Folder Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={folderName}
                        onChange={(text) => setFolderName(text.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialog}>Cancel</Button>
                    <Button type={"submit"} disabled={buttonLoader}>Add</Button>
                </DialogActions>
                </form>
            </Dialog>
        </>
    );

}

export default FolderAddPopoverCL

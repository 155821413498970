import React, {useState} from "react";
import {Divider, IconButton, Menu, MenuItem} from "@material-ui/core";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {AttachFile, Chat, Delete, Edit, EmojiEmotions, MoreVert, Replay, VideoCall} from "@material-ui/icons";
import clsx from "clsx";
import {COLORS} from "../../utils/Colors";
import EmojiPicker from "emoji-picker-react";

interface Props{
    addEmoji: any
}

const EmojiPopover:React.FC<Props> = ({addEmoji}) => {
    const [state , setState] = React.useState({});
    const [emojiShow , setEmojiShow] = useState(false);
    const isHoveringEm = 'isHoveringEm';
    const showEmojiPop = () => {
        setEmojiShow(!emojiShow)
    }


    return(
    <>
        <PopupState variant="popover" popupId="demoMenu">
            {(popupState) => (
                <React.Fragment>
                    <IconButton aria-label="Emoji" {...bindTrigger(popupState)} style={{padding: 5}}>
                        <EmojiEmotions style={{cursor: "pointer" , color: state === isHoveringEm  ? COLORS.basfLightOrange : COLORS.basfOrange }} data-info="isHoveringEm" onMouseEnter={() => setState('isHoveringEm')} onClick={showEmojiPop} onMouseLeave={() => setState('')}></EmojiEmotions>
                    </IconButton>

                    <Menu {...bindMenu(popupState)} style={{marginTop: "-4%", marginLeft: "-1%"}}>
                        <EmojiPicker width="50" onEmojiClick={(text) => addEmoji(text , popupState )} />
                    </Menu>

                </React.Fragment>
            )}
        </PopupState>
    </>
    )

}

export default EmojiPopover

import React, { useEffect, useRef, useState } from "react";
import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import Grid from "@material-ui/core/Grid";
import { Add, Folder } from "@material-ui/icons";
import { COLORS } from "../../utils/Colors";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import MenuPopover from "../../components/documents/FileCreate";
import LinkAddPopover from "../../components/documents/LinkAddPopover";
import FolderAddPopover from "../../components/documents/FolderAddPopover";
import FileFolder from "../../components/documents/FileFolder";
import FileFolderCL from "../../components/customer_library/FileFolderCL";
import FileCreateCL from "../../components/customer_library/FileCreateCL";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";

const initialPlayerState = {
  docId: "",
  name: "",
  createdAt: null,
};

const useStyles = makeStyles((theme) => ({
  addButton: {
    backgroundColor: COLORS.primary,
    width: 60,
    height: 60,
    display: "flex",
    padding: 14,
    borderRadius: 50,
    position: "absolute",
    bottom: 10,
    right: 10,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.hoverColor,
    },
  },
}));

const CustomerLibrary = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPop, setOpenPop] = useState(false);
  const [folderId , setFolderId] = useState('');
  const [savedDocument , setSavedDocument] = useState(false);


  const { user } = useSelector(
    (state: RootState) => state.auth
  );

  const openAddPopup = () => {
    setOpenPop(!openPop);
  };



  return (
    <>
      <HomeDefault
        illustrationBackground={illustration}
        image={illustration}
        heading="Library"
        icon={<Folder />}
      >
        <Grid>
          <FileFolderCL setFolderId={setFolderId} savedDocument={savedDocument} folderId={folderId} setSaveDocument={setSavedDocument}/>

          {user && user.role === 'User' &&
          <div className={clsx(classes.addButton)} onClick={openAddPopup}>
            <Add
              style={{
                fontSize: "32px",
                color: "white",
              }}
            ></Add>
          </div>
          }
        </Grid>
      </HomeDefault>

      <FileCreateCL
        anchorEl={anchorEl}
        openPop={openPop}
        openAddPopup={openAddPopup}
        folderId={folderId}
        setSavedDocument={setSavedDocument}
        saveDocument={savedDocument}
      ></FileCreateCL>

    </>
  );
};

export default CustomerLibrary;

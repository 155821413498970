import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import AuthAction from "../../stores/actions/authAction";
import { useDispatch } from "react-redux";

interface Props {}

const PasswordUpdate: React.FC<Props> = ({}) => {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const dispatch = useDispatch()<any>;

  const handleUpdatePassword = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    const result = await dispatch(
      AuthAction.changePassword({ oldPassword, newPassword, confirmPassword })
    );
    setButtonLoader(false);
    if (result.status) {
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    }
  };

  return (
    <WrapperPassword>
      <form action={"#"} onSubmit={handleUpdatePassword}>
        <Paper style={{ padding: 30, width: "70%" }}>
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-old-password">
              Enter Old Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-old-password"
              value={oldPassword}
              error={oldPassword.length ? false : true}
              label={"Enter Old Password"}
              required={true}
              onChange={(text) => setOldPassword(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter Old Password"
              inputProps={{ style: { textAlign: "left" } }}
              type="password"
            />
          </FormControl>
          <br />
          <br />
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-new_password">
              Enter New Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-new_password"
              value={newPassword}
              error={newPassword.length ? false : true}
              label={"Enter New Password"}
              required={true}
              onChange={(text) => setNewPassword(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter New Password"
              inputProps={{ style: { textAlign: "left" } }}
              type="password"
            />
          </FormControl>

          <br />
          <br />
          <FormControl variant="outlined" size={"small"} fullWidth={true}>
            <InputLabel htmlFor="outlined-adornment-confirm_password">
              Enter Confirm Password
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-confirm_password"
              value={confirmPassword}
              error={confirmPassword.length ? false : true}
              label={"Enter Confirm Password"}
              required={true}
              onChange={(text) => setConfirmPassword(text.target.value)}
              aria-describedby="outlined-weight-helper-text"
              placeholder="Enter Confirm Password"
              inputProps={{ style: { textAlign: "left" } }}
              type="password"
            />
          </FormControl>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              className="loginButton"
              type="submit"
              disabled={buttonLoader}
            >
              Change Password
            </Button>
          </Grid>
        </Paper>
      </form>
    </WrapperPassword>
  );
};

export default PasswordUpdate;

const WrapperPassword = styled.div`
  .loginButton {
    background-color: rgb(74 141 211);
    width: 50%;
    margin-top: 5%;
    height: 45px;
    border-radius: 25px;
    color: white;
  }

  .loginButton:hover {
    background-color: #004a96;
  }
`;

import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

import Dashboard from "../../views/home/Discussion";
import Calender from "../../views/calender/Calender";
import Documents from "../../views/directory/Documents";
import Tasks from "../../views/tasks/Tasks";
import HomePage from "../../views/home/HomePage";
import SingleMessage from "../../views/home/SingleMessage";
import Company from "../../views/company/Compnay";
import Profile from "../../views/profile/Profile";
import TaskList from "../../views/tasks/TaskList";
import Initiative from "../../views/initiative/Initiative";
import CustomerLibrary from "../../views/customer-library/CustomerLibrary";
import { socket } from "../../utils/Constants";
import { RootState } from "../../stores";
import { TaskPieChart } from "../../views/tasks/TaskPieChart";
import { JoiningRequest } from "../../views/joining-requests/joining-request";

const HomeRoute = () => {
  const { userId, authenticated, token } = useSelector((state: RootState) => {
    const { user, authenticated, token } = state.auth;
    return { userId: user?.id, authenticated, token };
  });

  useEffect(() => {
    if (!socket.connected && authenticated && token && userId) {
      if (socket.disconnected) {
        socket.auth = { userId: userId, token: token };
        socket.connect();
      }
    }

    return () => {
      if (!authenticated) {
        socket.disconnect();
      }
    };
  }, [userId, authenticated, token]);

  return (
    <Routes>
      <Route path={"/"} element={<HomePage />} />
      <Route path={"/joining-requests"} element={<JoiningRequest />} />
      <Route path={"/discussion/:teamId"} element={<Dashboard />} />
      <Route path={"/chat/:team_id/:message_id"} element={<SingleMessage />} />
      <Route path={"/calender"} element={<Calender />} />
      <Route path={"/library"} element={<Documents />} />
      <Route path={"/customer-library"} element={<CustomerLibrary />} />
      <Route path={"/tasks"} element={<Tasks />} />
      <Route path={"/project"} element={<Company />} />
      <Route path={"/profile"} element={<Profile />} />
      <Route path={"/tasks/list/:projectId"} element={<TaskList />} />
      <Route path={"/tasks/pie-chart/:projectId"} element={<TaskPieChart />} />
      <Route path={"/initiatives"} element={<Initiative />} />
    </Routes>
  );
};

export default HomeRoute;

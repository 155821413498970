import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";

import Logo from "../../assets/image/logo.svg";

export const Container = styled.div`
  background-color: #f1f5f9;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
  }

  .landing-card {
    background: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    border-radius: 0.1rem;
    max-width: 95%;
  }

  .landing-card_header {
    background-color: #21a0d2;
    padding: 16px 16px 6px;
    display: flex;
    justify-content: center;
  }

  .landing-card_header img {
    height: 3.5rem;
  }

  .landing-card_body {
    padding: 24px;
  }

  .landing-card_body h2 {
    margin: 0 0 12px;
    color: #0f172a;
  }

  .landing-card_body small {
    color: #334155;
    font-size: 14px;
  }

  .landing-card_footer {
    padding: 0 20px 20px;
    display: flex;
    justify-content: center;
  }

  .landing-card_footer button {
    font-size: 18px;
    background-color: #21a0d2;
    border: none;
    outline: none;
    color: white;
    padding: 8px 24px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
  }

  .landing-card_footer button:hover {
    transform: scale(1.01);
  }

  .landing-card_footer button:active {
    transform: scale(1);
  }

  .footer {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 12px 24px;
    color: #21a0d2;
  }

  .footer a {
    color: #21a0d2;
    text-decoration: none;
    font-size: 14px;
  }

  .footer span {
    color: #21a0d2;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  @media (min-width: 640px) {
    .landing-card {
      max-width: 70%;
    }
  }

  @media (min-width: 768px) {
    .landing-card {
      max-width: 60%;
    }
  }

  @media (min-width: 1024px) {
    .landing-card {
      max-width: 35%;
    }
  }

  @media (min-width: 1280px) {
    .landing-card {
      max-width: 30%;
    }
  }

  @media (min-width: 1536px) {
    .landing-card {
      max-width: 30%;
    }
  }
`;

export const LandingPage = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="body">
        <div className="landing-card">
          <div className="landing-card_header">
            <img src={Logo} alt="Logo" />
          </div>

          <div className="landing-card_body">
            <h2>Collaborative Marketplace</h2>
            <small>
              Welcome to BASF collaborative marketplace platform. BASF's
              Performance Material team helps customers maximize their potential
              by creating application-oriented industry solutions.
            </small>
          </div>

          <div className="landing-card_footer">
            <button onClick={() => navigate("/sign-in")}>Sign in</button>
          </div>
        </div>
      </div>

      <div className="footer">
        <span>
          <Link to="/privacy-policy">Data Privacy Statement</Link>
        </span>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
      </div>
    </Container>
  );
};

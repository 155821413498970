import { RootState } from "../";
import { ThunkAction } from "redux-thunk";
import LoadingType from "../types/loadingType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../types/alertType";
import {
  AddCompany,
  DeleteCompany,
  GetCompany,
  GetInvitedMember,
  InviteMember,
  RemoveMember,
  SetDefaultCompany,
  UpdateCompany,
} from "../types/settingType";
import SettingTypes from "../types/settingType";

interface Action {
  type: string;
  message?: string;
  payload?: any;
}

const SettingAction = {
  addCompany:
    (data: AddCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_company",
          {
            name: data.name,
            users: data.users,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  editCompany:
    (data: UpdateCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/update_company",
          {
            name: data.name,
            id: data.id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message:
              message.data.message === "Company successfully created"
                ? "Updated successfully"
                : message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  setDefaultCompany:
    (data: SetDefaultCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/set_default_company",
          {
            company_id: data.company_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: SettingTypes.SET_DEFAULT_COMPANY,
            payload: message.data,
          });

          dispatch({
            type: SettingTypes.SET_COMPANY_DETAILS,
            payload: message.data,
          });
        }

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getCompanyDetails:
    (data: SetDefaultCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/get_company_details",
          {
            company_id: data.company_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: SettingTypes.SET_COMPANY_DETAILS,
            payload: message.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getCompanyData:
    (data: GetCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL + "api/v1/get_company_list",
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: SettingTypes.SET_COMPANY,
            payload: result.data,
          });
        }
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  deleteCompany:
    (data: DeleteCompany): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(
          BASE_URL + "api/v1/delete_company",
          {
            data: { company_id: data.company_id },
            headers: { Authorization: "Bearer " + JSON.parse(token) },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });
        return result.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  removeMember:
    (data: RemoveMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(
          BASE_URL + "api/v1/remove_company_member",
          {
            data: { company_member_id: data.remove_company_member },
            headers: { Authorization: "Bearer " + JSON.parse(token) },
          }
        );

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: result.data.message,
          },
        });
        return result.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  inviteCompanyMember:
    (data: InviteMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/invite_company_member",
          {
            company_id: data.companyId,
            email: data.email,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getInviteMember:
    (data: GetInvitedMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/get_invited_data",
          {
            invitation_id: data.inviteId,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },
};

export default SettingAction;

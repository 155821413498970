import { RootState } from "../";
import DiscussionType, {
  AddTeam,
  AddTeamDirect,
  AddTeamMember,
  CompnayMember,
  DeleteFile,
  GetMessage,
  GetSingleMessage,
  GetTeamMember,
  MeetingMessage,
  Message,
  SearchMessage,
  SearchTeamMember,
  TeamDetails,
  Teams,
  UploadFile,
} from "../types/discussionType";
import { ThunkAction } from "redux-thunk";
import LoadingType from "../types/loadingType";
import axios from "axios";
import { BASE_URL } from "../../utils/Constants";
import AlertTypes, { AlertType } from "../types/alertType";
import DiscussionTypes from "../types/discussionType";
import { UpdateProfileImage } from "../types/authTypes";
import FormData from "form-data";

interface Action {
  type: string;
  message?: string;
  payload?: any;
}

const DiscussionAction = {
  addTeam:
    (data: AddTeam): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_team",
          {
            name: data.name,
            company_id: companyId,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addTeamDirect:
    (data: AddTeamDirect): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_team_direct",
          {
            memberId: data.memberId,
            company_id: companyId,
            type: data.type,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        return message.data;

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getTeams:
    (data: Teams): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL +
            "api/v1/get_teams/" +
            data.type +
            "/" +
            data.company_id +
            "/" +
            data.get_type,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_TEAM_DAFULT,
            payload: result.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        return result.data;
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  getTeamsAll:
    (data: Teams): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL +
            "api/v1/get_teams/" +
            data.type +
            "/" +
            data.company_id +
            "/" +
            data.get_type,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_TEAM,
            payload: result.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  getTeamsAllPersonal:
    (data: Teams): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL + "api/v1/get_teams_personal/" + data.company_id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_TEAM_PERSONL,
            payload: result.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  getTeamDetails:
    (data: TeamDetails): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/get_team_details",
          {
            team_id: data.team_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: DiscussionTypes.SET_TEAM_DETAILS,
            payload: message.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message;
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  sendMessage:
    (data: Message): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        // dispatch({
        //   type: LoadingType.SHOW_LOADING
        // })

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/create_message",
          {
            team_id: data.team_id,
            message_type: data.message_type,
            contents: data.content,
            file: data.file,
            fileName: data.fileName,
            parent_message_id: data.parent_message_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        return message;

        // dispatch({
        //   type: LoadingType.HIDE_LOADING,
        // });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getMessage:
    (data: GetMessage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.LOADING_MESSAGE_FALSE,
        });
        // dispatch({
        //   type: DiscussionTypes.SET_MESSAGE,
        //   payload: ''
        // })
        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/get_message",
          {
            team_id: data.team_id,
            limit: data.limit,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: DiscussionTypes.SET_MESSAGE,
            payload: message.data,
          });
        }

        dispatch({
          type: LoadingType.LOADING_MESSAGE,
        });

        return message.data;

        // dispatch({
        //   type: LoadingType.HIDE_LOADING,
        // });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getSingleMessage:
    (data: GetSingleMessage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        // dispatch({
        //   type: DiscussionTypes.SET_SINGLE_MSG,
        //   payload: ''
        // })
        const token: string | any = localStorage.getItem("@userData:token");

        const message: any = await axios.post(
          BASE_URL + "api/v1/get_message_single",
          {
            message_id: data.message_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: DiscussionTypes.SET_SINGLE_MSG,
            payload: message.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getCompanyMember:
    (data: CompnayMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        dispatch({
          type: DiscussionTypes.SET_COMPANY_MEMBER,
          payload: "",
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL + "api/v1/get_company_member/" + data.company_id,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_COMPANY_MEMBER,
            payload: result.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  uploadFile:
    (data: UploadFile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");

        const formData = new FormData();
        formData.append("file", data.file);
        const user: any = await axios.post(
          BASE_URL + "api/v1/file_upload",
          formData,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // dispatch({
        //   type: AlertTypes.SET_ALERT,
        //   payload: {
        //     type: AlertType.WARNING,
        //     message: user.data.message,
        //   },
        // });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return user.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  deleteFile:
    (data: DeleteFile): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const user: any = await axios.post(
          BASE_URL + "api/v1/file_delete",
          { file_name: data.file_name },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: user.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return user.data;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getDiscussionFiles:
    (data: GetMessage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        dispatch({
          type: DiscussionTypes.SET_MESSAGE_FILES,
          payload: "",
        });
        const token: string | any = localStorage.getItem("@userData:token");

        const message: any = await axios.post(
          BASE_URL + "api/v1/get_message_files",
          {
            team_id: data.team_id,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (message.data.status) {
          dispatch({
            type: DiscussionTypes.SET_MESSAGE_FILES,
            payload: message.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  searchDiscussion:
    (data: SearchMessage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        const token: string | any = localStorage.getItem("@userData:token");

        const message: any = await axios.post(
          BASE_URL + "api/v1/search_message",
          {
            team_id: data.team_id,
            keyword: data.keyword,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        return message.data;
      } catch (error: any) {
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  createMeetingMessage:
    (data: MeetingMessage): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.post(
          BASE_URL + "api/v1/meetings",
          {
            team_id: data.team_id,
            parent_message_id: data.parent_message_id,
            topic: data.topic,
            start_time: data.start_time,
            agenda: data.agenda,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  addTeamMember:
    (data: AddTeamMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.post(
          BASE_URL + "api/v1/add_team_member",
          {
            team_id: data.team_id,
            member_id: data.member_id,
            type: data.type,
            company_id: companyId,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        return message;

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getTeamMember:
    (data: GetTeamMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        dispatch({
          type: DiscussionTypes.SET_TEAM_MEMBER,
          payload: "",
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.get(
          BASE_URL + "api/v1/get_team_member/" + data.teamId,
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_TEAM_MEMBER,
            payload: result.data,
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  removeTeamMember:
    (data: AddTeamMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const companyId: string | any = localStorage.getItem(
          "@userData:companyId"
        );
        const message: any = await axios.delete(
          BASE_URL + "api/v1/remove_member",
          {
            data: {
              team_id: data.team_id,
              member_id: data.member_id,
              type: data.type,
              company_id: companyId,
            },

            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        return message;

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  deleteTeam:
    (data: GetTeamMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });

        const token: string | any = localStorage.getItem("@userData:token");
        const message: any = await axios.delete(
          BASE_URL + "api/v1/delete_team",
          {
            data: {
              team_id: data.teamId,
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: message.data.message,
          },
        });

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return message;
      } catch (error: any) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
        dispatch({
          type: AlertTypes.SET_ALERT,
          payload: {
            type: AlertType.WARNING,
            message: error.message,
          },
        });
      }
    },

  getSearchMember:
    (data: SearchTeamMember): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        // dispatch({
        //   type: LoadingType.SHOW_LOADING
        // })
        dispatch({
          type: DiscussionTypes.SET_SEARCH_USER,
          payload: "",
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.post(
          BASE_URL + "api/v1/get_member_search/" + data.teamId,
          {
            keyword: data.keyword,
          },
          {
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.SET_SEARCH_USER,
            payload: result.data,
          });
        }

        // dispatch({
        //   type: LoadingType.HIDE_LOADING
        // })

        return result.data;
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },

  deleteChatMessage:
    (data: {
      messageId: string;
      isFeed?: boolean;
    }): ThunkAction<void, RootState, null, Action> =>
    async (dispatch) => {
      try {
        dispatch({
          type: LoadingType.SHOW_LOADING,
        });
        const token: string | any = localStorage.getItem("@userData:token");
        const result: any = await axios.delete(
          `${BASE_URL}api/v1/delete_message`,
          {
            data: {
              message_id: data.messageId,
            },
            headers: {
              Authorization: "Bearer " + JSON.parse(token),
            },
          }
        );

        if (result.data.status) {
          dispatch({
            type: DiscussionTypes.DELETE_CHAT_MESSAGE,
            payload: { messageId: data.messageId, isFeed: data.isFeed },
          });
        }

        dispatch({
          type: LoadingType.HIDE_LOADING,
        });

        return result.data.status;
      } catch (error) {
        dispatch({
          type: LoadingType.HIDE_LOADING,
        });
      }
    },
};

export default DiscussionAction;

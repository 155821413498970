import React, { useCallback, useEffect, useState } from "react";
import { Task, ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { Button, Grid, Paper } from "@material-ui/core";
import { decode } from "js-base64";
import TaskAction from "../../stores/actions/taskAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import { getStartEndDateForProject, initTasks } from "./ganttChartData";
import { COLORS } from "../../utils/Colors";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { ViewSwitcher } from "./view-switcher";

interface Props {
  projectId: any;
  setGantFull: any;
  gantFull: any;
}

const TaskGantChart: React.FC<Props> = ({
  projectId,
  setGantFull,
  gantFull,
}) => {
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [isChecked, setIsChecked] = useState(true);
  const dispatch = useDispatch()<any>;
  const { taskGantt } = useSelector((state: RootState) => state.tasks);

  const { user } = useSelector((state: RootState) => state.auth);

  const [tasks, setTasks] = useState<Task[]>(initTasks(taskGantt));
  //Get task projects details
  const getProjectGantt = useCallback(async () => {
    const projectid = projectId;
    const result = await dispatch(
      TaskAction.getProjectTasksGantt({ projectid })
    );
    setTasks(initTasks(result));
  }, [dispatch]);

  //Call callback function
  useEffect(() => {
    getProjectGantt();
  }, [getProjectGantt]);

  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  const handleTaskChange = (task: Task) => {
    // console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  const handleDblClick = (task: Task) => {
    alert("On Double Click event Id:" + task.id);
  };

  const handleClick = (task: Task) => {
    console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };

  console.log(tasks);

  return (
    <>
      <Button
        style={{
          marginTop: "1%",
          backgroundColor:
            user && user.role === "User"
              ? COLORS.basfDarkBlue
              : COLORS.basfOrange,
          color: COLORS.basfWhite,
        }}
        onClick={() => setGantFull(!gantFull)}
      >
        {gantFull ? "Exit" : "View"} Full{" "}
        {gantFull ? <FullscreenExit /> : <Fullscreen />}{" "}
      </Button>
      <Paper style={{ width: "100%", marginTop: "1%", flexGrow: 1 }}>
        {taskGantt && tasks.length > 0 && (
          <>
            <ViewSwitcher
              onViewModeChange={(viewMode) => setView(viewMode)}
              onViewListChange={setIsChecked}
              isChecked={isChecked}
            />
            <Gantt
              tasks={tasks}
              viewMode={view}
              listCellWidth={isChecked ? "155px" : ""}
              columnWidth={columnWidth}
              onDateChange={handleTaskChange}
              onDelete={handleTaskDelete}
              onProgressChange={handleProgressChange}
              onDoubleClick={handleDblClick}
              onClick={handleClick}
              onSelect={handleSelect}
            />
          </>
        )}
      </Paper>
    </>
  );
};

export default TaskGantChart;

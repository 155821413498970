import React, { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export type ForgotPasswordStep3FormInputs = {
  password: string;
  confirmPassword: string;
};

const schema = yup
  .object({
    password: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password")], "Passwords must match"),
  })
  .required();

type Props = {
  onSubmit: (values: ForgotPasswordStep3FormInputs) => void;
};

export const ForgotPasswordStep3Form: FC<Props> = ({ onSubmit }) => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ForgotPasswordStep3FormInputs>({
    resolver: yupResolver(schema),
  });

  return (
    <form action={"#"} onSubmit={handleSubmit(onSubmit)}>
      <FormControl className="textField margin" variant="outlined" size="small">
        <InputLabel htmlFor="outlined-adornment-password">
          Password *
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={passwordVisible ? "text" : "password"}
          label={"Enter Password *"}
          aria-describedby="outlined-weight-helper-text"
          placeholder="Enter Password *"
          inputProps={{ style: { textAlign: "left" } }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisible(!passwordVisible)}
                edge="end"
              >
                {passwordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          {...register("password")}
          error={Boolean(errors?.password?.message)}
        />
        {errors?.password?.message && (
          <FormHelperText error>{errors?.password?.message}</FormHelperText>
        )}
      </FormControl>
      <br />
      <br />
      <FormControl className="textField margin" variant="outlined" size="small">
        <InputLabel htmlFor="outlined-adornment-confirm-password">
          Confirm Password *
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-confirm-password"
          type={passwordVisible ? "text" : "password"}
          label={"Confirm Password *"}
          aria-describedby="outlined-weight-helper-text"
          placeholder="Confirm Password *"
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisible(!passwordVisible)}
                edge="end"
              >
                {passwordVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          {...register("confirmPassword")}
          error={Boolean(errors?.confirmPassword?.message)}
        />
        {errors?.confirmPassword?.message && (
          <FormHelperText error>
            {errors?.confirmPassword?.message}
          </FormHelperText>
        )}
      </FormControl>

      <Button
        variant="contained"
        className="loginButton"
        type="submit"
        disabled={isSubmitting}
      >
        Continue
      </Button>
    </form>
  );
};

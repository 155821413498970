import React, { useCallback, useState } from "react";
import { AttachFile, Computer, Delete, Description, Folder, FolderOpen, Link, MoreVert } from "@material-ui/icons";
import {COLORS} from "../../utils/Colors";
import {
    Button, Card, CardHeader,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Divider, Grid,
    IconButton,
    Menu,
    MenuItem,
    TextField
} from "@material-ui/core";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import clsx from "clsx";
import {makeStyles} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import DocumentAction from "../../stores/actions/documentAction";
import Moment from "react-moment";
import { BASE_URL } from "../../utils/Constants";



const useStyles = makeStyles((theme) => ({

    iconStyle: {
        fontSize: 13 ,
        marginRight: 5
    },

    folderFile: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: COLORS.hoverColorSecond,
            boxShadow: `2px 2px 1px 3px ${COLORS.basfBoxGrey}`,
        },
    },

    button : {
        color: COLORS.basfDarkBlue,
        cursor:"pointer"
    }

}))

interface Props{
    onChangeMsgImage: any,
    setProfileImageDoc: any,
    setSelectDocument: any,
    setSendButton: any
}

const AttachmentPop: React.FC<Props> = ({onChangeMsgImage , setProfileImageDoc , setSelectDocument , setSendButton}) => {
    const [state , setState] = React.useState({});
    const [stateDocument , setStateDocument] = useState(false);
    const inputAtta = React.useRef<HTMLInputElement>(null)
    const isHoveringAt = 'isHoveringAt';
    const classes = useStyles();
    const dispatch = useDispatch()<any>;
    const { folderList  } = useSelector(
      (state: RootState) => state.documents
    );
    const [folders, setFolders] = useState([{id: '' , name: ''}]);

    const handleAttachOpenClick = (e: any) => {
        e.close();
        inputAtta.current && inputAtta.current.click();
    }

    // fucntion use for get file and folders
    const getFileFolders = useCallback( async (event: any | null) => {
        const result = dispatch(DocumentAction.getDocuments({folder_id: event , teamId: null , keyword: null , sort: null}));
    }, [dispatch])

    const handleDocumentPopClose = (e: any = null) => {
        if(e.close) {
            e.close()
        }
        setStateDocument(!stateDocument)
        getFileFolders('')
    }

    const clickFolder = async (id: any , name: any) => {
        let newData = {id: id, name: name}
        setFolders([...folders , newData]);
        getFileFolders(id)
        // setFolderId(id);
    }

    const backClick = async (id: any) => {
        if(folders.length > 0) {
            let newArray = [];
            for (const foldIs of folders) {
                if(foldIs && foldIs.id === id){
                    newArray.push({id: foldIs.id, name: foldIs.name});
                    break;
                }
                else{
                    newArray.push({id: foldIs.id, name: foldIs.name})
                    setFolders(newArray);
                }
            }
        }
        if(id === '') {
            setFolders([{ id: '', name: '' }]);
        }
        // setFolderId(id);
        getFileFolders(id)
    }

    const selectFile = async (path: any , name: any ) => {
        handleDocumentPopClose('');
        setSelectDocument(name);
        setProfileImageDoc(path)
        setSendButton(true)
    }


    return(

        <>
            <PopupState variant="popover" popupId="demoMenu">
                {(popupState) => (
                    <React.Fragment>
                        <input
                            type="file"
                            hidden
                            ref={inputAtta}
                            name={"file"}
                            onChange={onChangeMsgImage}
                        />
                        <IconButton aria-label="Attachment" style={{padding: 5}} {...bindTrigger(popupState)}>
                            <AttachFile style={{cursor: "pointer",  color: state === isHoveringAt ? COLORS.basfLightOrange : COLORS.basfOrange }} data-info="isHoveringAt" onMouseEnter={() => setState('isHoveringAt')} onMouseLeave={() => setState('')}></AttachFile>
                        </IconButton>
                        <Menu {...bindMenu(popupState)} style={{marginTop: "-3%", marginLeft: "-2%"}}>
                            <MenuItem  onClick={() => handleAttachOpenClick(popupState)} style={{ fontSize: 13}}
                            ><Computer className={clsx(classes.iconStyle)}
                            /> From Computer</MenuItem>

                            <MenuItem   style={{ fontSize: 13}}
                                        onClick={() => handleDocumentPopClose(popupState)}
                            ><FolderOpen className={clsx(classes.iconStyle)}/> Documents</MenuItem>
                        </Menu>
              </React.Fragment>
                )}
            </PopupState>
            {stateDocument &&
                <Dialog open={stateDocument} onClose={handleDocumentPopClose} fullWidth={true} maxWidth={"xs"}>
                    <DialogTitle>Documents</DialogTitle>
                    <DialogContent style={{maxHeight: "350px", overflow: "scroll"}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <>
                                <a className={clsx(classes.button)} onClick={() => backClick('')}>Main</a>
                                {folders && folders.map((fold: any, ind: any) => {
                                    if(fold.id) {
                                        return (
                                          <a className={clsx(classes.button)} onClick={() => backClick(fold.id)} style={{marginLeft: "1%"}}> {' > '+fold.name}</a>
                                        )
                                    }
                                })}
                                </>
                            </Grid>
                            <Grid item xs={12}>
                                <h4>Folder</h4>
                                {folderList && folderList.data.folder.map((folder: any, index: any) => {
                                    let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.updated_at}</Moment>
                                    let id = folder.id;
                                    let name = folder.name;
                                    return (
                                      <>
                                          <Card key={index} style={{width: "100%", marginBottom: 4}} className={clsx(classes.folderFile)} onClick={() => clickFolder(id , name)}>
                                              <CardHeader
                                                avatar={
                                                    <FolderOpen/>
                                                }

                                                title={name}
                                                subheader={time}
                                              />
                                          </Card>
                                          <Divider/>
                                      </>
                                    )
                                })}

                            </Grid>

                            <Grid item xs={12}>
                                <h4>File</h4>
                                {folderList && folderList.data.files.map((folder: any, index: any) => {
                                    let time = <Moment format={"MMM Do YYYY, h:mm:ss a"}>{folder.updated_at}</Moment>
                                    let nameShow = folder.type === 'FILE' ? folder.fileName : folder.name;
                                    let icon = folder.type === 'FILE' ? <Description/> : <Link/>
                                    return (
                                      <>
                                              <Card key={index} style={{width: "100%", marginBottom: 4}} className={clsx(classes.folderFile)} onClick={() => selectFile(folder.name , folder.fileName)}>
                                                  <CardHeader
                                                    avatar={
                                                        icon
                                                    }
                                                    title={nameShow}
                                                    subheader={time}
                                                    action={
                                                        <IconButton aria-label="settings">
                                                            <MoreVert />
                                                        </IconButton>
                                                    }
                                                  />
                                              </Card>
                                          <Divider/>
                                      </>
                                    )
                                })}

                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDocumentPopClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default AttachmentPop;

import React, { useCallback, useEffect } from "react";

import { COLORS} from '../../utils/Colors';
import { Contacts, HelpOutline, QueryBuilder } from "@material-ui/icons";
import { Avatar, Divider, IconButton, ListItem, Menu, MenuItem } from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import SettingAction from "../../stores/actions/settingAction";

const useStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 13 ,
    marginRight: 5
  },

  linkCss: {
    textDecoration: "none" ,
    display:"flex",
    color: COLORS.basfBlack,
    backgroundColor: COLORS.basfPaleLightGreen,
    borderRadius: 10,
    marginTop: 3,
    "&:hover": {
      backgroundColor: COLORS.hoverColorSecond,

    },
  }

}))

interface Props {
    title: string;
    id: any;
}

const Footer: React.FC<Props> = ({ title, id }) => {
  const classes = useStyles();
  const { companyData  } = useSelector(
    (state: RootState) => state.setting
  );
  const navigator = useNavigate();
  const dispatch = useDispatch()<any>;

  const getCompanyList = useCallback( async () => {
    await dispatch(SettingAction.getCompanyData({}))
  } , [dispatch])

  // useEffect(() => {
  //   getCompanyList()
  // } , [getCompanyList])

  const loadCompany = () => {
    getCompanyList();
  }

  const setDefaultCompany = async (e: any) => {
    const company_id = e;
    const result = await dispatch(SettingAction.setDefaultCompany({company_id}))
    if(result && result.status) {
      navigator('/home')
    }
  }

    return (
        <div
            style={{
                display: "flex",
                position:"fixed",
                color: COLORS.white,
                backgroundColor: COLORS.primary,
                fontWeight: "bold",

                bottom: 0,
                height: 60,
                width: 260
            }}
        >
          {/*<Avatar style={{*/}
          {/*  width: 32,*/}
          {/*  height: 32,*/}
          {/*  backgroundColor: COLORS.basfRed*/}
          {/*}}>{title.substring(0, 1)}</Avatar>*/}
          {/*  <ListItem style={{marginTop: "4%"}}>*/}
          {/*    <h3 style={{color:COLORS.basfWhite}}>{title}</h3>*/}
          {/*  </ListItem>*/}
          <PopupState variant="popover" popupId="demoMenu" >
            {(popupState) => (
              <React.Fragment>
                <div aria-label="settings" {...bindTrigger(popupState)} style={{cursor: "pointer" , display:"flex"}} >
                  <ListItem onClick={() => loadCompany()}>
                    <Avatar style={{
                      width: 32,
                      height: 32,
                      backgroundColor: COLORS.basfRed
                    }}>{title.substring(0, 1)}</Avatar>
                    <div style={{color:COLORS.basfWhite  , marginLeft: "5px"}}>{title}</div>
                  </ListItem>
                </div>
                <Menu {...bindMenu(popupState)} style={{marginTop: -40}}>
                  <MenuItem  style={{ fontSize: 14, fontWeight: "bold"}}>Change Project</MenuItem>
                  <Divider></Divider>
                  {companyData && companyData.data.map((company: any, index: any) => {
                    let selectedColor = id === company.id ? COLORS.basfLightGreen : '';
                    return(
                      <MenuItem style={{ fontSize: 14 , display:"flex" }} className={clsx(classes.linkCss)} onClick={() => setDefaultCompany(company.company_id)}>
                        <Link to={"/contact-form"} style={{ textDecoration: "none" , display:"flex" , color: COLORS.grey.dark}}>
                        <Avatar style={{ backgroundColor: selectedColor ,
                          width: 20,
                          height: 20, marginRight: "4%" }} aria-label="recipe"
                          alt={company.company_name[0]}
                        >
                          <span style={{fontSize: 12}}>{company.company_name[0]}</span>
                      </Avatar>{company.company_name}</Link></MenuItem>
                    )
                  })}
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </div>
    );
};

export default Footer;

import TaskTypes  from "../types/taskType";

interface TypeAction {
  type: string,
  payload?: any,
}




const initial_state = {
  projects: null,
  projectDetails: null,
  statusList: null,
  taskList: null,
  taskGantt: null,
  allocateList: null,
  statusListOnly: null,
  calenderData: null,
}

const taskReducer = (state = initial_state , action: TypeAction) => {
  switch (action.type) {
    case TaskTypes.SET_PROJECT:
      return {
        ...state,
        projects: action.payload
      }

    case TaskTypes.SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload
      }

    case TaskTypes.SET_PROJECT_STATUS:
      return {
        ...state,
        statusList: action.payload
      }

    case TaskTypes.SET_PROJECT_TASK:
      return {
        ...state,
        taskList: action.payload
      }

    case TaskTypes.SET_PROJECT_GANTT:
      return {
        ...state,
        taskGantt: action.payload
      }

    case TaskTypes.SET_ALLOCATE:
      return {
        ...state,
        allocateList: action.payload
      }

    case TaskTypes.SET_STATUS:
      return {
        ...state,
        statusListOnly: action.payload
      }

    case TaskTypes.SET_CALENDER_DATE:
          return  {
            ...state,
            calenderData: action.payload
          }

    default:
      return state
  }
}


export default taskReducer;

import styled from "styled-components";

export const Wrapper = styled.div`
  font-family: "TT Norms Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-variant: tabular-nums;
  width: 100%;

  .h2 {
    text-align: center;
    color: white;
    margin-top: 100px;
    font-size: 28px;
  }

  .button {
    border-radius: 4px;
    background-color: #004a96;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: 15px;
    width: 220px;
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 20px;
    box-shadow: 0 10px 20px -8px rgba(0, 0, 0, 0.7);
  }

  .button {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .button:after {
    content: "<<";
    position: absolute;
    opacity: 0;
    top: 15px;
    left: -110px;
    transition: 0.5s;
  }

  .button:hover {
    padding-right: 8px;
    padding-left: 24px;
  }

  .button:hover:after {
    opacity: 1;
    right: 10px;
  }

  h3 {
    text-align: center;
    color: white;
    margin-top: 5px;
  }

  .h4 {
    text-align: center;
    color: white;
    margin-bottom: 150px;
  }

  img {
    max-width: 250px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  footer {
    position: absolute;
    bottom: 0;
    display: block;
    width: 100%;
    height: 60px; /* Height of the footer */
  }
`;

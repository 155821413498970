import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import {
  Avatar, Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider,
  IconButton,
  List,
  ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Tooltip,
  Typography
} from "@material-ui/core";
import {
  Chat,
  Check,
  Delete,
  MoreVert, Settings,
  Unsubscribe,
  VerifiedUser
} from "@material-ui/icons";
import SettingAction from "../../stores/actions/settingAction";
import Moment from "react-moment";
import { COLORS } from "../../utils/Colors";
import { useNavigate } from "react-router-dom";
import CompanySidePop from "./CompanySidePop";
import Grid from "@material-ui/core/Grid";


interface Props {

}

const CompanyList: React.FC<Props> = ({}) => {
  const dispatch = useDispatch()<any>;
  const [openRemoveMember , setOpenRemoveMember] = useState(false);
  const [companyMemberId, setCompanyMemberId] = useState("");
  const [screenHeight, setScreenHeight ] = useState((window.innerHeight - 168));
  const { companyData  } = useSelector(
    (state: RootState) => state.setting
  );

  const { user  } = useSelector(
    (state: RootState) => state.auth
  );

  const navigator = useNavigate();

  const getCompanyList = useCallback( async () => {
    await dispatch(SettingAction.getCompanyData({}))
  } , [dispatch])

  useEffect(() => {
    getCompanyList()
  } , [getCompanyList])


  const setDefaultCompany = async (e: any) => {
    const company_id = e;
    const result = await dispatch(SettingAction.setDefaultCompany({company_id}))
    if(result && result.status) {
        navigator('/home')
    }
  }

  const handleDeletePop = (e: any) => {
      setOpenRemoveMember(!openRemoveMember);
      setCompanyMemberId(e)
  }

  const removeMembers = async (e: any) => {
      const remove_company_member = companyMemberId;
      const result = await dispatch(SettingAction.removeMember({remove_company_member}))
        if(result.status) {
          await dispatch(SettingAction.getCompanyData({}))
          setOpenRemoveMember(!openRemoveMember);
        }
  }

  const handleResize = () => {
    setScreenHeight((window.innerHeight - 168))
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
  })


  return (
      <>
        <Grid style={{
          maxHeight: screenHeight+"px",
          overflowX: "scroll",
          alignItems: "center",
          bottom: 0,
        }}>
        {companyData && companyData.data.map((company: any, index: any) => {
            const bgColor = (user && user.companyId === company.company_id) ? COLORS.basfPaleLightBlue : '';
            const companyId = company.company_id;
            const iconColor =  (user && user.companyId === company.company_id) ? COLORS.basfDarkGreen : COLORS.basfRed;
            const action = (user && user.role === 'Vendor') ? <CompanySidePop companyId={companyId} isDefault={company.is_default} companyName={company.company_name}/> : '';
            return (

              <Card style={{marginTop: 10, backgroundColor: bgColor}} key={index}>
                <CardHeader
                  avatar={
                    <Avatar style={{ backgroundColor: COLORS.basfRed }} aria-label="recipe">
                      {company.company_name[0]}
                    </Avatar>
                  }
                  action={action}
                  title={company.company_name}
                  subheader={<Moment format={"MMM Do YYYY, h:mm:ss a"}>{company.created_at}</Moment>}
                >
                </CardHeader>
                {user && user.role === 'Vendor' &&
                <CardContent>
                  <Typography variant="body2"  style={{marginLeft: "4%"}}>
                    <p style={{marginLeft: "5%"}}>Project Members</p>
                    <Divider></Divider>
                    {company.members.length > 0 && company.members.map((members: any, j: any) => {
                      const pending = members.status ? <Tooltip title={"Verified"}>
                        <IconButton><VerifiedUser style={{color: COLORS.basfDarkGreen , fontSize: "18px"}} titleAccess={"Accepted"}/></IconButton></Tooltip>
                        : <Tooltip title={"Pending for acceptance"}><IconButton><Unsubscribe style={{color:COLORS.basfOrange}} titleAccess={'Pending for acceptance'} /></IconButton></Tooltip>;
                      const memberId = members.id;
                      const isAdmin = (user && user.id === members.member_id) ? <p style={{backgroundColor: COLORS.basfLightGreen, padding: 3, borderRadius: 10 , color: COLORS.basfWhite}}>It's You</p>  : '';
                      const deleted = members.member_id === company.created_by ? '' : <Tooltip title={"Delete"}>
                        <IconButton onClick={() => handleDeletePop(memberId)}>
                          <Delete style={{color: COLORS.basfRed , fontSize: "18px"}}/>
                        </IconButton>
                      </Tooltip>;

                        return (
                          <List style={{ width: '100%' }}>
                            <ListItem
                              key={j}
                            >
                              <ListItemAvatar>
                                <Avatar style={{textTransform: 'capitalize', backgroundColor: COLORS.basfLightBlue}}>
                                  {members.email[0]}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText primary={members.email}
                                            secondary={<Moment format={"MMM Do YYYY, h:mm:ss a"}>{members.created_at}</Moment>} />
                                {isAdmin}
                                {pending}
                                {deleted}
                            </ListItem>
                          </List>
                        )
                    })
                    }
                  </Typography>
                </CardContent>
                }
                <CardActions disableSpacing>
                  <Button disabled={user && user.companyId === company.company_id} aria-label="add to favorites" onClick={() => {
                    if (user && user.companyId != company.company_id) {
                      setDefaultCompany(company.company_id)
                    }
                  }} startIcon={<Check style={{color: iconColor }} />}>
                    {user && user.companyId != company.company_id ?
                          <p>Set As Default Project </p> : <p>Selected</p>
                    }
                  </Button>
                </CardActions>
              </Card>
            )
        })}

        <Dialog
          open={openRemoveMember}
          keepMounted
          onClose={() => setOpenRemoveMember(!openRemoveMember)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Remove Member?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure to remove the member from this company ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenRemoveMember(!openRemoveMember)}>Cancel</Button>
            <Button onClick={removeMembers}>Confirm</Button>
          </DialogActions>
        </Dialog>
        </Grid>
      </>
  )
}

export default CompanyList


const DiscussionTypes = {
  SET_SEND_MESSAGE: "SET_SEND_MESSAGE",
  RESET_SEND_MESSAGE: "RESET_SEND_MESSAGE",
  SET_TEAM_DAFULT: "SET_TEAM_DAFULT",
  SET_TEAM: "SET_TEAM",
  SET_TEAM_DETAILS: "SET_TEAM_DETAILS",
  SET_TEAM_PERSONL: "SET_TEAM_PERSONL",
  SET_MESSAGE: "SET_MESSAGE",
  SET_SINGLE_MSG: "SET_SINGLE_MSG",
  SET_COMPANY_MEMBER: "SET_COMPANY_MEMBER",
  SET_MESSAGE_FILES: "SET_MESSAGE_FILES",
  SET_TEAM_MEMBER: "SET_TEAM_MEMBER",
  SET_SEARCH_USER: "SET_SEARCH_USER",
  DELETE_CHAT_MESSAGE: "DELETE_CHAT_MESSAGE",
};

export interface AddTeam {
  name: string;
  type: string;
}

export interface AddTeamDirect {
  memberId: string;
  type: string;
}

export interface Teams {
  type: string;
  company_id: string;
  get_type: boolean;
}

export interface TeamDetails {
  team_id: string;
}

export interface Message {
  team_id: string;
  parent_message_id: string | null;
  message_type: string;
  content: string;
  file: string | null;
  fileName: string | null;
}

export interface MeetingMessage {
  team_id: string;
  parent_message_id: string | null;
  topic: string;
  start_time: string;
  agenda: string | null;
}

export interface CompanyMembers {
  username: string;
  id: string;
}

export interface GetMessage {
  team_id: string | null;
  limit: string | null;
}

export interface GetSingleMessage {
  message_id: string | null;
}

export interface CompnayMember {
  company_id: string;
}

export interface UploadFile {
  file: string;
}

export interface DeleteFile {
  file_name: string;
}

export interface SearchMessage {
  team_id: string;
  keyword: string;
}

export interface AddTeamMember {
  team_id: string;
  member_id: string;
  type: string;
}

export interface GetTeamMember {
  teamId: any;
}

export interface SearchTeamMember {
  teamId: any;
  keyword: string;
}

export default DiscussionTypes;

import React, { useCallback, useEffect, useState } from "react";
import { CheckBoxOutlined } from "@material-ui/icons";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { decode } from "js-base64";
import Grid from "@material-ui/core/Grid";
import Moment from "react-moment";
import { Card, CardHeader } from "@material-ui/core";

import illustration from "../../assets/image/auth.png";
import HomeDefault from "../../layouts/home/HomeDefault";
import { RootState } from "../../stores";
import TaskAction from "../../stores/actions/taskAction";
import moment from "moment";

const data = [
  ["Task", "Hours per Day"],
  ["New Project Today Start 1", 1],
  ["NEw task 2", 1],
  ["New test task", 1],
  ["New Task IS dne", 1],
];

export const options = {
  title: "Tasks",
  is3D: true,
};

export const TaskPieChart = () => {
  const { projectId } = useParams();

  const [pieChart, setPieChart] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch()<any>;
  const { projectDetails, taskGantt } = useSelector(
    (state: RootState) => state.tasks
  );

  const getTaskProjects = useCallback(async () => {
    setLoading(true);
    const projectid = decode(projectId!);
    await Promise.all([
      dispatch(TaskAction.getProjectDetails({ projectid })),
      dispatch(TaskAction.getProjectTasksGantt({ projectid })),
    ]);

    setLoading(false);
  }, [dispatch]);

  //Call callback function
  useEffect(() => {
    getTaskProjects();
  }, [getTaskProjects]);

  useEffect(() => {
    if (taskGantt?.data) {
      const result = [["Task", "Hours per Day"]];

      taskGantt.data.forEach((item: any) => {
        console.log(
          moment(item.start, "YYYY-MM-DD 00:00:00"),
          moment(item.end, "2023-MM-DD 00:00:00")
        );

        const endDate = moment([2007, 0, 29]);
        const startDate = moment([2007, 0, 28]);

        const time = endDate.diff(startDate, "days");

        result.push([item?.name, time]);
      });

      console.log(result);
      setPieChart(result);
    }
  }, []);

  return (
    <HomeDefault
      illustrationBackground={illustration}
      image={illustration}
      heading="Tasks"
      icon={<CheckBoxOutlined />}
    >
      <>
        {loading ? null : (
          <>
            <Grid item xs={12} style={{ marginBottom: "24px" }}>
              {projectDetails &&
                projectDetails.data.map((project: any, index: any) => {
                  let time = (
                    <Moment format={"MMM Do YYYY, h:mm:ss a"}>
                      {project.created_at}
                    </Moment>
                  );
                  return (
                    <Card key={index} style={{ width: "100%" }}>
                      <CardHeader
                        title={project.project_name}
                        subheader={time}
                      />
                    </Card>
                  );
                })}
            </Grid>
            <Chart
              chartType="PieChart"
              data={pieChart}
              options={options}
              width={"100%"}
              height={"400px"}
            />
          </>
        )}
      </>
    </HomeDefault>
  );
};

import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/image/logo.svg";

export const Container = styled.div`
  background-color: #f1f5f9;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: center;
    justify-content: center;
  }

  .landing-card {
    background: white;
    box-shadow: 0 1px 5px rgb(0 0 0 / 0.2);
    border-radius: 0.1rem;
    max-width: 95%;
  }

  .landing-card_header {
    background-color: #21a0d2;
    padding: 16px 16px 14px;
    display: flex;
  }

  .landing-card_header img {
    height: 3.5rem;
  }

  .landing-card_body {
    padding: 24px;
  }

  .landing-card_body h2 {
    margin: 0 0 12px;
    color: #0f172a;
  }

  .landing-card_body small {
    color: #334155;
    font-size: 14px;
  }

  .landing-card_footer {
    padding: 0 20px 20px;
    display: flex;
    justify-content: center;
  }

  .landing-card_footer button {
    font-size: 18px;
    background-color: #21a0d2;
    border: none;
    outline: none;
    color: white;
    padding: 8px 24px;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgb(0 0 0 / 0.2);
  }

  .landing-card_footer button:hover {
    transform: scale(1.01);
  }

  .landing-card_footer button:active {
    transform: scale(1);
  }

  .footer {
    border-top: 0.5px solid #dedede;
    background-color: white;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 12px 24px;
    color: #21a0d2;
    margin-top: 24px;
  }

  .footer a {
    color: #21a0d2;
    text-decoration: none;
    font-size: 14px;
  }

  .footer span {
    color: #21a0d2;
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
  }

  @media (min-width: 640px) {
    .landing-card {
      max-width: 70%;
    }
  }

  @media (min-width: 768px) {
    .landing-card {
      max-width: 60%;
    }
  }

  @media (min-width: 1024px) {
    .landing-card {
      max-width: 35%;
    }
  }

  @media (min-width: 1280px) {
    .landing-card {
      max-width: 30%;
    }
  }

  @media (min-width: 1536px) {
    .landing-card {
      max-width: 30%;
    }
  }

  .container {
    padding-left: 10%;
    padding-right: 10%;
  }

  ol {
    color: indigo;
  }

  a {
    text-decoration: none;
    color: indigo;
  }

  p {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 25px;
    margin: 0;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  h6 {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }

  h3 {
    color: #004696;
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .title {
    margin-top: 50px;
    font-size: 24px;
    font-weight: 500;
  }
`;

export const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <div className="landing-card_header">
        <img src={Logo} alt="Logo" />
      </div>

      <div className="body">
        <div className="container">
          <div className="content">
            <h1 className="title">Privacy Policy</h1>
            <br />
            <br />
            <p>
              BASF is pleased about your visit to our website and your interest
              in our company.
            </p>
            <br />
            <br />
            <p>
              The topic of data protection has the highest priority at BASF.
              Therefore, we would like to inform you in detail about it in the
              following:
            </p>
            <br />
            <br />
            <ol type="A">
              <li>
                <a href="#Data-pro-A">
                  Who is responsible for data processing and who is our data
                  protection officer?
                </a>
              </li>
              <li>
                <a href="#Data-pro-B">
                  What data do we process, for what purposes, for how long and
                  on what legal basis?
                </a>
              </li>
              <li>
                <a href="#Data-pro-C">
                  Are you obliged to provide us with this data?
                </a>
              </li>
              <li>
                <a href="#Data-pro-D">
                  To whom do we pass on your personal data?
                </a>
              </li>
              <li>
                <a href="#Data-pro-E">
                  How do we safeguard your personal data?
                </a>
              </li>
              <li>
                <a href="#Data-pro-F">
                  What rights do you have with respect to your personal data?
                </a>
              </li>
              <li>
                <a href="#Data-pro-G">
                  Where can you complain regarding any data privacy issues?
                </a>
              </li>
              <li>
                <a href="#Data-pro-H">Data protection for minors</a>
              </li>
              <li>
                <a href="#Data-pro-I">
                  Website and external privacy statement for all of the BASF
                  entities in Nigeria, Kenya, South Africa, East Africa (
                  Ethiopia and Djibouti) and Zambia
                </a>
              </li>
            </ol>
            <h1>
              A. Who is responsible for data processing and who is our data
              protection officer?
            </h1>
            <p>Our data protection officer is:</p>
            <p>
              Mr.Ulf-Gregor Schulz
              <br />
              Data Protection Officer – BASF FZE
              <br />
              BASF FZE, Floor 15, Tower B, JAFZA One, Exit 17,
              <br />
              Sheikh Zayed Road, Dubai, UAE
              <br />
              Phone: +971 4 8072-218
              <br />
              E-Mail: <a href=""> data-protection.uae@basf.com </a>
            </p>

            <h1>
              B. What data do we process, for what purposes, for how long and on
              what legal basis?
            </h1>
            <p>
              In the following, we inform you about the data processing on our
              site (a), its purposes and legal basis (b) as well as the
              respective storage period and, if applicable, specific objection
              and removal options (c).
            </p>

            <h3>1. Log files</h3>
            <p>a. Data processing </p>
            <p>
              If you visit our website for information purposes only, i.e. if
              you do not register or transmit data in any other way (e.g. via a
              contact form), the following information is automatically
              transmitted from your browser to our server:{" "}
            </p>

            <p> IP address of your device </p>
            <p> Information about your browser </p>
            <p> Name of the website from which you visit us </p>
            <p> Name of the visited page (URL) or the opened file </p>
            <p> Date and time of your visit </p>
            <p> Status information such as error messages </p>
            <p>
              {" "}
              Transferred data volume and the access status (file transferred,
              file not found, etc.){" "}
            </p>
            <p>
              {" "}
              Operating system and version of your computer’s operating system
              and the name of your access provider{" "}
            </p>

            <p>
              b. Purposes and legal basis When you visit the BASF web site, we
              use the IP address and other information automatically transmitted
              to our server by your browser under B. 1. a. to{" "}
            </p>

            <p> (i) provide you with the requested content </p>

            <p>
              {" "}
              (ii) ensure the security and stability of our website and to trace
              unauthorized use{" "}
            </p>

            <p> (iii) enable a comfortable use of our website. </p>

            <p>
              {" "}
              The legal basis for the data processing is Art. 6 para. 1 sentence
              1 lit. f GDPR. Our legitimate interest follows from the above
              listed purposes for data processing. Under no circumstances do we
              use the collected data for the purpose of drawing conclusions
              about your person.{" "}
            </p>

            <p>c. Storage period </p>
            <p>
              The data will be deleted as soon as they are no longer necessary
              for the purpose for which they were collected. In the case of the
              collection of data for the provision of the website, this is the
              case when the respective session is ended. In the case of storage
              of the data in log files, this is the case after seven days at the
              latest. Storage beyond this period is possible. In this case, the
              IP addresses are anonymized by deleting the last eight bits, so
              that an identification of you is no longer possible.{" "}
            </p>

            <h3>2. Cookies</h3>
            <p>a. Data processing and respective legal basis </p>
            <p>
              We use cookies on our website. These are text information that can
              be stored in the browser on the device of the viewer to a visited
              website (web server, server). The cookie is either sent from the
              web server to the browser or generated in the browser by a script
              (JavaScript). The web server can read this cookie information
              directly from the server when the user visits this page again or
              transfer the cookie information to the server via a script of the
              website. Cookies do not cause any damage to your device, do not
              contain viruses, trojans or other malware.{" "}
            </p>
            <p>
              Information is stored in the cookie that is related to the
              specific device used. This does not mean, however, that we obtain
              direct knowledge of your identity.{" "}
            </p>

            <p> We generally use the following cookies: </p>

            <h6>Strictly necessary cookies</h6>
            <p>
              These cookies are necessary for the functioning of the website. In
              general, these cookies are only set in response to actions you
              take in response to a service request, such as setting your
              privacy preferences, logging in, or filling out forms. You can set
              your browser to block these cookies or to notify you about these
              cookies. However, some areas of the website may not function
              properly.
            </p>
            <p>
              The legal basis for the processing of these cookies is Art. 6
              para. 1 sentence 1 lit. f GDPR. Our legitimate interest follows
              from the data processing purposes listed under B.1.b.
            </p>

            <h6>Performance cookies</h6>
            <p>
              These cookies enable us to count visits and traffic sources so
              that we can measure and improve the performance of our website.
              They help us answering questions about which pages are most
              popular, which are least used and how visitors move around the
              site. All information collected by these cookies is aggregated and
              therefore anonymous. If you do not allow these cookies, we cannot
              know when you visited our website.{" "}
            </p>

            <p>
              The legal basis for the processing of these cookies is your
              consent pursuant to Art. 6 para. 1 sentence 1 lit. a GDPR, which
              you have given us by making your selection in the cookie banner or
              in our Privacy Preference Center.{" "}
            </p>
            <p>
              {" "}
              You have the right to revoke your consent at any time, without
              affecting the lawfulness of the processing carried out on the
              basis of the consent until revocation. To do so, please change the
              settings in the Privacy Preference Center.
            </p>

            <h6>Functional cookies</h6>
            <p>
              These cookies enable the website to provide enhanced functionality
              and personalization. They may be set by us or by third parties
              whose services we use on our pages. If you do not allow these
              cookies, some or all of these services may not function properly.
            </p>
            <p>
              The legal basis for the processing of these cookies is your
              consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR,
              which you have given us by making your selection in the cookie
              banner or in our Privacy Preference Center.{" "}
            </p>
            <p>
              You have the right to revoke your consent at any time, without
              affecting the lawfulness of the processing carried out on the
              basis of the consent until revocation. To do so, please change the
              settings in the Privacy Preference Center.
            </p>

            <h6>Cookies for marketing purposes</h6>
            <p>
              These cookies can be set via our website. They may be used by our
              marketing partners to profile your interests and show you relevant
              ads on other websites. If you do not allow these cookies, you will
              experience less targeted advertising.
            </p>
            <p>
              The legal basis for the processing of these cookies is your
              consent in accordance with Art. 6 para. 1 sentence 1 lit. a GDPR,
              which you have given us by making your selection in the cookie
              banner or in our Privacy Preference Center.{" "}
            </p>
            <p>
              {" "}
              You have the right to revoke your consent at any time, without
              affecting the lawfulness of the processing carried out on the
              basis of the consent until revocation. To do so, please change the
              settings in the Privacy Preference Center.
            </p>
            <p> b. Specific use of cookies, purposes and storage period </p>
            <p>
              Specifically, we use the following cookies, depending on the
              cookie preferences you set in the Privacy Preference Center. Only
              the strictly necessary cookies are activated by default. If you do
              not want this either, you have the option of generally rejecting
              cookies in your browser. In this case, the functionality of the
              visited website may be impaired.{" "}
            </p>
            <p>Cookie List </p>
            <p>
              {" "}
              A cookie is a small piece of data (text file) that a website –
              when visited by a user – asks your browser to store on your device
              in order to remember information about you, such as your language
              preference or login information. Those cookies are set by us and
              called first-party cookies. We also use third-party cookies –
              which are cookies from a domain different than the domain of the
              website you are visiting – for our advertising and marketing
              efforts. More specifically, we use cookies and other tracking
              technologies for the following purposes:{" "}
            </p>

            <div id="ot-sdk-cookie-policy"></div>

            <p style={{ marginBottom: "12px" }}>
              Further details about the respective cookie providers can be found
              in the Privacy Preference Center, under the respective cookie
              categories.
            </p>

            <button id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Settings
            </button>

            <h3>3. Contact form and e-mail</h3>
            <p>a. Data processing </p>
            <p>
              Our web pages contain contact forms as well as links to send us an
              e-mail directly. If you use one of these contact forms, the data
              which you provide in such forms will be transmitted to us and
              processed. The mandatory data that must be filled in for
              electronic contact via the respective contact form are marked with
              (*). If you provide us with additional data, this is done
              voluntarily.{" "}
            </p>

            <p>
              If you send us an e-mail, we will process the associated metadata
              and the content of the message.
            </p>
            <p>b. Purpose and legal basis</p>
            <p>
              Your data will be processed to enable us to contact you, to
              process your request and to provide you with our respective
              services, to prevent misuse of the contact form and to ensure the
              security of our information technology systems.
            </p>
            <p>
              The legal basis for the processing of data classified as being
              mandatory is Art. 6 para. 1 sentence 1 lit. f GDPR. The
              aforementioned purposes also include a legitimate interest in the
              processing of the data. If the purpose of the contact is the
              conclusion of a contract, the additional legal basis for the
              processing is Art. 6 para. 1 sentence 1 lit. b GDPR. The legal
              basis for the processing of the data that you have voluntarily
              provided us with is your consent in accordance with Art. 6 para. 1
              sentence 1 lit. a GDPR.{" "}
            </p>
            <p>c. Storage period </p>
            <p>
              The personal data of the person concerned will be deleted or made
              unavailable as soon as the purpose of the storage does no longer
              apply. Furthermore, data may be stored if this has been provided
              for by the European or national legislator in EU regulations, laws
              or other regulations to which the person responsible is subject.
              Data will also be deleted or made unavailable when a storage
              period prescribed by the above-mentioned provisions expires,
              unless there is a need to continue storing the data for the
              purpose of concluding or fulfilling a contract.{" "}
            </p>

            <p>
              In case of a consent, you have the right to revoke such consent at
              any time, without affecting the lawfulness of the processing
              carried out on the basis of the consent until revocation. To do
              so, please change the Cookie Settings.
            </p>

            <h3>4. Social media hyperlink</h3>
            <p>
              We have included hyperlinks to the following social media services
              on our website: LinkedIn, Facebook, Twitter, Vimeo, YouTube,
              Slideshare, flickr and Instagram.{" "}
            </p>
            <p>
              {" "}
              These hyperlinks are designed in the form of the corresponding
              logos and are stored on our own server. This means that when our
              website is loaded for the first time, no data about you as a user
              are transferred to the respective provider. You will be directed
              to the respective website only if you click on the logos. Further
              data about you may be processed on this website.
            </p>
            <p>
              {" "}
              We have no influence on this data processing on the respective
              linked websites.
            </p>

            <h3>5. Livechat</h3>
            <p>a. Data processing and purposes </p>
            <p>
              {" "}
              We have integrated the chatbot/livechat “”Hunt’r”” and WhatsApp on
              the website. This is a service of BASF. The chatbot and the
              associated data processing will only be started if you have
              previously either clicked the “”Accept All Cookies”” button in the
              cookie banner or activated “”Functional Cookies”” and “”Targeting
              Cookies”” in the Privacy Preference Center. By default, “”
              Hunt’r”” and WhatsApp is not loaded and therefore no personal data
              is collected from you.
            </p>
            <p>
              Our chatbot supports you around the clock with general questions
              about product promotions and about BASF. If the chatbot cannot
              answer your request, it will be passed on to the LiveChat from our
              BASF team.
            </p>
            <p>
              The participation in a chat is voluntary and you alone decide
              which personal data you disclose about yourself in the consulting
              chat. The data provided by you will be processed exclusively for
              the purpose of carrying out the enquiry and its quality assurance.
              Only data which is necessary to provide our consulting service or
              to handle your request will be used. This data will not be
              processed by besides anyone else than the service providers
              mentioned above and us.
            </p>
            <p>
              The transfer of the data is encrypted. Technical and
              organizational security measures are used to protect the data from
              intentional or accidental manipulation, access by unauthorized
              persons, changes, loss and destruction.
            </p>
            <p>b. Legal basis </p>
            <p>
              The legal basis for the processing of the data you have
              voluntarily provided us within the chat is your consent in
              accordance with Art. 6 para. 1 sentence 1 lit. a GDPR. You give us
              this consent by accepting the necessary cookies.
            </p>
            <p>
              Insofar as the chat is in connection with a potential application
              to BASF, the legal basis for processing is Art. 6 Para. 1 sentence
              1 lit. b) GDPR. Here we use your personal data only to answer your
              questions. The chat record will not be linked to a concrete
              application.
            </p>
            <p>
              Apart from that, we use your information for quality assurance
              purposes. As far as possible, we will anonymous them. If this
              cannot be done, we will process your personal data on the basis of
              Art. 6 Para. 1 sentence 1 lit. f) GDPR. Our legitimate interest in
              processing the data collected lies in the continuous improvement
              of our service within the framework of the chat.
            </p>
            <p>c. Storage period </p>
            <p>
              {" "}
              The chat record and its associated metadata will be deleted or
              blocked as soon as the purpose for which it was stored ceases to
              apply. Furthermore, data may be stored if this has been provided
              for by the European or national legislator in EU regulations, laws
              or other regulations to which the person responsible is subject.
              Data will also be deleted or made unavailable when a storage
              period prescribed by the above-mentioned provisions expires,
              unless there is a need to continue storing the data for the
              purpose of concluding or fulfilling a contract.{" "}
            </p>
            <p>
              In case of a consent, you have the right to revoke such consent at
              any time, without affecting the lawfulness of the processing
              carried out on the basis of the consent until revocation. Please
              inform your respective contact partner at our company about this.
            </p>

            <h1>C. Are you obliged to provide us with this data? </h1>
            <p>
              When you visit our website, the information specified in section
              B. 1. a. as well as the information from the cookies classified as
              strictly necessary is processed automatically. The transfer of
              this information is voluntary. Without the provision of this
              personal data, we cannot display the requested page.{" "}
            </p>
            <p>If you </p>
            <p>
              allow us to use cookies that are not classified as strictly
              necessary or{" "}
            </p>
            <p>want to see embedded YouTube videos </p>
            <p>the transfer of the information is voluntary. </p>
            <p>
              If you contact us, we cannot answer your inquiry in the chosen way
              without the provision of the personal data required in the
              individual case. As far as cookies are concerned, the lack of
              consent can lead to a restriction of the functionality of the
              website or parts of it. Embedded videos cannot be played without
              your consent.
            </p>

            <h1>D. To whom do we pass on your personal data?</h1>
            <p>
              Within our company, only persons and departments are granted
              access to your personal data as far as they need it to fulfil the
              abovementioned purposes.{" "}
            </p>
            <p>
              We also involve service providers. These service providers will
              only act on our instructions and are contractually obliged to
              comply with the applicable data protection requirements.{" "}
            </p>
            <p>
              Otherwise, we will only pass on your data to third parties if:
            </p>
            <p>
              you have given your express consent in accordance with Art. 6
              para. 1 sentence 1 lit. a GDPR,{" "}
            </p>
            <p>
              the disclosure pursuant to Art. 6 para. 1 sentence 1 lit. f GDPR
              is necessary for the assertion, exercise or defense of legal
              claims and there is no reason to assume that you have an
              overriding interest worthy of protection in not disclosing your
              data,{" "}
            </p>
            <p>
              there is a legal obligation for us to pass on the data in
              accordance with Art. 6 para. 1 sentence 1 lit. c GDPR, or{" "}
            </p>
            <p>
              this is legally permissible and, in accordance with Art. 6 para. 1
              sentence 1 lit. b GDPR, is necessary for the fulfilment of
              contractual relationships with you.
            </p>
            <p>
              A transfer of your personal data to service providers in a third
              country will only take place if the special requirements of Art.
              44 ff. GDPR are fulfilled.
            </p>

            <h1>F. What rights do you have?</h1>

            <p>
              You have certain rights under the General Data Protection
              Regulation including the right to request a copy of the personal
              information we hold about you, if you request it from us in
              writing:
            </p>
            <p>
              <b> Right to access: </b> the right to obtain access to your
              information (if we’re processing it), and certain other
              information (like that provided in this Privacy Policy);
            </p>
            <p>
              <b>Right to correct:</b> if your personal information is
              inaccurate or incomplete you have the right to have your personal
              information rectified;
            </p>
            <p>
              <b>Right to erasure: </b>this is also known as ‘the right to be
              forgotten’ and, in simple terms, enables you to request the
              deletion or removal of your information where there’s no
              compelling reason for us to keep using it. This is not a general
              right to erasure; there are exceptions. For example, we have the
              right to continue using your personal data if such use is
              necessary for compliance with our legal obligations or for the
              establishment, exercise or defense of legal claims;
            </p>
            <p>
              <b>Right to restriction of processing:</b> the right to suspend
              the usage of your personal information or limit the way in which
              we can process it. Please note that this right is limited in
              certain situations: When we are processing your personal
              information that we collected from you with your consent you can
              only request restriction on the basis of: (a) inaccuracy of data;
              (b) where our processing is unlawful and you don’t want your
              personal information erased; (c) you need it for a legal claim; or
              (d) if we no longer need to use the data for the purposes for
              which we hold it. When processing is restricted, we can still
              store your information, but may not use it further. We keep lists
              of people who have asked for restriction of the use of their
              personal information to make sure the restriction is respected in
              future;
            </p>
            <p>
              <b>Right to data portability:</b> the right to request that we
              move, copy or transfer (where technically feasible) your personal
              information in a structured, commonly used and machine-readable
              format, for your own purposes across different services;
            </p>
            <p>
              <b>Right to object:</b> the right to object to our processing of
              your personal information including where we process it for our
              legitimate interests, direct marketing;
            </p>
            <p>
              <b>Right to withdraw consent:</b> if you have given your consent
              to anything we do with your personal information, you have the
              right to withdraw your consent at any time (although if you do so,
              it does not mean that anything we have done with your personal
              information with your consent up to that point is unlawful).
            </p>
            <p>
              Exercising these rights is free of charge. However, you are
              required to prove your identity by means of a
              two-factor-authentication. We will engage reasonable efforts
              consistent with our legal duty to supply, correct or delete
              personal information about you. To exercise your rights, please
              contact us, for example by e-mail or by post. You will find the
              contact details in section A.
            </p>

            <h1>G. Where can you complain?</h1>
            <p>
              You have the right to lodge a complaint with our data protection
              officer (for contact details see Section A) or with a supervisory
              authority, in particular in the Member State of your habitual
              residence, place of work or place of the alleged infringement.
            </p>
            <p>
              In either case, you can contact our Lead Data Protection
              Authority:
            </p>
            <p>
              State Commissioner for Data Protection and Freedom of Information
              of Rhineland-Palatinate (Landesbeauftragter für den Datenschutz
              und die Informationsfreiheit Rheinland-Pfalz)
            </p>
            <p>Hintere Bleiche 34</p>
            <p>55116 Mainz</p>
            <a href="https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/">
              https://www.datenschutz.rlp.de/de/general-storage/footer/ueber-den-lfdi/kontakt/{" "}
            </a>
            <p>
              E-Mail:{" "}
              <a
                href="mailto:poststelle@datenschutz.rlp.de"
                target="_blank"
                rel="noopener"
              >
                poststelle@datenschutz.rlp.de{" "}
              </a>
            </p>

            <h1>H. Data protection for minors</h1>
            <p>
              This website is intended for persons who are at least 18 years
              old. If a minor submits personal data via this website, we will
              delete this data and not process it further as soon as we become
              aware of this fact.
            </p>

            <h1>
              I. Website and external privacy statement for all of the BASF
              entities in Nigeria, Kenya, South Africa, East Africa ( Ethiopia
              and Djibouti) and Zambia
            </h1>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-east-africa-ethoipia-and-djibouti/">
                https://promotions.basf.com/privacy-policy-east-africa-ethoipia-and-djibouti/
              </a>
            </p>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-kenya/">
                https://promotions.basf.com/privacy-policy-kenya/
              </a>
            </p>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-nigeria/">
                https://promotions.basf.com/privacy-policy-nigeria/
              </a>
            </p>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-south-africa/">
                https://promotions.basf.com/privacy-policy-south-africa/
              </a>
            </p>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-zambia/">
                https://promotions.basf.com/privacy-policy-zambia/
              </a>
            </p>
            <p>
              <a href="https://promotions.basf.com/privacy-policy-algeria/">
                https://promotions.basf.com/privacy-policy-algeria/
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="footer">
        <span>
          <Link to="/privacy-policy">Data Privacy Statement</Link>
        </span>
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
      </div>
    </Container>
  );
};

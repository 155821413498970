import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import { useDispatch } from "react-redux";
import InitiativeAction from "../../stores/actions/initiativeAction";

interface Props {

  openDialog: boolean,
  handleDialog: any,
  folderId: any,
  setSavedDocument: any,
  saveDocument: any
}

const InitiativeAddPopover: React.FC<Props> = ({openDialog , handleDialog , folderId , setSavedDocument , saveDocument}) => {
  const [initiativeName, setInitiativeName] = useState('');
  const dispatch = useDispatch()<any>;
  const [buttonLoader, setButtonLoader] = useState(false);

  const saveFolder = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    const name = initiativeName;
    const folder_id = folderId;
    const result = await dispatch(InitiativeAction.addInitiative({name , folder_id}))
    if(result && result.status) {
      setSavedDocument(!saveDocument)
      setInitiativeName('');
      handleDialog();
    }
    setButtonLoader(false);
  }

  return(
    <>
      <Dialog open={openDialog} onClose={handleDialog}
              fullWidth={true}
              maxWidth={"xs"}
      >
        <form action={'#'} onSubmit={saveFolder}>
          <DialogTitle>Add New Initiative</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Initiative Name"
              type="text"
              fullWidth
              variant="standard"
              value={initiativeName}
              onChange={(text) => setInitiativeName(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialog}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
export default InitiativeAddPopover

import React, {useEffect, useRef, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import { useDispatch } from "react-redux";
import DocumentAction from "../../stores/actions/documentAction";
import InitiativeAction from "../../stores/actions/initiativeAction";

interface Props {

  openDialogNotes: boolean;
  handleDialogNotes: any;
  folderId: any,
  setSaveInitiative: any,
  saveInitiative: any
}

const InitiativeNotesPop: React.FC<Props> = ({openDialogNotes, handleDialogNotes , folderId, setSaveInitiative , saveInitiative}) => {

  const [noteTitle, setNotesTitle] = useState('');
  const [notesDescription, setNotesDescription] = useState('');
  const dispatch = useDispatch()<any>;
  const [buttonLoader, setButtonLoader] = useState(false);

  const saveFolder = async (e: any) => {
    e.preventDefault();
    setButtonLoader(true);
    const folder_id = folderId;
    const result = await dispatch(InitiativeAction.addNotes({name: noteTitle , description: notesDescription , folder_id}))
    if(result && result.status) {
      setSaveInitiative(!saveInitiative)
      setNotesTitle('');
      setNotesDescription('')
      handleDialogNotes();
    }
    setButtonLoader(false);
  }

  console.log(openDialogNotes)


  return (
    <>
      <Dialog open={openDialogNotes} onClose={handleDialogNotes}
              fullWidth={true}
              maxWidth={"xs"}
      >
        <form action={'#'} onSubmit={saveFolder}>
          <DialogTitle>New Notes</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Title"
              value={noteTitle}
              type="text"
              fullWidth
              variant="standard"
              onChange={(text) => setNotesTitle(text.target.value)}
            />
            <br/>
            <br/>
            <TextField
              autoFocus
              margin="dense"
              id="link"
              label="Description"
              type="text"
              fullWidth
              multiline={true}
              rows={5}
              variant="standard"
              value={notesDescription}
              onChange={(text) => setNotesDescription(text.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogNotes}>Cancel</Button>
            <Button type={"submit"} disabled={buttonLoader}>Add</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default InitiativeNotesPop;

import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Wrapper } from "./HomeDefault.styles";
import { ArrowBack, Close } from "@material-ui/icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Header from "../../components/Include/Header";
import Sidebar from "../../components/Include/Sidebar";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PageHeader from "../../components/Include/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../stores";
import AuthAction from "../../stores/actions/authAction";
import { AlertState } from "../../stores/reducers/alertReducer";
import { IconButton, Snackbar } from "@material-ui/core";
import { socket } from "../../utils/Constants";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  pageBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Default = (props: {
  children: JSX.Element | string;
  illustrationBackground: string;
  heading: string;
  icon: any;
}) => {
  const { children, illustrationBackground, heading, icon } = props;
  const [open, setOpen] = useState(true);
  const [tokenIs, setTokens] = useState("");
  const sAlert: AlertState = useSelector((state: RootState) => state.alert);
  const sLoading = useSelector((state: RootState) => state.loading);
  const { user, authenticated, token } = useSelector(
    (state: RootState) => state.auth
  );
  const classes = useStyles();
  const navigator = useNavigate();
  const dispatch = useDispatch()<any>;

  const tokens: string | any = localStorage.getItem("@userData:token");

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (!tokens) {
      navigator("/");
    } else {
      setTokens(tokens);
      (async () => {
        await dispatch(AuthAction.verifyToken({ tokens }));
      })();
    }
    // if(user) {
    //   socket.emit('live_update', { userId: user.id, token: JSON.parse(tokens), type: 'connect' })
    //
    //
    //   let timer = setInterval(function(){a
    //
    //     socket.emit('live_update', { userId: user.id, token: JSON.parse(tokens), type: 'connect' })
    //   } , 100000)
    //
    //   return () => clearInterval(timer);
    // }
  }, [dispatch]);

  // handle for logout process
  const logoutHandle = async () => {
    const tokens: any = localStorage.getItem("@userData:token");
    if (user) {
      // socket.emit("live_update", {
      //   userId: user.id,
      //   token: JSON.parse(tokens),
      //   type: "disconnect",
      // });
    }
    socket.disconnect();
    await dispatch(AuthAction.signOUt({ tokens }));
    navigator("/");
  };

  return (
    <Wrapper>
      <Grid container spacing={3} direction={"column"} xs={12} lg={12} md={12}>
        {sLoading.show && (
          <Grid
            style={{
              zIndex: 9999,
              position: "fixed",
              width: "100%",
              height: "100%",
              marginTop: "0.75%",
            }}
          >
            <LinearProgress />
          </Grid>
        )}

        <Header
          open={open}
          handleDrawerOpen={handleDrawerOpen}
          logoutHandle={logoutHandle}
          heading={heading}
          icon={icon}
        />
        <div
          style={{
            display: "flex",
          }}
        >
          <Sidebar open={open} handleDrawerOpen={handleDrawerOpen} />
          {/*<PageHeader title={heading} open={open} icon={icon}></PageHeader>*/}
          <Grid
            className={clsx({ [classes.pageBarShift]: open })}
            style={{
              marginTop: "8%",
              marginLeft: open ? "5%" : "0%",
            }}
          >
            {children}
          </Grid>
        </div>
      </Grid>
    </Wrapper>
  );
};

Default.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
  heading: PropTypes.string,
};

export default Default;
